// this is for common path for api

// this api is for development purpose
// const commonApiPath = `https://mdqualityapps.in/API/shopztrend/UAT/`;

// this api is for development purpose
// const commonApiPath = `https://mdqualityapps.in/API/shopztrend/production/`;

// Live Site
const commonApiPath = `https://shopztrend.com/ProdApiFolder/`;

// Category Image
const CategoryImageAPI = `${commonApiPath}category/`;

// Products Image
const BaseImageAPI = `${commonApiPath}products/`;

// userProfile
const ProfileImageAPI =`${commonApiPath}userprofile/`;

const getallcategory = `${commonApiPath}get-allcategory`

const user_signup = `${commonApiPath}admin_signup`

const getmainproducts =`${commonApiPath}get_all_category_products` 

const user_signin = `${commonApiPath}admin_signin` 

const forgot_password = `${commonApiPath}forgot_password`

const get_product_reviews =`${commonApiPath}get_product_reviews` 

const add_product_reviews = `${commonApiPath}add_product_reviews`;

const add_main_category =`${commonApiPath}add_main_category`;

const add_products =`${commonApiPath}add_products`;

const get_all_placed_orders =`${commonApiPath}get_all_placed_orders`;

const get_allproducts =`${commonApiPath}get-allproducts`;

const getParticularItem = `${commonApiPath}get-mainproducts`;

const add_feedback = `${commonApiPath}add_feedback`;

const getuser = `${commonApiPath}getuser`;

const getAllusers = `${commonApiPath}getAllusers`;

const user_update = `${commonApiPath}user-update`;

const add_user_address = `${commonApiPath}add_user_address`;

const get_all_user_address = `${commonApiPath}get_all_user_address`;

const get_user_address =  `${commonApiPath}get_user_address`;

const update_user_address =  `${commonApiPath}update_user_address`;

const delete_user_address =  `${commonApiPath}delete_user_address`;

const update_email =  `${commonApiPath}update_email`;

const update_mobile =  `${commonApiPath}update_mobile`;

const add_to_cart = `${commonApiPath}add_to_cart`;

const add_to_wishlist = `${commonApiPath}add_to_wishlist`;

const get_user_wishlist = `${commonApiPath}get_user_wishlist`;

const get_user_cart = `${commonApiPath}get_user_cart`;

const get_product_ordered_qty = `${commonApiPath}get_product_ordered_qty`;

const get_products_in_admin = `${commonApiPath}get_products_in_admin`;

const delete_product = `${commonApiPath}delete_product`;

const update_product = `${commonApiPath}update_product`;

const get_all_deleted_products = `${commonApiPath}get_all_deleted_products`;

const update_main_category = `${commonApiPath}update_main_category`;

const delete_main_category = `${commonApiPath}delete_main_category`;

const delete_product_image = `${commonApiPath}delete_product_image`;

const get_all_product_name = `${commonApiPath}get_all_product_name`;

const add_product_image = `${commonApiPath}add_product_image`;

const get_product_and_cost = `${commonApiPath}get_product_and_cost`;

const add_purchase = `${commonApiPath}add_purchase`;

const get_purchase = `${commonApiPath}get_purchase`;

const add_expense_category = `${commonApiPath}add_expense_category`;

const get_expense_category = `${commonApiPath}get_expense_category`;

const update_expense_category = `${commonApiPath}update_expense_category`;

const delete_expense_category = `${commonApiPath}delete_expense_category`;


const add_expense= `${commonApiPath}add_expense`;

const get_expense = `${commonApiPath}get_expense`;

const update_expense = `${commonApiPath}update_expense`;

const delete_expense = `${commonApiPath}delete_expense`;

const get_vendor = `${commonApiPath}get_vendor`;

const add_vendor = `${commonApiPath}add_vendor`;

const update_vendor = `${commonApiPath}update_vendor`;

const delete_vendor = `${commonApiPath}delete_vendor`;

const update_order_status = `${commonApiPath}update_order_status`;

const create_shipment = `${commonApiPath}create_shipment`;

const get_placed_orders = `${commonApiPath}get_placed_orders`;

const capture_payment = `${commonApiPath}capture_payment`;

const cancel_payment = `${commonApiPath}cancel_payment`;

const get_home_image = `${commonApiPath}get_home_image`;

const get_all_returns_refunds = `${commonApiPath}get_all_returns_refunds`;

const get_all_supplier = `${commonApiPath}get_all_supplier`;

const get_all_contractor = `${commonApiPath}get_all_contractor`;

const get_all_consultant = `${commonApiPath}get_all_consultant`;

const add_channel_sale_order = `${commonApiPath}add_channel_sale_order`;

const get_all_confirmed_orders = `${commonApiPath}get_all_confirmed_orders`;

const get_all_cancelled_orders = `${commonApiPath}get_all_cancelled_orders`;

const update_home_page_image = `${commonApiPath}update_home_page_image`;


const sales_invoice = `${commonApiPath}pdf/sales_invoice`;

const purchase_bill = `${commonApiPath}pdf/purchase_bill`;

const get_channel_sale_order = `${commonApiPath}get_channel_sale_order`;

const channel_invoice = `${commonApiPath}pdf/channel_invoice`;

const get_product_by_category = `${commonApiPath}get_product_by_category`;

const delete_home_page_image = `${commonApiPath}delete_home_page_image`;

const get_brand_image = `${commonApiPath}get_brand_image`;

const get_all_contact_us = `${commonApiPath}get_all_contact_us`;

const refund_payment = `${commonApiPath}refund_payment`;

const get_admin = `${commonApiPath}get_admin`;

const get_user_ordered_product_refund = `${commonApiPath}get_user_ordered_product_refund`;

const add_return_product = `${commonApiPath}add_return_product`;

const get_all_return_product = `${commonApiPath}get_all_return_product`;

const admin_signup = `${commonApiPath}admin_signup`;

const update_admin = `${commonApiPath}update_admin`;

const get_color_by_product_id = `${commonApiPath}get_color_by_product_id`;

const get_dashboard_data = `${commonApiPath}get_dashboard_data`;

const printing_label = `${commonApiPath}printing_label`;

const get_order_vs_pending = `${commonApiPath}get_order_vs_pending`;

const get_revenue = `${commonApiPath}get_revenue`;

const get_expense_report = `${commonApiPath}get_expense_report`;

const add_home_page_images = `${commonApiPath}add_home_page_images`;

const add_brand_images = `${commonApiPath}add_brand_images`;

const update_brand_image = `${commonApiPath}update_brand_image`;

const delete_brand_image = `${commonApiPath}delete_brand_image`;

const get_all_brand_names = `${commonApiPath}get_all_brand_names`;

const profit_loss_graph = `${commonApiPath}profit_loss_graph`;

const expenses_sales_graph = `${commonApiPath}expenses_sales_graph`;

const web_orders_graph = `${commonApiPath}web_orders_graph`;

const channel_sale_orders_graph = `${commonApiPath}channel_sale_orders_graph`;

const products_in_stock_graph = `${commonApiPath}products_in_stock_graph`;

const products_out_of_stock_graph = `${commonApiPath}products_out_of_stock_graph`;

const web_orders_excel_report = `${commonApiPath}web_orders_excel_report`;

const gettax = `${commonApiPath}gettax`;

const get_provinces = `${commonApiPath}get_provinces`;

export { create_shipment,get_revenue ,get_provinces, gettax ,web_orders_excel_report ,get_expense_report , products_out_of_stock_graph , products_in_stock_graph , channel_sale_orders_graph ,web_orders_graph , expenses_sales_graph , profit_loss_graph , get_all_brand_names ,update_brand_image , delete_brand_image,add_home_page_images ,add_brand_images,  printing_label,get_order_vs_pending, get_color_by_product_id,get_dashboard_data ,  get_admin, add_return_product, update_admin,  admin_signup , get_all_return_product ,get_user_ordered_product_refund, get_channel_sale_order,refund_payment , get_brand_image, get_all_contact_us,  delete_home_page_image, channel_invoice, get_product_by_category,  purchase_bill , sales_invoice , update_home_page_image , get_all_cancelled_orders, get_all_confirmed_orders, add_channel_sale_order ,capture_payment, get_all_returns_refunds, get_all_contractor, get_all_supplier, get_all_consultant , get_home_image ,cancel_payment, getallcategory, get_placed_orders, update_order_status,  add_vendor, update_vendor, delete_vendor, get_vendor, add_expense, get_expense, delete_expense, update_expense, delete_expense_category , BaseImageAPI, get_purchase, update_expense_category, get_expense_category, add_expense_category, add_purchase, delete_product_image, get_product_and_cost, add_product_image, get_all_product_name ,update_main_category,delete_main_category, update_product, get_all_deleted_products, CategoryImageAPI,delete_product,get_products_in_admin,forgot_password,ProfileImageAPI,getAllusers,get_user_cart,get_product_ordered_qty,get_user_wishlist,add_to_cart,add_to_wishlist,update_mobile,delete_user_address,update_email,update_user_address,get_user_address,get_all_user_address,add_user_address,user_update,getuser,add_feedback,getParticularItem,get_allproducts,add_products,get_all_placed_orders,add_main_category,add_product_reviews,get_product_reviews, user_signup,getmainproducts,user_signin}