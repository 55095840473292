import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Heading from '../../Components/Heading/Heading';
import { FilterData, FilterTwoData } from '../../Components/FilterData/FilterData';
import Filter from '../../Components/FilterData/Filter';
import axios from 'axios';
import { Container, Grid , Button } from '@mui/material';
import { getmainproducts, get_allproducts, BaseImageAPI } from '../../Services/APIService';
import { orderTable, productsTable, updateproductsTable } from '../../Variables/Variables';
import AddIcon from '@mui/icons-material/Add';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import RemoveDialogBox from './RemoveDialogBox';
import { useNavigate } from 'react-router-dom';

export default function UpdateProductTable() {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [status, setStatus] = useState();
    const [color, setColor] = useState();
    const [products, setProducts] = useState([]);
    const [search, setSearch] = useState('');

    const [productNameSearch, setproductNameSearch] = useState('');
    const [categoryNameSearch, setcategoryNameSearch] = useState('');

    const [openDialog, setOpenDialog] = useState(false);
    const navigate= useNavigate();


    useEffect(() => {
        axios({
            method: "GET",
            url: get_allproducts,
        }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(false)
                setColor(false)
            } else {
                setOpen(true)
                setMessage(res.data.message)
                setProducts(res.data.data);
                setStatus(true)
                setColor(true)
            }
        }).catch(err => {
            console.log(err)
        });
    }, [])


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const openEditPage = (id) =>{
        navigate('/dashbrdlayout/updatesection' , {state:{productId:id}})
    }

    return (
        <Box p={2}>
            <Box p={3}>
                <Heading title={'Update Products'} />
            </Box>
            <Container>
                <Box mt={2} py={4}>
                    <Grid container>
                        <TableContainer sx={{ border: '1px solid silver' }} >

                            <Table size='small'>
                                <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: '#616e80' }}>
                                    <TableRow sx={{ borderBottom: '1px solid silver' }}>
                                        {
                                            updateproductsTable.map((i, index) => {
                                                return (
                                                    <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                            <Box sx={{ my: 'auto' }}>
                                                                {i.title}
                                                            </Box>
                                                            {i.title === "Category Name" && (
                                                                <Box>
                                                                    <Filter search={categoryNameSearch} setSearch={setcategoryNameSearch} setPage={setPage} />
                                                                </Box>
                                                            )
                                                            }
                                                            {i.title === "Product Name" && (
                                                                <Box>
                                                                    <Filter search={productNameSearch} setSearch={setproductNameSearch} setPage={setPage} />
                                                                </Box>
                                                            )
                                                            }
                                                        </Box>
                                                    </TableCell>
                                                )
                                            })
                                        }
                                    </TableRow>
                                </TableHead>


                                <TableBody>

                                {FilterTwoData(products, categoryNameSearch, productNameSearch)
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((i, index) => {
                                return (
                                <TableRow key={index} sx={{ borderBottom: '1px solid silver' }}>
                                <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}> <img style={{ width: 100, height: 100 }} src={`${BaseImageAPI}${i.mainImage}`} alt='Image' /> </TableCell>
                                <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}>{i.productId}</TableCell>
                                <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}>{i.categoryName}</TableCell>
                                <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}>{i.productName}</TableCell>
                                <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}>
                                <Box display='flex' flexDirection='row' justifyContent='center' gap={2}>
                                <Button onClick={()=>openEditPage(i.productId)} 
                                variant="contained" 
                                sx={{ height: 30, width: 100, fontWeight: 600, opacity: 0.9, fontSize: 12 }} 
                                color='warning'>
                                <DriveFileRenameOutlineIcon 
                                sx={{ verticalAlign: 'middle', mr: 1 }}
                                 /> 
                                                    UPDATE
                                 </Button>
                                </Box>
                                </TableCell>
                                </TableRow>
                                )
                                })
                                }

                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={FilterTwoData(products, categoryNameSearch, productNameSearch).length}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Grid>
                </Box>
            </Container>
        </Box>
    );
}