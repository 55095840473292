import {Drawer,Typography,Box,List,ListItem,ListItemText,ListItemIcon, IconButton} from '@mui/material';
import { Link } from 'react-router-dom'
import ResponsiveBd from './ResponsiveBd';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import logo from '../../Assets/SHOPZTREND_NEW.png';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import EditRoadIcon from '@mui/icons-material/EditRoad';
import AddchartIcon from '@mui/icons-material/Addchart';
import ArchiveIcon from '@mui/icons-material/Archive';
import WebIcon from '@mui/icons-material/Web';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import IosShareIcon from '@mui/icons-material/IosShare';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import AddBoxIcon from '@mui/icons-material/AddBox';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import SellIcon from '@mui/icons-material/Sell';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import QrCodeIcon from '@mui/icons-material/QrCode';
import StoreIcon from '@mui/icons-material/Store';
import TaskIcon from '@mui/icons-material/Task';
import AddTaskIcon from '@mui/icons-material/AddTask';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import SummarizeIcon from '@mui/icons-material/Summarize';
import TerminalIcon from '@mui/icons-material/Terminal';
import TimelineIcon from '@mui/icons-material/Timeline';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import WorkIcon from '@mui/icons-material/Work';
import AssignmentIcon from '@mui/icons-material/Assignment';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import MobileScreenShareIcon from '@mui/icons-material/MobileScreenShare';
import { useState } from 'react';
import CategoryIcon from '@mui/icons-material/Category';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline'
import Shop2Icon from '@mui/icons-material/Shop2';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import TocIcon from '@mui/icons-material/Toc';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import GroupsIcon from '@mui/icons-material/Groups';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import PaidIcon from '@mui/icons-material/Paid';
import PaymentsIcon from '@mui/icons-material/Payments';
import HomeIcon from '@mui/icons-material/Home';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import VerifiedIcon from '@mui/icons-material/Verified';
import CancelIcon from '@mui/icons-material/Cancel';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import LanguageIcon from '@mui/icons-material/Language';


function SideBar({ mobileOpen, setMobileOpen }) {
    const responsive = ResponsiveBd();
    const drawerWidth = 280;
      const [openOne, setOpenOne] = useState(false);
      const [openTwo, setOpenTwo] = useState(false);
      const [openThree, setOpenThree] = useState(false);
      const [openFour, setOpenFour] = useState(false);
      const [openFive, setOpenFive] = useState(false);
      const [openSix, setOpenSix] = useState(false);
      const [openSeven, setOpenSeven] = useState(false);
      const [openEight, setOpenEight] = useState(false);
      const [openNine, setOpenNine] = useState(false);
      const [openTen, setOpenTen] = useState(false);
     const [openOrder, setOpenOrder] = useState(false);
     const access = JSON.parse(localStorage.getItem('access'));

  const { dashboardView,
    addCategoryView,
    updateCategoryView,
    addProductView,
    updateProductView,
    allProductView,
    archiveProductView,
    purchaseListView,
    addPurchaseView,
    vendorView,
    webOrderView,
    channelOrderView,
    returnRefundView,
    expenseView,
    expenseCategoryView,
    customerView,
    inquiryView,
    homePageView,
    trendingView,
    saleView,
    brandsView,
    accessView,
    authorizationView,
    reportView, } = access ;



    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

  const handleClickOne = () => {
        setOpenOne(!openOne);
      };

      
  const handleClickTwo = () => {
        setOpenTwo(!openTwo);
      };

      const handleClickThree = () => {
        setOpenThree(!openThree);
      };

      const handleClickFour = () => {
        setOpenFour(!openFour);
      };

      const handleClickFive = () => {
        setOpenFive(!openFive);
      };
      const handleClickSix = () => {
        setOpenSix(!openSix);
      };
      const handleClickSeven = () => {
        setOpenSeven(!openSeven);
      };
      const handleClickEight = () => {
        setOpenEight(!openEight);
      };
      const handleClickNine = () => {
        setOpenNine(!openNine);
      };
  const handleClickTen = () => {
    setOpenTen(!openTen);
  };

  const handleClickStoreOrders = () =>{
    setOpenOrder(!openOrder);
  }

  const drawer = (
  
  <Box sx={{ height: '100%' }}>
    {/* Logo */}
  <Box sx={{ px: 2 }}>
    <Box sx={{ textAlign: 'center' }}>
      <Link to="dashboard"> <img className='dashlogo' src={logo} alt="logo" 
            style={{ objectFit: 'contain', height: '155px', width: '235px', mixBlendMode: 'multiply', verticalAlign:'bottom'}} loading='lazy' /></Link>
    </Box>
  </Box>


  {/* Dashboard */}

      {dashboardView === 'true' && (
        <Link to="dashboard" className={" text-nowrap text-decoration-none fs-5 "}>
        <ListItem button onClick={handleDrawerToggle}  sx={{ textAlign: 'left', borderRadius: 2, py: 2, color: 'primary.main' }}>
        <ListItemIcon sx={{ px: 2, color: 'primary.main' }}><DashboardIcon sx={{color:'#faa634'}} /></ListItemIcon>
        <ListItemText>
        <Typography sx={{ fontWeight: 500 }}>Dashboard</Typography>
        </ListItemText>
        </ListItem>
        </Link>
      )}


{/* Category */}

      {(addCategoryView === 'true' || updateCategoryView === 'true') && (<>
        <ListItem onClick={handleClickOne} button sx={{ textAlign: 'left', borderRadius: 2, py: 2, color: 'primary.main' }} >
        <ListItemIcon sx={{ px: 2, color: 'primary.main' }}><CategoryIcon sx={{ color: '#faa634' }} /></ListItemIcon>
          <ListItemText >
            <Typography component={'div'} sx={{ display: 'flex', fontWeight: 500, justifyContent: 'space-between' }} >
              <Box component={'div'} sx={{ whiteSpace: 'nowrap' }}> Category</Box><Box>{openOne ? <ExpandLess /> : <ExpandMore />}</Box>
            </Typography>
          </ListItemText>
        </ListItem>
        <Collapse in={openOne} timeout="auto" unmountOnExit>
          <List sx={{ px: 2 }}>

            {addCategoryView === 'true' && (
          <Link to="newcategory" className=" text-nowrap text-decoration-none fs-5 ">
              <ListItem onClick={handleDrawerToggle} button sx={{ borderRadius: 2, color: 'primary.main' }}>
                <ListItemIcon sx={{ px: 2, color: 'primary.main' }}>
                <AddBoxIcon color='success' />
                </ListItemIcon>
                <ListItemText sx={{ color: 'primary.main' }} >
                  <Typography sx={{ fontWeight: 500 }}>
                    New Category
                  </Typography>
                </ListItemText>
              </ListItem>
            </Link>
            )}

            {updateCategoryView === 'true' && (
          <Link to="editcategory" className=" text-nowrap text-decoration-none fs-5 ">
              <ListItem button onClick={handleDrawerToggle} sx={{ borderRadius: 2, color: 'primary.main' }}>
                <ListItemIcon sx={{ px: 2, color: 'primary.main' }}>
                <EditRoadIcon color='success' />
                </ListItemIcon>
                <ListItemText sx={{ color: 'primary.main' }} >
                  <Typography sx={{ fontWeight: 500 }}>
                    Update Category
                  </Typography>
                </ListItemText>
              </ListItem>
            </Link>
            )}

          </List>
        </Collapse>
        </>
      )}

  

  {/* Products */}
      {(addProductView === 'true' || updateProductView === 'true' || allProductView === 'true' || archiveProductView ==='true') && (
      <>
      <ListItem onClick={handleClickTwo} button sx={{ textAlign: 'left', borderRadius: 2, py: 2, color: 'primary.main' }} >
        <ListItemIcon sx={{ px: 2, color: 'primary.main' }}><Inventory2Icon sx={{ color: '#faa634' }} /></ListItemIcon>
          <ListItemText >
            <Typography component={'div'} sx={{ display: 'flex', fontWeight: 500, justifyContent: 'space-between' }} >
              <Box component={'div'} sx={{ whiteSpace: 'nowrap' }}> Products</Box><Box>{openTwo ? <ExpandLess /> : <ExpandMore />}</Box>
            </Typography>
          </ListItemText>
        </ListItem>
        <Collapse in={openTwo} timeout="auto" unmountOnExit>
          <List sx={{ px: 2 }}>
   
              {addProductView === 'true' && (
        <Link to="productspage" className={" text-nowrap text-decoration-none fs-5 "}>
        <ListItem button onClick={handleDrawerToggle}  sx={{ textAlign: 'left', borderRadius: 2, py: 2, color: 'primary.main' }}>
              <ListItemIcon sx={{ px: 2, color: 'primary.main' }}><AddchartIcon color='success'  /></ListItemIcon>
        <ListItemText>
        <Typography sx={{ fontWeight: 500 }}>New Products</Typography>
        </ListItemText>
        </ListItem>
        </Link>
              )}

              {updateProductView === 'true' && (
      <Link to="editproductspage" className={" text-nowrap text-decoration-none fs-5 "}>
        <ListItem button onClick={handleDrawerToggle} sx={{ textAlign: 'left', borderRadius: 2, py: 2, color: 'primary.main' }}>
              <ListItemIcon sx={{ px: 2, color: 'primary.main' }}><EditRoadIcon color='success' /></ListItemIcon>
          <ListItemText>
            <Typography sx={{ fontWeight: 500 }}>Update Products</Typography>
          </ListItemText>
        </ListItem>
      </Link>
              )}

              {allProductView === 'true' && (
        <Link to="allproductspage" className={" text-nowrap text-decoration-none fs-5 "}>
        <ListItem button onClick={handleDrawerToggle}  sx={{ textAlign: 'left', borderRadius: 2, py: 2, color: 'primary.main' }}>
              <ListItemIcon sx={{ px: 2, color: 'primary.main' }}><Inventory2Icon color='success'  /></ListItemIcon>
        <ListItemText>
        <Typography sx={{ fontWeight: 500 }}>All Products</Typography>
        </ListItemText>
        </ListItem>
        </Link>
              )}

              {archiveProductView=== 'true' && (
      <Link to="archive" className={" text-nowrap text-decoration-none fs-5 "}>
        <ListItem button onClick={handleDrawerToggle} sx={{ textAlign: 'left', borderRadius: 2, py: 2, color: 'primary.main' }}>
              <ListItemIcon sx={{ px: 2, color: 'primary.main' }}><ArchiveIcon color='success' /></ListItemIcon>
          <ListItemText>
            <Typography sx={{ fontWeight: 500 }}>Archive Products</Typography>
          </ListItemText>
        </ListItem>
      </Link>
              )}

          </List>
        </Collapse>
        </>
      )}


      {/* Purchase */}

      {(purchaseListView === 'true' || addPurchaseView === 'true' || vendorView === 'true') && (
        <>
        <ListItem onClick={handleClickThree} button sx={{ textAlign: 'left', borderRadius: 2, py:2 , color: 'primary.main' }} >
        <ListItemIcon sx={{ px: 2, color: 'primary.main' }}><Shop2Icon sx={{ color: '#faa634' }} /></ListItemIcon>
          <ListItemText >
            <Typography component={'div'} sx={{ display: 'flex', fontWeight: 500, justifyContent: 'space-between' }} >
              <Box component={'div'} sx={{ whiteSpace: 'nowrap' }}> Purchase</Box><Box>{openThree ? <ExpandLess /> : <ExpandMore />}</Box>
            </Typography>
          </ListItemText>
        </ListItem>
        <Collapse in={openThree} timeout="auto" unmountOnExit>
          <List sx={{ px: 2 }}>

              {purchaseListView === 'true' && (
           <Link to="purchaselist" className=" text-nowrap text-decoration-none fs-5 ">
              <ListItem onClick={handleDrawerToggle} button sx={{ borderRadius: 2, color: 'primary.main' }}>
                <ListItemIcon sx={{ px: 2, color: 'primary.main' }}>
                <TocIcon color='success' />
                </ListItemIcon>
                <ListItemText sx={{ color: 'primary.main' }} >
                  <Typography sx={{ fontWeight: 500 }}>
                    Purchase List
                  </Typography>
                </ListItemText>
              </ListItem>
            </Link>
              )}

              {addPurchaseView === 'true' && (
            <Link to="createnewpurchase" className=" text-nowrap text-decoration-none fs-5 ">
              <ListItem button onClick={handleDrawerToggle} sx={{ borderRadius: 2, color: 'primary.main' }}>
                <ListItemIcon sx={{ px: 2, color: 'primary.main' }}>
                <FiberNewIcon color='success' />
                </ListItemIcon>
                <ListItemText sx={{ color: 'primary.main' }} >
                  <Typography sx={{ fontWeight: 500 }}>
                    Create New Purchase
                  </Typography>
                </ListItemText>
              </ListItem>
            </Link>
              )}
            
              {vendorView === 'true' && (
          <Link to="vendor" className=" text-nowrap text-decoration-none fs-5 ">
            <ListItem button onClick={handleDrawerToggle} sx={{ borderRadius: 2, color: 'primary.main' }}>
              <ListItemIcon sx={{ px: 2, color: 'primary.main' }}>
                <ConnectWithoutContactIcon color='success' />
              </ListItemIcon>
              <ListItemText sx={{ color: 'primary.main' }} >
                <Typography sx={{ fontWeight: 500 }}>
                  Vendor
                </Typography>
              </ListItemText>
            </ListItem>
          </Link>
              )}

          </List>
        </Collapse>
        </>)}


        {/* Orders */}


      {(webOrderView === 'true' || channelOrderView === 'true' || returnRefundView === 'true') && (
        <>
      <ListItem onClick={handleClickStoreOrders} button sx={{ textAlign: 'left', borderRadius: 2, py: 2, color: 'primary.main' }} >
        <ListItemIcon sx={{ px: 2, color: 'primary.main' }}><SellIcon sx={{ color: '#faa634' }} /></ListItemIcon>
        <ListItemText >
          <Typography component={'div'} sx={{ display: 'flex', fontWeight: 500, justifyContent: 'space-between' }} >
            <Box component={'div'} sx={{ whiteSpace: 'nowrap' }}>Sale Order</Box>
            <Box>{openOrder ? <ExpandLess /> : <ExpandMore />}</Box>
          </Typography>
        </ListItemText>
      </ListItem>
      <Collapse in={openOrder} timeout="auto" unmountOnExit>
        <List sx={{ px: 2 }}>
       
              {webOrderView === 'true' && (
                <>
          <ListItem onClick={handleClickFour} button sx={{ textAlign: 'left', borderRadius: 2, py: 2, color: 'primary.main' }} >
            <ListItemIcon sx={{ px: 2, color: 'primary.main' }}><LanguageIcon sx={{ color: '#faa634' }} /></ListItemIcon>
            <ListItemText >
              <Typography component={'div'} sx={{ display: 'flex', fontWeight: 500, justifyContent: 'space-between' }} >
                <Box component={'div'} sx={{ whiteSpace: 'nowrap' }}>Web Order</Box><Box>{openFour ? <ExpandLess /> : <ExpandMore />}</Box>
              </Typography>
            </ListItemText>
          </ListItem>
          <Collapse in={openFour} timeout="auto" unmountOnExit>
            <List sx={{ px: 2 }}>


              <Link to="orders" className={" text-nowrap text-decoration-none fs-5 "}>
                <ListItem button onClick={handleDrawerToggle} sx={{ textAlign: 'left', borderRadius: 2, py: 2, color: 'primary.main' }}>
                  <ListItemIcon sx={{ px: 2, color: 'primary.main' }}><EditRoadIcon color='success' /></ListItemIcon>
                  <ListItemText>
                    <Typography sx={{ fontWeight: 500 }}>Order Processing</Typography>
                  </ListItemText>
                </ListItem>
              </Link>

              <Link to="confirmed" className={" text-nowrap text-decoration-none fs-5 "}>
                <ListItem button onClick={handleDrawerToggle} sx={{ textAlign: 'left', borderRadius: 2, py: 2, color: 'primary.main' }}>
                  <ListItemIcon sx={{ px: 2, color: 'primary.main' }}><VerifiedIcon color='success' /></ListItemIcon>
                  <ListItemText>
                    <Typography sx={{ fontWeight: 500 }}>Processed Orders</Typography>
                  </ListItemText>
                </ListItem>
              </Link>

              <Link to="cancelled" className={" text-nowrap text-decoration-none fs-5 "}>
                <ListItem button onClick={handleDrawerToggle} sx={{ textAlign: 'left', borderRadius: 2, py: 2, color: 'primary.main' }}>
                  <ListItemIcon sx={{ px: 2, color: 'primary.main' }}><CancelIcon color='success' /></ListItemIcon>
                  <ListItemText>
                    <Typography sx={{ fontWeight: 500 }}>Cancelled Orders</Typography>
                  </ListItemText>
                </ListItem>
              </Link>
            </List>
          </Collapse>
             </> )}

              {channelOrderView === 'true' && (
                <>
          <ListItem onClick={handleClickEight} button sx={{ textAlign: 'left', borderRadius: 2, py: 2, color: 'primary.main' }} >
            <ListItemIcon sx={{ px: 2, color: 'primary.main' }}><StoreIcon sx={{ color: '#faa634' }} /></ListItemIcon>
            <ListItemText >
              <Typography component={'div'} sx={{ display: 'flex', fontWeight: 500, justifyContent: 'space-between' }} >
                <Box component={'div'} sx={{ whiteSpace: 'nowrap' }}>Channel Sale</Box><Box>{openEight ? <ExpandLess /> : <ExpandMore />}</Box>
              </Typography>
            </ListItemText>
          </ListItem>
          <Collapse in={openEight} timeout="auto" unmountOnExit>
            <List sx={{ px: 2 }}>

              <Link to="saleslist" className=" text-nowrap text-decoration-none fs-5 ">
                <ListItem button onClick={handleDrawerToggle} sx={{ borderRadius: 2, color: 'primary.main' }}>
                  <ListItemIcon sx={{ px: 2, color: 'primary.main' }}>
                    <TocIcon color='success' />
                  </ListItemIcon>
                  <ListItemText sx={{ color: 'primary.main' }} >
                    <Typography sx={{ fontWeight: 500 }}>
                      Channel Sale List
                    </Typography>
                  </ListItemText>
                </ListItem>
              </Link>

              <Link to="createnewsale" className=" text-nowrap text-decoration-none fs-5 ">
                <ListItem button onClick={handleDrawerToggle} sx={{ borderRadius: 2, color: 'primary.main' }}>
                  <ListItemIcon sx={{ px: 2, color: 'primary.main' }}>
                    <FiberNewIcon color='success' />
                  </ListItemIcon>
                  <ListItemText sx={{ color: 'primary.main' }} >
                    <Typography sx={{ fontWeight: 500 }}>
                      Create New Sale
                    </Typography>
                  </ListItemText>
                </ListItem>
              </Link>

            </List>
          </Collapse>
          </>)}

              {returnRefundView === 'true' && (
          <Link to="returnsale" className=" text-nowrap text-decoration-none fs-5 ">
            <ListItem button onClick={handleDrawerToggle} sx={{ borderRadius: 2, color: 'primary.main' }}>
              <ListItemIcon sx={{ px: 2, color: 'primary.main' }}>
                <FastRewindIcon color='success' />
              </ListItemIcon>
              <ListItemText sx={{ color: 'primary.main' }} >
                <Typography sx={{ fontWeight: 500 }}>
                  Return & Refund
                </Typography>
              </ListItemText>
            </ListItem>
          </Link>
          )}

        </List>
      </Collapse>
        </>)}



      {/* Expenses */}

      {(expenseView === 'true' || expenseCategoryView === 'true') && (
        <>
      <ListItem onClick={handleClickFive} button sx={{ textAlign: 'left', borderRadius: 2, py:2 , color: 'primary.main' }} >
        <ListItemIcon sx={{ px: 2, color: 'primary.main' }}><PaidIcon sx={{ color: '#faa634' }} /></ListItemIcon>
        <ListItemText >
          <Typography component={'div'} sx={{ display: 'flex', fontWeight: 500, justifyContent: 'space-between' }} >
            <Box component={'div'} sx={{ whiteSpace: 'nowrap' }}> Expense</Box><Box>{openFive ? <ExpandLess /> : <ExpandMore />}</Box>
          </Typography>
        </ListItemText>
      </ListItem>
      <Collapse in={openFive} timeout="auto" unmountOnExit>
        <List sx={{ px: 2 }}>

              {expenseView === 'true' && (
          <Link to="expenses" className=" text-nowrap text-decoration-none fs-5 ">
            <ListItem onClick={handleDrawerToggle} button sx={{ borderRadius: 2, color: 'primary.main' }}>
              <ListItemIcon sx={{ px: 2, color: 'primary.main' }}>
                <PaymentsIcon color='success' />
              </ListItemIcon>
              <ListItemText sx={{ color: 'primary.main' }} >
                <Typography sx={{ fontWeight: 500 }}>
                  Expense
                </Typography>
              </ListItemText>
            </ListItem>
          </Link>
              )}

              {expenseCategoryView === 'true' && (
          <Link to="expensescategory" className=" text-nowrap text-decoration-none fs-5 ">
            <ListItem button onClick={handleDrawerToggle} sx={{ borderRadius: 2, color: 'primary.main' }}>
              <ListItemIcon sx={{ px: 2, color: 'primary.main' }}>
                <CategoryIcon color='success' />
              </ListItemIcon>
              <ListItemText sx={{ color: 'primary.main' }} >
                <Typography sx={{ fontWeight: 500 }}>
                  Expense Category
                </Typography>
              </ListItemText>
            </ListItem>
          </Link>
              )}

        </List>
      </Collapse>
      </>)}


{/* People */}

      {(customerView === 'true' || inquiryView === 'true') && (
        <>
        <ListItem onClick={handleClickSix} button sx={{ textAlign: 'left', borderRadius: 2, py:2 , color: 'primary.main' }} >
        <ListItemIcon sx={{ px: 2, color: 'primary.main' }}><GroupsIcon sx={{ color: '#faa634' }} /></ListItemIcon>
          <ListItemText >
            <Typography component={'div'} sx={{ display: 'flex', fontWeight: 500, justifyContent: 'space-between' }} >
              <Box component={'div'} sx={{ whiteSpace: 'nowrap' }}> People</Box><Box>{openSix ? <ExpandLess /> : <ExpandMore />}</Box>
            </Typography>
          </ListItemText>
        </ListItem>
        <Collapse in={openSix} timeout="auto" unmountOnExit>
          <List sx={{ px: 2 }}>

              {customerView === 'true' && (
        <Link to="users" className={" text-nowrap text-decoration-none fs-5 "}>
        <ListItem button onClick={handleDrawerToggle}  sx={{ textAlign: 'left', borderRadius: 2, py: 2, color: 'primary.main' }}>
              <ListItemIcon sx={{ px: 2, color: 'primary.main' }}><AccountCircleIcon color='success'  /></ListItemIcon>
        <ListItemText>
        <Typography sx={{ fontWeight: 500 }}>Customer</Typography>
        </ListItemText>
        </ListItem>
        </Link>
              )}

              {inquiryView === 'true' && (
          <Link to="inquiry" className=" text-nowrap text-decoration-none fs-5 ">
            <ListItem button onClick={handleDrawerToggle} sx={{ borderRadius: 2, color: 'primary.main' }}>
              <ListItemIcon sx={{ px: 2, color: 'primary.main' }}>
                <TransferWithinAStationIcon color='success' />
              </ListItemIcon>
              <ListItemText sx={{ color: 'primary.main' }} >
                <Typography sx={{ fontWeight: 500 }}>
                  Inquiry
                </Typography>
              </ListItemText>
            </ListItem>
          </Link>
              )}

          </List>
        </Collapse>
        </>)}

      {/* Home Page */}

      {(homePageView === 'true' || trendingView === 'true' || saleView === 'true' || brandsView === 'true') && (
        <>
      <ListItem onClick={handleClickTen} button sx={{ textAlign: 'left', borderRadius: 2, py: 2, color: 'primary.main' }} >
        <ListItemIcon sx={{ px: 2, color: 'primary.main' }}><WebIcon sx={{ color: '#faa634' }} /></ListItemIcon>
        <ListItemText >
          <Typography component={'div'} sx={{ display: 'flex', fontWeight: 500, justifyContent: 'space-between' }} >
            <Box component={'div'} sx={{ whiteSpace: 'nowrap' }}> Website UI</Box><Box>{openFive ? <ExpandLess /> : <ExpandMore />}</Box>
          </Typography>
        </ListItemText>
      </ListItem>
      <Collapse in={openTen} timeout="auto" unmountOnExit>
        <List sx={{ px: 2 }}>

              {homePageView === 'true' && (
          <Link to="hompage" className=" text-nowrap text-decoration-none fs-5 ">
            <ListItem onClick={handleDrawerToggle} button sx={{ borderRadius: 2, color: 'primary.main' }}>
              <ListItemIcon sx={{ px: 2, color: 'primary.main' }}>
                <HomeIcon color='success' />
              </ListItemIcon>
              <ListItemText sx={{ color: 'primary.main' }} >
                <Typography sx={{ fontWeight: 500 }}>
                  Home Page
                </Typography>
              </ListItemText>
            </ListItem>
          </Link>
              )}

              {trendingView === 'true' && (
          <Link to="trending" className=" text-nowrap text-decoration-none fs-5 ">
            <ListItem button onClick={handleDrawerToggle} sx={{ borderRadius: 2, color: 'primary.main' }}>
              <ListItemIcon sx={{ px: 2, color: 'primary.main' }}>
                <TrendingUpIcon color='success' />
              </ListItemIcon>
              <ListItemText sx={{ color: 'primary.main' }} >
                <Typography sx={{ fontWeight: 500 }}>
                  Trending
                </Typography>
              </ListItemText>
            </ListItem>
          </Link>
              )}

              {saleView === 'true' && (
          <Link to="sale" className=" text-nowrap text-decoration-none fs-5 ">
            <ListItem button onClick={handleDrawerToggle} sx={{ borderRadius: 2, color: 'primary.main' }}>
              <ListItemIcon sx={{ px: 2, color: 'primary.main' }}>
                <ShoppingBagIcon color='success' />
              </ListItemIcon>
              <ListItemText sx={{ color: 'primary.main' }} >
                <Typography sx={{ fontWeight: 500 }}>
                  Sale
                </Typography>
              </ListItemText>
            </ListItem>
          </Link>
              )}

              {brandsView === 'true' && (
          <Link to="bulk" className=" text-nowrap text-decoration-none fs-5 ">
            <ListItem button onClick={handleDrawerToggle} sx={{ borderRadius: 2, color: 'primary.main' }}>
              <ListItemIcon sx={{ px: 2, color: 'primary.main' }}>
                <ShoppingBagIcon color='success' />
              </ListItemIcon>
              <ListItemText sx={{ color: 'primary.main' }} >
                <Typography sx={{ fontWeight: 500 }}>
                  Brands - Bulk
                </Typography>
              </ListItemText>
            </ListItem>
          </Link>
              )}

        </List>
      </Collapse>
        </>)}

  {/* Access */}

      {(accessView === 'true' || authorizationView === 'true') && (
        <>
          <ListItem onClick={handleClickSeven} button sx={{ textAlign: 'left', borderRadius: 2, py:2 , color: 'primary.main' }} >
        <ListItemIcon sx={{ px: 2, color: 'primary.main' }}><AddModeratorIcon sx={{ color: '#faa634' }} /></ListItemIcon>
          <ListItemText >
            <Typography component={'div'} sx={{ display: 'flex', fontWeight: 500, justifyContent: 'space-between' }} >
              <Box component={'div'} sx={{ whiteSpace: 'nowrap' }}> Access</Box><Box>{openSeven ? <ExpandLess /> : <ExpandMore />}</Box>
            </Typography>
          </ListItemText>
        </ListItem>
        <Collapse in={openSeven} timeout="auto" unmountOnExit>
          <List sx={{ px: 2 }}>

              {accessView === 'true' && (
        <Link to="access" className={" text-nowrap text-decoration-none fs-5 "}>
        <ListItem button onClick={handleDrawerToggle}  sx={{ textAlign: 'left', borderRadius: 2, py: 2, color: 'primary.main' }}>
              <ListItemIcon sx={{ px: 2, color: 'primary.main' }}><AccessibilityIcon color='success'  /></ListItemIcon>
        <ListItemText>
        <Typography sx={{ fontWeight: 500 }}>Access</Typography>
        </ListItemText>
        </ListItem>
        </Link>
              )}

              {authorizationView === 'true' && (
        <Link to="authpersonlist" className={" text-nowrap text-decoration-none fs-5 "}>
        <ListItem button onClick={handleDrawerToggle}  sx={{ textAlign: 'left', borderRadius: 2, py: 2, color: 'primary.main' }}>
              <ListItemIcon sx={{ px: 2, color: 'primary.main' }}><TocIcon color='success'  /></ListItemIcon>
        <ListItemText>
        <Typography sx={{ fontWeight: 500 }}>Authorized Person List</Typography>
        </ListItemText>
        </ListItem>
        </Link>
              )}

          </List>
        </Collapse>
        </>)}



  {/* Report  */}

      {reportView === 'true' && (
      <Link to="reports" className={" text-nowrap text-decoration-none fs-5 "}>
        <ListItem button onClick={handleDrawerToggle} sx={{ textAlign: 'left', borderRadius: 2, py: 2, color: 'primary.main' }}>
          <ListItemIcon sx={{ px: 2, color: 'primary.main' }}><SummarizeIcon sx={{ color: '#faa634' }} /></ListItemIcon>
          <ListItemText>
            <Typography sx={{ fontWeight: 500 }}>Report</Typography>
          </ListItemText>
        </ListItem>
      </Link>
      )}
        
  
  {/* Logout */}
        <Link to="logout" className={" text-nowrap text-decoration-none fs-5 "}>
        <ListItem onClick={() => localStorage.clear()} sx={{ textAlign: 'left', borderRadius: 2, py: 2, color: 'primary.main' }}>
        <ListItemIcon  sx={{ px: 2 }}><LogoutIcon sx={{color:'#faa634'}}  /></ListItemIcon>
        <ListItemText>
        <Typography sx={{ fontWeight: 500 }}>Logout</Typography>
        </ListItemText>
        </ListItem>
        </Link>

</Box>)

return (
  <Box>
    <Drawer
    open={mobileOpen}
      variant={responsive ? 'temporary' : 'permanent'}
      ModalProps={{
        keepMounted: true,
      }}
      sx={{
        display: 'block',
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          bgcolor: '#eaf4fc'
        },
      }}
      anchor="left"
    >
      {drawer}
    </Drawer>



  </Box>
)
}

export default SideBar