const FilterData = (data, search, searchfeild) => {
  const { searchFeildOne, searchFeildTwo } = searchfeild;
  if (search === '') {
    return data
  } else if (searchFeildOne && searchFeildOne.toString().toLowerCase().includes(search.toLowerCase()) || searchFeildTwo && searchFeildTwo.toString().toLowerCase().includes(search.toLowerCase())) {
    return data
  }
}

  const filterEmpOrders = (data, search, searchfeild) => {
    const { searchFeildOne, searchFeildTwo, searchFeildThree } = searchfeild;
    if (search === '') {
      return data
    } else if (searchFeildOne.toLowerCase().includes(search.toLowerCase()) || searchFeildTwo.toLowerCase().includes(search.toLowerCase()) || searchFeildThree.toLowerCase().includes(search.toLowerCase())) {
      return data
    }
  }


const FilterTwoData = (products, categoryNameSearch, productNameSearch) => {
  if (!categoryNameSearch && !productNameSearch) {
    return products;
  }

  return products.filter((row) => {
    if (categoryNameSearch && row.categoryName.toString().toLowerCase().includes(categoryNameSearch.toLowerCase())) {
      return true; // Include in the filter if it matches the employeeId search.
    }

    if (productNameSearch && row.productName.toString().toLowerCase().includes(productNameSearch.toLowerCase())) {
      return true; // Include in the filter if it matches the projectCode search.
    }

    return false; // Exclude if it doesn't match either search.
  });
};


export { FilterData, filterEmpOrders, FilterTwoData };