import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Heading from '../../Components/Heading/Heading';
import { FilterData } from '../../Components/FilterData/FilterData';
import Filter from '../../Components/FilterData/Filter';
import axios from 'axios';
import { Container, Grid , Button , Card } from '@mui/material';
import { getmainproducts, get_allproducts, get_all_deleted_products, BaseImageAPI, get_purchase, purchase_bill } from '../../Services/APIService';
import { DeletedproductsTable, PurchaseListTable, orderTable, productsTable } from '../../Variables/Variables';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PrintIcon from '@mui/icons-material/Print';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';

export default function PurchaseList() {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [status, setStatus] = useState();
    const [color, setColor] = useState();
    const [purchaseList, setPurchaseList] = useState([]);
    const [search, setSearch] = useState('');
    const [currentPurchaseId, setCurrentPurchaseId] = useState(null);
    const navigate = useNavigate();
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);
    const handleClick = (event , index) => {
        setAnchorEl(event.currentTarget);
        setCurrentPurchaseId(index);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

  useEffect(()=>{
      const sendData = new FormData();
      sendData.append('purchaseId','');
      axios({
          method: "POST",
          url: get_purchase,
          data: sendData
      }).then(res => {
          if (res.data.error) {
              setMessage(res.data.message)
              setOpen(true)
              setStatus(false)
              setColor(false)
          } else {
              setOpen(true)
              setMessage(res.data.message)
              setStatus(true)
              setColor(true)
              setPurchaseList(res.data.data);
          }
      }).catch(err => {
          console.log(err)
      });
  }, []);

  const GotoDetails = (id) =>{
    navigate('/dashbrdlayout/purchasedetails', { state: { id: id } });
  }

    const GotoNewPurchase = () => {
        navigate('/dashbrdlayout/createnewpurchase');
    }

    const handlegenerateInvoice = (purchaseid) => {
        const sendData = new FormData();
        sendData.append('purchaseId', purchaseid);

        axios({
            method: 'POST',
            url: purchase_bill,
            data: sendData
        })
            .then((res) => {
                if (res.data.error) {
                    setMessage(res.data.message);
                    setOpen(true);
                    setStatus(false);
                    setColor(false);
                } else {
                    setMessage(res.data.message);
                    setOpen(true);
                    setStatus(true);
                    setColor(true);

                    // Open the URL in a new tab
                    const newTab = window.open(res.data.data, '_blank');

                    // Check if the new tab has been successfully opened
                    if (newTab !== null) {
                        // Add a timeout to ensure the URL has loaded before printing
                        setTimeout(() => {
                            newTab.print(); // Print the contents of the new tab
                        }, 1000); // Adjust the timeout delay as needed
                    } else {
                        alert('Popup blocker prevented opening the new tab. Please allow popups.');
                    }
                }
            })
            .catch((err) => {
                alert("Oops something went wrong " + err);
            });
    };


    return (
        <Box p={2}>
            <Box p={3}>
                <Heading title={'Purchase List'} />
            </Box>
 
            <Box component={Card} sx={{ boxShadow: 2 }} pb={2}>
            <Container>
                <Box mt={2} py={2}  >
                    <Grid container>
                        <Grid item xs={12}>
                            <Box display='flex' justifyContent='space-between' py={2} border='1px 1px 0px 1px solid silver'>
                                    <Button variant='contained' color='success' onClick={GotoNewPurchase}
                                    sx={{ height: '25px', borderRadius: 0, boxShadow: 0, fontWeight: 600 }} >
                                <AddIcon sx={{ verticalAlign: 'middle', fontWeight: 600 }}/> CREATE NEW PURCHASE</Button>
                            
                            </Box>
                        </Grid>
                        <TableContainer sx={{ border: '1px solid silver' }} >

                            <Table size='small' bgcolor='#eff8f2'>
                                <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: '#616e80' }}>
                                    <TableRow sx={{borderBottom: '1px solid silver'}}>
                                        {
                                            PurchaseListTable.map((i, index) => {
                                                return (
                                                    <TableCell sx={{
                                                        textAlign: 'center', color: 'white', fontWeight: 600, minHeight: '20px', borderRight: '1px solid silver',}}>
                                                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                            <Box sx={{ my: 'auto' }}>
                                                                {i.title}
                                                            </Box>
                                                            <Box>{
                                                                i.filter &&
                                                                <Filter search={search} setSearch={setSearch} setPage={setPage} />
                                                            }
                                                            </Box>
                                                        </Box>
                                                    </TableCell>
                                                )
                                            })
                                        }
                                    </TableRow>
                                </TableHead>


                                <TableBody>

                                    {
                                        purchaseList.filter((data) => FilterData(data, search, {
                                            searchFeildOne: data.billNo,
                                            searchFeildTwo: data.supplierName,
                                        })).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((i , index) => {
                                            return (
                                                <TableRow key={i.purchaseId} sx={{
                                                    borderBottom: '1px solid silver',
                                                    '& .MuiTableCell-root': {
                                                    height: '20px',
                                                    }}}>
                                                    <TableCell sx={{
                                                        textAlign: 'center', borderBottom: '1px solid silver', borderLeft: '1px solid silver',
                                                        borderRight: '1px solid silver',
                                                    }}>{i.purchaseDate}</TableCell>
                                                    <TableCell sx={{
                                                        textAlign: 'center', borderBottom: '1px solid silver', borderLeft: '1px solid silver',
                                                        borderRight: '1px solid silver',
                                                    }}>{i.billNo}</TableCell>
                                                    <TableCell sx={{
                                                        textAlign: 'center', borderBottom: '1px solid silver', borderLeft: '1px solid silver',
                                                        borderRight: '1px solid silver',
                                                    }}>{i.supplierName}</TableCell>
                                                    <TableCell sx={{
                                                        textAlign: 'center', borderBottom: '1px solid silver', borderLeft: '1px solid silver',
                                                        borderRight: '1px solid silver',
                                                    }}>{i.netTotal}</TableCell>
                                                    <TableCell sx={{
                                                        textAlign: 'center', borderBottom: '1px solid silver', borderLeft: '1px solid silver',
                                                        borderRight: '1px solid silver',
                                                    }}>{i.paidAmount}</TableCell>
                                                    <TableCell sx={{
                                                        textAlign: 'center', borderBottom: '1px solid silver', borderLeft: '1px solid silver',
                                                        borderRight: '1px solid silver',
                                                    }}><Button variant='contained' size='small' color='warning' sx={{ height: '25px', boxShadow: 0 }} onClick={(event) => handleClick(event, i.purchaseId)}>Actions <ArrowDropDownIcon sx={{verticalAlign:'middle'}} /></Button>
                                                       
                                                        <Box>
                                                            <Menu
                                                                id={`basic-menu-${index}`} // Unique id for each row's menu
                                                                anchorEl={anchorEl}
                                                                open={openMenu && currentPurchaseId === i.purchaseId} // Check if the current purchaseId matches the selected row's purchaseId
                                                                onClose={handleClose}
                                                                MenuListProps={{
                                                                    'aria-labelledby': `basic-button-${index}`, // Unique id for each row's menu
                                                                }}
                                                                sx={{ boxShadow: 0, mt: 1 }}
                                                            >
                                                                <MenuItem onClick={() => GotoDetails(i.purchaseId)} ><VisibilityIcon sx={{ marginRight: 1, verticalAlign: 'middle' }} color='primary' />Details</MenuItem>
                                                                <MenuItem onClick={() => handlegenerateInvoice(i.purchaseId)} ><PrintIcon sx={{ marginRight: 1, verticalAlign: 'middle' }} color='warning' /> Bill</MenuItem>
                                                                <MenuItem ><DeleteIcon sx={{ marginRight: 1, verticalAlign: 'middle' }} color='error' /> Delete</MenuItem>
                                                            </Menu>
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }

                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={purchaseList.length}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Grid>
                </Box>
            </Container>
            </Box>
        </Box>
    );
}