import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Heading from '../../Components/Heading/Heading';
import axios from 'axios';
import { Container, Grid, Card, Typography, Button, IconButton } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';
import PaidIcon from '@mui/icons-material/Paid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FastRewindOutlinedIcon from '@mui/icons-material/FastRewindOutlined';
import { get_all_return_product, get_return } from '../../Services/APIService';
import SnackBar from '../../Components/AdminDashBoardComps/Snackbar';
import PrintIcon from '@mui/icons-material/Print';

export default function ReturnDetailView() {


    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [status, setStatus] = useState();
    const [color, setColor] = useState();
    const [returnList, setReturnList] = useState([]);
    const navigate = useNavigate();
    const [value, setValue] = React.useState(0);
    const location = useLocation();
    const { id } = location.state;

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`full-width-tabpanel-${index}`}
                aria-labelledby={`full-width-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 2 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `full-width-tab-${index}`,
            'aria-controls': `full-width-tabpanel-${index}`,
        };
    }

    useEffect(() => {
        const sendData = new FormData()
        sendData.append('returnOrderId', id)
        axios({
            method: "POST",
            url: get_all_return_product,
              data:sendData,
        }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(false)
                setColor(false)
            } else {
                setOpen(true)
                setMessage(res.data.message)
                setStatus(true)
                setColor(true)
                setReturnList(res.data.data);
            }
        }).catch(err => {
            console.log(err)
        });
    }, []);



    return (
        <Box p={2}>

            <Box p={3}>
                <Heading title={'return Details'} />
            </Box>

            <SnackBar open={open} setOpen={setOpen} status={status} color={color} message={message} />
            <Box component={Card} sx={{ boxShadow: 2 }}>

                <Grid container p={2} spacing={2}>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Box component={Card} pb={2} border='1px solid silver'>
                            <Box bgcolor='#00bca4' py={4}>
                                <ContactEmergencyIcon sx={{ color: '#FFFFFF' }} />
                                <Typography fontWeight={600} color='#FFFFFF' variant='h6'>Supplier Name</Typography>
                            </Box>
                            <Box p={2}>
                                <Box p={2} border='1px solid silver' display='flex' flexWrap='wrap' flexDirection='row' gap={1}>
                                    <Typography color='#616e80' fontWeight={600} variant='subtitle2'>Name:</Typography>
                                    <Typography fontWeight={600} variant='subtitle2'>{returnList.customerName}</Typography>
                                </Box>

                                <Box p={2} border='1px solid silver' display='flex' flexWrap='wrap' flexDirection='row' gap={1}>
                                    <Typography color='#616e80' fontWeight={600} variant='subtitle2'>Email:</Typography>
                                    <Typography fontWeight={600} variant='subtitle2'>{returnList.email}</Typography>
                                </Box>
                                <Box p={2} border='1px solid silver' display='flex' flexWrap='wrap' flexDirection='row' gap={1}>
                                    <Typography color='#616e80' fontWeight={600} variant='subtitle2'>Phone:</Typography>
                                    <Typography fontWeight={600} variant='subtitle2'>{returnList.phoneNum}</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Box component={Card} pb={2} border='1px solid silver'>
                            <Box bgcolor='#65a6ff' sx={{ color: '#FFFFFF' }} py={4}>
                                <ReceiptIcon />
                                <Typography color='#FFFFFF' fontWeight={600} variant='h6'>Order Info</Typography>
                            </Box>
                            <Box p={2} >
                                <Box p={2} border='1px solid silver' display='flex' flexWrap='wrap' flexDirection='row' gap={1}>
                                    <Typography color='#616e80' fontWeight={600} variant='subtitle2'>Order Num:</Typography>
                                    <Typography fontWeight={600} variant='subtitle2'>{returnList.orderNum}</Typography>
                                </Box>
                                <Box p={2} border='1px solid silver' display='flex' flexWrap='wrap' flexDirection='row' gap={1}>
                                    <Typography color='#616e80' fontWeight={600} variant='subtitle2'>Order Date:</Typography>
                                    <Typography fontWeight={600} variant='subtitle2'>{returnList.orderDate}</Typography>
                                </Box>
                                <Box p={2} border='1px solid silver' display='flex' flexWrap='wrap' flexDirection='row' gap={1}>
                                    <Typography color='#616e80' fontWeight={600} variant='subtitle2'>Return Date:</Typography>
                                    <Typography fontWeight={600} variant='subtitle2'>{returnList.returnDate}</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Box component={Card} pb={2} border='1px solid silver'>
                            <Box bgcolor='#984dff' py={4}>
                                <PaidIcon sx={{ color: '#FFFFFF' }} />
                                <Typography color='#FFFFFF' fontWeight={600} variant='h6'>Payment Info</Typography>
                            </Box>
                            <Box p={2}>
                                <Box p={2} border='1px solid silver' display='flex' flexWrap='wrap' flexDirection='row' gap={1}>
                                    <Typography color='#616e80' fontWeight={600} variant='subtitle2'>Invoice Num:</Typography>
                                    <Typography fontWeight={600} variant='subtitle2'>${returnList.invoiceNum}</Typography>
                                </Box>
                                <Box p={2} border='1px solid silver' display='flex' flexWrap='wrap' flexDirection='row' gap={1}>
                                    <Typography color='#616e80' fontWeight={600} variant='subtitle2'>Total product Price:</Typography>
                                    <Typography fontWeight={600} variant='subtitle2'>${returnList.orderCost}</Typography>
                                </Box>
                                <Box p={2} border='1px solid silver' display='flex' flexWrap='wrap' flexDirection='row' gap={1}>
                                    <Typography color='#616e80' fontWeight={600} variant='subtitle2'>Tax Cost:</Typography>
                                    <Typography fontWeight={600} variant='subtitle2'>${returnList.taxCost}</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>






                <Container>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mt={4} mb={1}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="success"
                            textColor="inherit"
                            aria-label="full width tabs example"
                            variant="scrollable"
                            scrollButtons
                            allowScrollButtonsMobile
                        >
                            <Tab
                                label="Returned Items"
                                sx={{
                                    bgcolor: value === 0 && '#00bca4',
                                    border: value === 0 && '1px solid silver',
                                    borderRadius: value === 0 && '4px',
                                    textTransform: 'none'
                                }}
                                {...a11yProps(0)} />
                        </Tabs>
                    </Box>

                    {/* return items */}

                    <TabPanel value={value} index={0} >
                        <Box mb={2}>
                            <TableContainer >
                                <Table bgcolor='#F9F9F8' sx={{ borderLeft: '1px solid silver' }}>
                                    <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: '#616e80' }}>
                                        <TableRow>
                                            <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>#</TableCell>
                                            <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>
                                                Product
                                            </TableCell>
                                            <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>Quantity</TableCell>
                                            <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>Unit Price ($)</TableCell>
                                            <TableCell align="center">Sub Total ($)</TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {
                                            returnList?.product?.map((i, index) => {
                                                return (
                                                    <TableRow>
                                                        <TableCell align="center" sx={{ borderRight: '1px solid silver', borderLeft: '1px solid silver' }}>
                                                            {index + 1}
                                                        </TableCell>

                                                        <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>
                                                            {i.productName}
                                                        </TableCell>

                                                        <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>
                                                            {i.productQty}
                                                        </TableCell>

                                                        <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>
                                                            {i.productPrice}
                                                        </TableCell>

                                                        <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>
                                                            {i.subTotal}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })}

                                        <TableRow>
                                            <TableCell sx={{ borderLeft: '1px solid silver', bgcolor: '#F8FCD4' }} />
                                            <TableCell align="right" colSpan={3} sx={{ borderRight: '1px solid silver', bgcolor: '#F8FCD4', fontWeight: 600 }}>Tax Cost($) :</TableCell>
                                            <TableCell align="center" sx={{ borderRight: '1px solid silver', bgcolor: '#F8FCD4' }}>
                                                {returnList?.taxCost}
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell sx={{ borderLeft: '1px solid silver', bgcolor: '#F8FCD4' }} />
                                            <TableCell align="right" colSpan={3} sx={{ borderRight: '1px solid silver', bgcolor: '#F8FCD4', fontWeight: 600 }}>Total Product Price($) : </TableCell>
                                            <TableCell align="center" sx={{ borderRight: '1px solid silver', bgcolor: '#F8FCD4' }}>
                                                {returnList?.orderCost}
                                            </TableCell>
                                        </TableRow>

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </TabPanel>

                </Container>

                <Box p={1} display='flex' justifyContent='end'>
                    <IconButton onClick={() => navigate(-1)} sx={{ width: 100, height: 40, border: '3px solid black', borderRadius: 1 }}>
                        <Button sx={{ color: '#000000', fontWeight: 600, textTransform: 'none' }}><FastRewindOutlinedIcon sx={{ verticalAlign: 'middle' }} /> Back</Button>
                    </IconButton>
                </Box>


            </Box>



        </Box>
    )
}
