import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Container, Typography } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import { get_home_image, getallcategory } from '../../Services/APIService';
import axios from 'axios';
import TrendingLayout from './Trending/TrendingLayout';

export default function SaleTab() {

    const [value, setValue] = React.useState(0);
    const [open, setOpen] = useState(false);
    const [status, setStatus] = useState(false);
    const [color, setColor] = useState(false);
    const [message, setMessage] = useState("");
    const [SalesPagesection1, setSalesPagesection1] = useState([])
    const [SalesPagesection2, setSalesPagesection2] = useState([])
    const [SalesPagesection3, setSalesPagesection3] = useState([])
    const [SalesPagesection4, setSalesPagesection4] = useState([])
    const [SalesPagesection5, setSalesPagesection5] = useState([])
    const [SalesPagesection6, setSalesPagesection6] = useState([])
    const [SalesPagesection7, setSalesPagesection7] = useState([])
    const [SalesPagesection8, setSalesPagesection8] = useState([])
    const [SalesPagesection9, setSalesPagesection9] = useState([])
    const [categoryList, setCategoryList] = useState([]);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`full-width-tabpanel-${index}`}
                aria-labelledby={`full-width-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 2 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `full-width-tab-${index}`,
            'aria-controls': `full-width-tabpanel-${index}`,
        };
    }

    useEffect(() => {
        getAllData();
    }, [])



    const getAllData = () => {
        const sendData = new FormData()
        sendData.append('homePageImageId', '');
        axios({
          method: 'POST',
          url: get_home_image,
          data: sendData,
        }).then(res => {
            if (!res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(false)
                setColor(false)
                setSalesPagesection1(res.data.data.SalesPage_layout_1[0])
                setSalesPagesection2(res.data.data.SalesPage_layout_2)
                setSalesPagesection3(res.data.data.SalesPage_layout_3)
                setSalesPagesection4(res.data.data.SalesPage_layout_4)
                setSalesPagesection5(res.data.data.SalesPage_layout_5)
                setSalesPagesection6(res.data.data.SalesPage_layout_6)
                setSalesPagesection7(res.data.data.SalesPage_layout_7)
                setSalesPagesection8(res.data.data.SalesPage_layout_8)
                setSalesPagesection9(res.data.data.SalesPage_layout_9[0])
            } else {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(true)
                setColor(true)
            }
        }).catch(err => {
            alert('Oops something went wrong ' + err)
        });
    }


    useEffect(() => {
        axios({
            method: "GET",
            url: getallcategory,
        }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(false)
                setColor(false)
            } else {
                setMessage(res.data.message)
                setCategoryList(res.data.data);
                setStatus(true)
                setColor(true)
            }
        }).catch(err => {
            console.log(err)
        });
    }, [])


    const CategoryOptions = categoryList.map((i) => {
        const obj = {
            'label': i.categoriesName,
            'id': i.categoryId,
        }
        return obj;
    });

    return (
        <Container>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' , overflow:'auto' }} mt={4} mb={1}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="success"
                    textColor="inherit"
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    aria-label="full width tabs example"
                >
                    <Tab
                        label="Sale Layout 1"
                        sx={{
                            bgcolor: value === 0 && '#00bca4',
                            border: value === 0 && '1px solid silver',
                            borderRadius: value === 0 && '4px',
                            textTransform: 'none'
                        }}
                        {...a11yProps(0)} />
                    <Tab
                        label="Sale Layout 2"
                        sx={{
                            bgcolor: value === 1 && '#00bca4',
                            border: value === 1 && '1px solid silver',
                            borderRadius: value === 1 && 1,
                            textTransform: 'none'
                        }}
                        {...a11yProps(1)}
                    />
                    <Tab
                        label="Sale Layout 3"
                        sx={{
                            bgcolor: value === 2 && '#00bca4',
                            border: value === 2 && '1px solid silver',
                            borderRadius: value === 2 && 2,
                            textTransform: 'none'
                        }}
                        {...a11yProps(1)}
                    />
                    <Tab
                        label="Sale Layout 4"
                        sx={{
                            bgcolor: value === 3 && '#00bca4',
                            border: value === 3 && '1px solid silver',
                            borderRadius: value === 3 && 3,
                            textTransform: 'none'
                        }}
                        {...a11yProps(1)}
                    />
                    <Tab
                        label="Sale Layout 5"
                        sx={{
                            bgcolor: value === 4 && '#00bca4',
                            border: value === 4 && '1px solid silver',
                            borderRadius: value === 4 && 4,
                            textTransform: 'none'
                        }}
                        {...a11yProps(1)}
                    />
                    <Tab
                        label="Sale Layout 6"
                        sx={{
                            bgcolor: value === 5 && '#00bca4',
                            border: value === 5 && '1px solid silver',
                            borderRadius: value === 5 && 5,
                            textTransform: 'none'
                        }}
                        {...a11yProps(1)}
                    />
                    <Tab
                        label="Sale Layout 7"
                        sx={{
                            bgcolor: value === 6 && '#00bca4',
                            border: value === 6 && '1px solid silver',
                            borderRadius: value === 6 && 6,
                            textTransform: 'none'
                        }}
                        {...a11yProps(1)}
                    />
                    <Tab
                        label="Sale Layout 8"
                        sx={{
                            bgcolor: value === 7 && '#00bca4',
                            border: value === 7 && '1px solid silver',
                            borderRadius: value === 7 && 7,
                            textTransform: 'none'
                        }}
                        {...a11yProps(1)}
                    />
                    <Tab
                        label="Sale Layout 9"
                        sx={{
                            bgcolor: value === 8 && '#00bca4',
                            border: value === 8 && '1px solid silver',
                            borderRadius: value === 8 && 8,
                            textTransform: 'none'
                        }}
                        {...a11yProps(1)}
                    />
                </Tabs>
            </Box>

            <TabPanel value={value} index={0} >
                <TrendingLayout Dimensions={'1920 * 2880 '} CategoryOptions={CategoryOptions} section={'SalesPage_layout_1'} Layout={SalesPagesection1} getAllData={getAllData} />
            </TabPanel>

            <TabPanel value={value} index={1}>
              <TrendingLayout Dimensions={'385 * 385 '}  CategoryOptions={CategoryOptions} section={'SalesPage_layout_2'} Layout={SalesPagesection2} getAllData={getAllData} />
            </TabPanel>

            <TabPanel value={value} index={2}>
            <TrendingLayout Dimensions={'385 * 385 '}  CategoryOptions={CategoryOptions} section={'SalesPage_layout_3'} Layout={SalesPagesection3} getAllData={getAllData} />
            </TabPanel>


            <TabPanel value={value} index={3}>
                <TrendingLayout Dimensions={'385 * 385 '} CategoryOptions={CategoryOptions} section={'SalesPage_layout_4'} Layout={SalesPagesection4} getAllData={getAllData} />
            </TabPanel>

            <TabPanel value={value} index={4}>
           <TrendingLayout Dimensions={'385 * 385 '} CategoryOptions={CategoryOptions} section={'SalesPage_layout_5'} Layout={SalesPagesection5} getAllData={getAllData} />
            </TabPanel>

            <TabPanel value={value} index={5}>
                <TrendingLayout Dimensions={'385 * 385 '} CategoryOptions={CategoryOptions} section={'SalesPage_layout_6'} Layout={SalesPagesection6} getAllData={getAllData} />
            </TabPanel>

            <TabPanel value={value} index={6}>
                <TrendingLayout Dimensions={'385 * 300 '} CategoryOptions={CategoryOptions}  section={'SalesPage_layout_7'} Layout={SalesPagesection7} getAllData={getAllData} />
            </TabPanel>

            <TabPanel value={value} index={7}>
                <TrendingLayout Dimensions={'385 * 250 '} CategoryOptions={CategoryOptions} section={'SalesPage_layout_8'} Layout={SalesPagesection8} getAllData={getAllData} />
            </TabPanel>

            <TabPanel value={value} index={8}>
                <TrendingLayout Dimensions={'1920 * 2880 '} CategoryOptions={CategoryOptions} section={'SalesPage_layout_9'} Layout={SalesPagesection9} getAllData={getAllData} />
            </TabPanel>

        </Container>
    )
}
