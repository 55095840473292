import { Box, Typography, Card, Grid, TextField, Button, Autocomplete, Stack } from '@mui/material'
import React, { useEffect } from 'react'
import Heading from '../../Components/Heading/Heading';
import { useState } from 'react';
import { add_vendor, get_vendor } from '../../Services/APIService';
import axios from 'axios';
import SnackBar from '../../Components/AdminDashBoardComps/Snackbar';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { AllVendorTable } from '../../Variables/Variables';
import VendorEditDialog from './VendorEditDialog';
import VendorDeleteDialog from './VendorDeleteDialog';

export default function Vendors() {

    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [status, setStatus] = useState();
    const [color, setColor] = useState();
    const [vendorId, setVendorId] = useState('');
    const [vendorName, setVendorName] = useState('');
    const [contactPerson, setContactPerson] = useState('');
    const [mobile, setMobile] = useState('');
    const [email, setEmail] = useState('');
    const [stateName, setstateName] = useState('');
    const [city, setcity] = useState('');
    const [phone, setPhone] = useState('');
    const [country, setCountry] = useState('');
    const [pincode, setpincode] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [search, setSearch] = useState('');
    const [VendorList, setVendorList] = useState([]);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);


    const getallVendorAPI = () =>{
        axios({
            method: "POST",
            url: get_vendor,
        }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(false)
                setColor(false)
            } else {
                setOpen(true)
                setMessage(res.data.message)
                setVendorList(res.data.data);
                setStatus(true)
                setColor(true)
            }
        }).catch(err => {
            console.log(err)
        });
        }

            useEffect(() => {
              getallVendorAPI();
            }, [])



    const handleClick = () => {
        const serverData = new FormData()
        serverData.append('vendorName', vendorName)
        serverData.append('contactPerson', contactPerson)
        serverData.append('mobile', mobile)
        serverData.append('email', email)
        serverData.append('city', city)
        serverData.append('stateName', stateName)
        serverData.append('country', country)
        serverData.append('pincode', pincode)
        axios({
            method: "POST",
            url: add_vendor,
            data: serverData,
        }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(false)
                setColor(false)
            } else {
                setOpen(true)
                setMessage(res.data.message)
                setStatus(true)
                setColor(true);
                setVendorName('');
                setContactPerson('');
                setMobile('');
                setEmail('');
                setstateName('');
                setcity('');
                setPhone('');
                setCountry('');
                setpincode('');
                getallVendorAPI();
            }
        }).catch(err => {
            console.log(err)
        });
    }


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const DeleteVendor = (id) =>{
       setOpenDeleteDialog(true)
       setVendorId(id);
    }

    const EditVendor = (id) =>{
       setOpenEditDialog(true);
        setVendorId(id);
    }
    
  return (
      <Box p={3}>

          <SnackBar open={open} setOpen={setOpen} status={status} color={color} message={message} />
          <Box py={3}>
              <Heading title={'Create Vendor'} />
          </Box>

          <VendorEditDialog setVendorId={setVendorId} vendorId={vendorId} getallVendorAPI={getallVendorAPI} openEditDialog={openEditDialog} setOpenEditDialog={setOpenEditDialog} setMessage={setMessage} setOpen={setOpen} setStatus={setStatus} setColor={setColor} />
         
          <VendorDeleteDialog setVendorId={setVendorId} vendorId={vendorId} getallVendorAPI={getallVendorAPI} openDeleteDialog={openDeleteDialog} setOpenDeleteDialog={setOpenDeleteDialog} setMessage={setMessage} setOpen={setOpen} setStatus={setStatus} setColor={setColor}  />

          <Box component={Card} py={3} boxShadow={4}>
              <Grid container>
                  <Grid item xs={12} sm={12} md={12} lg={12} >
                      <Box textAlign='left' p={2}>
                          <Typography variant='h6' fontWeight={500}>Create New Vendor</Typography>
                      </Box>
                      <Grid container display='flex' flexDirection={{ xs: 'column', sm: 'row', md: 'row', lg: 'row' }} p={2} spacing={2}>
                          <Grid item xs={12} sm={6} md={4} lg={4}>
                              <TextField
                                value={vendorName}
                                  onChange={(e) => setVendorName(e.target.value)}
                                  size='small'
                                  fullWidth
                                  type='text'
                                  label="Vendor Name"
                                  variant="outlined" />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={4}>
                              <TextField
                                   value={contactPerson}
                                  onChange={(e) => setContactPerson(e.target.value)}
                                  size='small'
                                  fullWidth
                                  type='text'
                                  label="Contact Person"
                                  variant="outlined" />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={4}>
                              <TextField
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                  size='small'
                                  fullWidth
                                  type='email'
                                  label="Email"
                                  variant="outlined" />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={4}>
                              <TextField
                                  value={mobile}
                                  onChange={(e) => setMobile(e.target.value)}
                                  size='small'
                                  fullWidth
                                  type='text'
                                  label="Mobile"
                                  variant="outlined" />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={4}>
                              <TextField
                                   value={city}
                                  onChange={(e) => setcity(e.target.value)}
                                  size='small'
                                  fullWidth
                                  type='text'
                                  label="City"
                                  variant="outlined" />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={4}>
                              <TextField
                                  value={stateName}
                                  onChange={(e) => setstateName(e.target.value)}
                                  size='small'
                                  fullWidth
                                  type='text'
                                  label="State"
                                  variant="outlined" />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={4}>
                              <TextField
                                  value={country}
                                  onChange={(e) => setCountry(e.target.value)}
                                  size='small'
                                  fullWidth
                                  type='text'
                                  label="Country"
                                  variant="outlined" />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={4}>
                              <TextField
                                  value={pincode}
                                  onChange={(e) => setpincode(e.target.value)}
                                  size='small'
                                  fullWidth
                                  type='text'
                                  label="Postal Code"
                                  variant="outlined" />
                          </Grid>

                          <Grid item xs={12} sm={12} md={4} lg={4}>
                              <Box>
                                  <Button onClick={handleClick} variant='contained' fullWidth
                                      sx={{ bgcolor: '#faa634', ':hover': { bgcolor: '#316C31' } }}>
                                      Create Vendor</Button>
                              </Box>
                          </Grid>
                      </Grid>

                  </Grid>
              </Grid>
          </Box>

          <Box py={2} mt={3}>
              <Heading title={'All Vendors'} />
          </Box>

          <Box py={3}>
              <Grid container>
                  <TableContainer sx={{ border: '1px solid silver', boxShadow:4 }} >

                      <Table>
                          <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: '#616e80' }}>
                              <TableRow sx={{ borderBottom: '1px solid silver' }}>
                                  {
                                      AllVendorTable.map((i, index) => {
                                          return (
                                              <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                      <Box sx={{ my: 'auto' }}>
                                                          {i.title}
                                                      </Box>
                                                  </Box>
                                              </TableCell>
                                          )
                                      })
                                  }
                              </TableRow>
                          </TableHead>


                          <TableBody>

                              {
                                  VendorList.map((i, index) => {
                                      return (
                                          <TableRow key={index} sx={{ borderBottom: '1px solid silver' }}>
                                        <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}>{index + 1}</TableCell>
                                        <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}>{i.vendorName}</TableCell>
                                        <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}>{i.contactPerson}</TableCell>
                                        <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}>{i.mobile}</TableCell>
                                        <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}>{i.email}</TableCell>
                                              <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}>{i.city}, {i.stateName}, {i.country}, {i.pincode}</TableCell>
                                              <TableCell>
                                                  <Box display='flex' flexDirection='row' gap={2}>
                                                    <Button variant="contained" size='small' onClick={()=>EditVendor(i.vendorId)} fullWidth>EDIT</Button>
                                                      <Button variant="contained" size='small' onClick={() => DeleteVendor(i.vendorId)} fullWidth color='error' >DELETE</Button>
                                                  </Box>
                                              </TableCell>
                                          </TableRow>
                                      )
                                  })
                              }

                          </TableBody>
                      </Table>
                  </TableContainer>
                  <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={VendorList.length}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                  />
              </Grid>
          </Box>
    </Box>
  )
}
