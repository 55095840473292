import React, { useRef } from 'react'
import { Box, Typography, Button , TextField, Menu, MenuItem, Grid} from '@mui/material';
import { Bar, Line } from 'react-chartjs-2';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { MonthlyLabels, TodayLabels, WeeklyLabels, backgroundColor, borderColor } from '../../../Variables/Variables';
import { Chart as Chartjs } from 'chart.js/auto';
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import { channel_sale_orders_graph, get_revenue } from '../../../Services/APIService';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function ChannelSaleChart() {

  const controller = new AbortController();
  const signal = controller.signal;

  
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [color, setColor] = useState();
  const [data , setData] = useState([]);
  const[currentMode , setCurentMode] = useState('Today');
  const [weekNumber , setWeekNumber] = useState('');
  const [fromDate , setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const[yearnumber , setYearNumber] = useState('');
  const [showWeek , setShowWeek ] = useState(false);
  const [showToday ,  setShowToday ] = useState(false);
  const [showMonth , setShowMonth ] = useState(false);

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const textFieldRef = useRef();
  const textFieldYearRef = useRef();
  const textFieldWeekRef = useRef();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };



 const getFilteredTodayData = () =>{
  const sendData = new FormData()
  sendData.append('checkBox', 'Today');

   axios({
    method: 'POST',
     url: channel_sale_orders_graph,
     data: sendData,
}).then(res => {
    if (res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(false)
        setColor(false)
    } else {
        setOpen(true)
        setMessage(res.data.message)
        setStatus(true)
        setColor(true)
        setData(res.data.data)
    }
}).catch(err => {
    console.log(err)
});
 }

 useEffect(() => {
  getFilteredTodayData();
 }, [])


  const getFilteredWeekData = (week) =>{
    const sendData = new FormData()
    sendData.append('checkBox', 'Weekly');
    sendData.append('weekNumber', week !== undefined ? week : '');
     axios({
      method: 'POST',
      url: channel_sale_orders_graph,
       data: sendData,
  }).then(res => {
      if (res.data.error) {
          setMessage(res.data.message)
          setOpen(true)
          setStatus(false)
          setColor(false)
      } else {
          setOpen(true)
          setMessage(res.data.message)
          setStatus(true)
          setColor(true)
          setData(res.data.data)
      }
  }).catch(err => {
      console.log(err)
  });
  }



  const getFilteredMonthData = () =>{
    const sendData = new FormData()
    sendData.append('checkBox', 'Monthly');
    sendData.append('fromDate', fromDate);
    sendData.append('toDate', textFieldRef?.current?.value !== undefined ? textFieldRef?.current?.value : '');
     axios({
      method: 'POST',
      url: channel_sale_orders_graph,
       data: sendData,
  }).then(res => {
      if (res.data.error) {
          setMessage(res.data.message)
          setOpen(true)
          setStatus(false)
          setColor(false)
      } else {
          setOpen(true)
          setMessage(res.data.message)
          setStatus(true)
          setColor(true)
          setData(res.data.data)
      }
  }).catch(err => {
      console.log(err)
  });
  }

  const ChangeMode = (mode) =>{
    setCurentMode(mode);
    if (mode === 'Today'){
      setCurentMode('Today');
      setShowToday(true);
      setShowWeek(false);
      setShowMonth(false);
      getFilteredTodayData('');
      handleClose();
    }
    else if (mode === 'Weekly'){
      setCurentMode('Weekly');
     setShowWeek(true);
    setShowMonth(false);
    setShowToday(false);
    getFilteredWeekData('');
    handleClose();
    }
    else if (mode === 'Monthly'){
      setCurentMode('Monthly')
      setShowMonth(true);
      setShowWeek(false);
      setShowToday(false);
      getFilteredMonthData();
      handleClose();
    }
  }

  const labels = currentMode === 'Monthly' ? MonthlyLabels :
  currentMode === 'Weekly' ? WeeklyLabels :
  TodayLabels;

  const linedata = {
    labels: labels,
    datasets: [{
      label: ['PROFIT & LOSS'],
      backgroundColor: backgroundColor[4],
      borderColor: borderColor[4],
      borderWidth: 1,
      data: data && data.map((graphdata) => graphdata.totalRevenue),
      barThickness: 20,
    }]
  };



  return (
    <Box>
      <Box sx={{ p: 2}}>

      <Grid container justifyContent='space-between' spacing={3}>

      <Grid item xs={12} sm={6} md={6} lg={6}>
      <Box>
      <Typography
        variant="h6"
        sx={{ textAlign: 'start', fontWeight: 550, fontSize: 18.5, textTransform: 'uppercase', color: 'primary.main' }}
      >
       Channel Sale
      </Typography>
      </Box>
      </Grid>
      
      <Grid item xs={12} sm={6} md={6} lg={6} justifyContent='end' display='flex'>
      <Grid container spacing={3}>
      <Grid item xs={12} lg={12} justifyContent='end' display='flex'>
      <Box sx={{cursor:'pointer'}} display='flex' flexDirection='row' gap={3}>
      <Typography sx={{color:'#faa634' , cursor:'pointer'}} onClick={()=>ChangeMode('Today')}>Today</Typography>
      <Typography sx={{color:'#faa634' , cursor:'pointer'}} onClick={()=>ChangeMode('Weekly')}>Weekly</Typography>
       <Typography sx={{color:'#faa634' , cursor:'pointer'}}  onClick={()=>ChangeMode('Monthly')}>Monthly</Typography>
    </Box>
      </Grid>
      <Grid item xs={12} lg={12} justifyContent='end' display='flex'>
      <Box>
      {
      showWeek &&
      <input
      type="week"
      inputRef={textFieldWeekRef}
      name="week" 
      onChange={(e) => {
        const fullWeekValue = e.target.value; // e.g., "2018-W24"
        const weekNumber = fullWeekValue.replace('-W', ''); // Remove "-W"
        setWeekNumber(weekNumber);
        getFilteredWeekData(weekNumber);
      }}  
      id="camp-week"
      min="2018-W18"
      required
      className="MuiInputBase-input MuiInput-input"
      />
      }
      {
        showMonth &&
        <Box display='flex' flexDirection={{lg:'row', md:'row', sm:'row', xs:'column'}} gap={2}>
        <TextField
        type="date"
        id="start" 
        size='small'
        name="trip-start"
        onChange={(e) => {
        setFromDate(e.target.value);
        }}
        label='From date'
        required
        InputLabelProps={{
          shrink: true,
        }}
          />
    
          <TextField
          type="date"
          id="end" 
          size='small'
          name="trip-end"
          inputRef={textFieldRef}
          onChange={(e) => {
          setToDate(e.target.value);
            getFilteredMonthData();
          }}
          label='To date'
          required
          InputLabelProps={{
            shrink: true,
          }}
            />
       </Box>
      }
      </Box>
      </Grid>
      </Grid>

      </Grid>
      </Grid>




      </Box>
      <Box>
        <Bar data={linedata} options={{
          scales: {
            y: {
              beginAtZero: true,
              ticks:{
                color: 'black',
                font:{
                  size:12,
                  weight:'500',
                  family:"'Jost', sans-serif"
                }
              }
            },  
            x: {
              ticks:{
                color: 'black',
                font:{
                  size:12,
                  weight:'500',
                  family:"'Jost', sans-serif"
                }
              }
            },         
          },
          plugins: {
            legend: {
              labels:{
                font:{
                  size:12,
                  weight:'500',
                  family:"'Jost', sans-serif"
                }
              },
                display: false,
                position:'top'
            }
        }
        }} />
      </Box>
    </Box>
  )
}

export default ChannelSaleChart