import React, { useEffect, useState } from 'react'
import { Container, Grid, Button, Box, Typography, Card, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Autocomplete, } from '@mui/material';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import { get_vendor, update_vendor } from '../../Services/APIService';


export default function VendorEditDialog({ setVendorId , vendorId ,getallVendorAPI, setMessage, setOpen, setStatus, setColor, setOpenEditDialog, openEditDialog }) {

    const [vendorName, setVendorName] = useState('');
    const [contactPerson, setContactPerson] = useState('');
    const [mobile, setMobile] = useState('');
    const [email, setEmail] = useState('');
    const [state, setstate] = useState('');
    const [city, setcity] = useState('');
    const [phone, setPhone] = useState('');
    const [country, setCountry] = useState('');
    const [pincode, setpincode] = useState('');
    const [Existingmobile, setExistingMobile] = useState('');
    const [Existingemail, setExistingEmail] = useState('');

    const handleClose = () => {
        setOpenEditDialog(false);
        getallVendorAPI();
        setVendorId('');
    }

    useEffect (() => {
        const sendData = new FormData();
        sendData.append('vendorId',  vendorId)
        axios({
            method: "POST",
            url: get_vendor,
            data:sendData,
        }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(false)
                setColor(false)
            } else {
                setOpen(true)
                setMessage(res.data.message)
                setStatus(true)
                setColor(true)
                setVendorName(res.data.data.vendorName);
                setContactPerson(res.data.data.vendorName);
                setMobile(res.data.data.mobile);
                setEmail(res.data.data.email);
                setstate(res.data.data.stateName);
                setExistingEmail(res.data.data.email);
                setExistingMobile(res.data.data.mobile);
                setcity(res.data.data.city);
                setCountry(res.data.data.country);
                setpincode(res.data.data.pincode);
            }
        }).catch(err => {
            console.log(err)
        });
    },[vendorId]);



    const handleClick = () => {
        const serverData = new FormData()
        serverData.append('vendorName', vendorName)
        serverData.append('contactPerson', contactPerson)
        serverData.append('city', city)
        serverData.append('stateName', state)
        serverData.append('country', country)
        serverData.append('pincode', pincode)
        serverData.append('vendorId', vendorId)
        if (email !== Existingemail) {
            serverData.append('email', email);
        } else {
            serverData.append('email', '');
        }
        if (mobile !== Existingmobile) {
            serverData.append('mobile', mobile);
        } else {
            serverData.append('mobile', '');
        }
        axios({
            method: "POST",
            url: update_vendor,
            data: serverData,
        }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(false)
                setColor(false)
            } else {
                setOpen(true)
                setMessage(res.data.message)
                setStatus(true)
                setColor(true);
                setVendorName('');
                setContactPerson('');
                setMobile('');
                setEmail('');
                setstate('');
                setcity('');
                setCountry('');
                setpincode('');
                getallVendorAPI();
                setExistingEmail('');
                setExistingMobile('');
                handleClose();
            }
        }).catch(err => {
            console.log(err)
        });
    }


    return (
        <Dialog onClose={handleClose} open={openEditDialog} fullWidth>
            <DialogTitle sx={{ borderBottom: '1px solid silver' }}>
                EDIT VENDOR INFORMATION
            </DialogTitle>
            <DialogContent>

            <Grid container display='flex' flexDirection={{ xs: 'column', sm: 'row', md: 'row', lg: 'row' }} p={2} spacing={2}>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                    <TextField
                        value={vendorName}
                        onChange={(e) => setVendorName(e.target.value)}
                        size='small'
                        fullWidth
                        type='text'
                        label="Vendor Name"
                        InputLabelProps={{shrink:true}}
                        variant="outlined" />
                </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
                    <TextField
                        value={contactPerson}
                        onChange={(e) => setContactPerson(e.target.value)}
                        size='small'
                        fullWidth
                        type='text'
                        label="Contact Person"
                            InputLabelProps={{ shrink: true }}
                        variant="outlined" />
                </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
                    <TextField
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        size='small'
                        fullWidth
                        type='email'
                        label="Email"
                            InputLabelProps={{ shrink: true }}
                        variant="outlined" />
                </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
                    <TextField
                        value={mobile}
                        onChange={(e) => setMobile(e.target.value)}
                        size='small'
                        fullWidth
                        type='text'
                        label="Mobile"
                            InputLabelProps={{ shrink: true }}
                        variant="outlined" />
                </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
                    <TextField
                        value={city}
                        onChange={(e) => setcity(e.target.value)}
                        size='small'
                        fullWidth
                        type='text'
                        label="City"
                            InputLabelProps={{ shrink: true }}
                        variant="outlined" />
                </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
                    <TextField
                         value={state}
                        onChange={(e) => setstate(e.target.value)}
                        size='small'
                        fullWidth
                        type='text'
                        label="State"
                            InputLabelProps={{ shrink: true }}
                        variant="outlined" />
                </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
                    <TextField
                         value={country}
                        onChange={(e) => setCountry(e.target.value)}
                        size='small'
                        fullWidth
                        type='text'
                        label="Country"
                            InputLabelProps={{ shrink: true }}
                        variant="outlined" />
                </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
                    <TextField
                         value={pincode}
                        onChange={(e) => setpincode(e.target.value)}
                        size='small'
                        fullWidth
                        type='text'
                        label="Postal Code"
                            InputLabelProps={{ shrink: true }}
                        variant="outlined" />
                </Grid>
            </Grid>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant='outlined' color='error'>CLOSE</Button>
                <Button variant='outlined' color='success' onClick={handleClick}>UPDATE</Button>
            </DialogActions>
        </Dialog>
    )
}
