import { Box, Typography, Card,  Grid } from '@mui/material'
import React, { useState } from 'react'
import CategoryIcon from '@mui/icons-material/Category';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart';
import BalanceIcon from '@mui/icons-material/Balance';
import StoreIcon from '@mui/icons-material/Store';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import Groups2Icon from '@mui/icons-material/Groups2';
import PieChartIcon from '@mui/icons-material/PieChart';
import SavingsIcon from '@mui/icons-material/Savings';
import ProfitChart from './Charts/ProfitChart';
import ExpenseVsSalesChart from './Charts/ExpenseVsSalesChart';
import WebOrderChart from './Charts/WebOrderChart';
import ChannelSaleChart from './Charts/ChannelSaleChart';
import StockChart from './Charts/StockChart';
import OutOfStockChart from './Charts/OutOfStockChart';



export default function ReportGraph() {

  return (
   <Box p={2}>

          <Grid container spacing={2}>

          
          <Grid item xs={12} sm={6} md={6} lg={6}>
          <Box component={Card} sx={{ p: 2, borderRadius: 5, marginTop:2.5, boxShadow: 5}}>
           <ProfitChart />
           </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6}>
          <Box component={Card} sx={{ p: 2, borderRadius: 5, marginTop:2.5, boxShadow: 5}}>
           <ExpenseVsSalesChart />
           </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6}>
          <Box component={Card} sx={{ p: 2, borderRadius: 5, marginTop:2.5, boxShadow: 5}}>
           <WebOrderChart />
           </Box>
          </Grid>


          <Grid item xs={12} sm={6} md={6} lg={6}>
          <Box component={Card} sx={{ p: 2, borderRadius: 5, marginTop:2.5, boxShadow: 5}}>
           <ChannelSaleChart />
           </Box>
          </Grid>


          <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box component={Card} sx={{ p: 2, borderRadius: 5, marginTop:2.5, boxShadow: 5}}>
           <StockChart />
           </Box>
          </Grid>


          <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box component={Card} sx={{ p: 2, borderRadius: 5, marginTop:2.5, boxShadow: 5}}>
           <OutOfStockChart />
           </Box>
          </Grid>

          </Grid>
   </Box>
  )
}
