import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Container, Typography } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import { get_home_image, getallcategory } from '../../../Services/APIService';
import axios from 'axios';
import TrendingLayout from './TrendingLayout';


export default function TredingTab() {

    const [value, setValue] = React.useState(0);
    const [TrendingSection1, setTrendingSection1] = useState([])
    const [TrendingSection2, setTrendingSection2] = useState([])
    const [TrendingSection3, setTrendingSection3] = useState([])
    const [TrendingSection4, setTrendingSection4] = useState([])
    const [TrendingSection5, setTrendingSection5] = useState([])
    const [TrendingSection6, setTrendingSection6] = useState([])
    const [categoryList, setCategoryList] = useState([]);
    const [open, setOpen] = useState(false);
    const [status, setStatus] = useState(false);
    const [color, setColor] = useState(false);
    const [message, setMessage] = useState("");
    
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`full-width-tabpanel-${index}`}
                aria-labelledby={`full-width-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 2 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `full-width-tab-${index}`,
            'aria-controls': `full-width-tabpanel-${index}`,
        };
    }

    useEffect(() => {
        getAllData();
    }, [])



    const getAllData = () => {
        const sendData = new FormData()
        sendData.append('homePageImageId', '');
        axios({
          method: 'POST',
          url: get_home_image,
          data: sendData,
        }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(false)
                setColor(false)
            } else {
                setMessage(res.data.message)
                setTrendingSection1(res.data.data.Trending_layout_1)
                setTrendingSection2(res.data.data.Trending_layout_2)
                setTrendingSection3(res.data.data.Trending_layout_3)
                setTrendingSection4(res.data.data.Trending_layout_4)
                setTrendingSection5(res.data.data.Trending_layout_5)
                setTrendingSection6(res.data.data.Trending_layout_6)
                setOpen(true)
                setStatus(true)
                setColor(true)
            }
        }).catch(err => {
            alert('Oops something went wrong ' + err)
        });
    }


    useEffect(() => {
        axios({
            method: "GET",
            url: getallcategory,
        }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(false)
                setColor(false)
            } else {
                setMessage(res.data.message)
                setCategoryList(res.data.data);
                setStatus(true)
                setColor(true)
            }
        }).catch(err => {
            console.log(err)
        });
    }, [])


    const CategoryOptions = categoryList.map((i) => {
        const obj = {
            'label': i.categoriesName,
            'id': i.categoryId,
        }
        return obj;
    });

    return (
        <Container>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mt={4} mb={1}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="success"
                    textColor="inherit"
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    aria-label="full width tabs example"
                >
                    <Tab
                        label="Trending Layout 1"
                        sx={{
                            bgcolor: value === 0 && '#00bca4',
                            border: value === 0 && '1px solid silver',
                            borderRadius: value === 0 && '4px',
                            textTransform: 'none'
                        }}
                        {...a11yProps(0)} />
                    <Tab
                        label="Trending Layout 2"
                        sx={{
                            bgcolor: value === 1 && '#00bca4',
                            border: value === 1 && '1px solid silver',
                            borderRadius: value === 1 && 1,
                            textTransform: 'none'
                        }}
                        {...a11yProps(1)}
                    />
                    <Tab
                        label="Trending Layout 3"
                        sx={{
                            bgcolor: value === 2 && '#00bca4',
                            border: value === 2 && '1px solid silver',
                            borderRadius: value === 2 && 2,
                            textTransform: 'none'
                        }}
                        {...a11yProps(1)}
                    />
                    <Tab
                        label="Trending Layout 4"
                        sx={{
                            bgcolor: value === 3 && '#00bca4',
                            border: value === 3 && '1px solid silver',
                            borderRadius: value === 3 && 3,
                            textTransform: 'none'
                        }}
                        {...a11yProps(1)}
                    />
                    <Tab
                        label="Trending Layout 5"
                        sx={{
                            bgcolor: value === 4 && '#00bca4',
                            border: value === 4 && '1px solid silver',
                            borderRadius: value === 4 && 4,
                            textTransform: 'none'
                        }}
                        {...a11yProps(1)}
                    />
                    <Tab
                        label="Trending Layout 6"
                        sx={{
                            bgcolor: value === 5 && '#00bca4',
                            border: value === 5 && '1px solid silver',
                            borderRadius: value === 5 && 5,
                            textTransform: 'none'
                        }}
                        {...a11yProps(1)}
                    />
                </Tabs>
            </Box>

            <TabPanel value={value} index={0} >
                <TrendingLayout Dimensions={'385 * 385 '}  CategoryOptions={CategoryOptions} section={'Trending_layout_1'} Layout={TrendingSection1} getAllData={getAllData} />
            </TabPanel>

           
            <TabPanel value={value} index={1}>
                <TrendingLayout Dimensions={'385 * 385 '}  CategoryOptions={CategoryOptions} section={'Trending_layout_2'} Layout={TrendingSection2} getAllData={getAllData}  />
            </TabPanel>

            <TabPanel value={value} index={2}>
                <TrendingLayout Dimensions={'385 * 300 '}   CategoryOptions={CategoryOptions} section={'Trending_layout_3'} Layout={TrendingSection3} getAllData={getAllData} />
            </TabPanel>

            <TabPanel value={value} index={3}>
                <TrendingLayout Dimensions={'385 * 300 '}   CategoryOptions={CategoryOptions} section={'Trending_layout_4'} Layout={TrendingSection4} getAllData={getAllData} />
            </TabPanel>


            <TabPanel value={value} index={4}>
                <TrendingLayout Dimensions={'385 * 300 '}   CategoryOptions={CategoryOptions} section={'Trending_layout_5'} Layout={TrendingSection5} getAllData={getAllData} />
            </TabPanel>

            <TabPanel value={value} index={5}>
                <TrendingLayout Dimensions={'385 * 250 '}   CategoryOptions={CategoryOptions} section={'Trending_layout_6'} Layout={TrendingSection6} getAllData={getAllData} />
            </TabPanel>

        </Container>
    )
}
