import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { Stack } from '@mui/material';
import axios from 'axios';
import { user_signin , forgot_password} from '../../Services/APIService';
import {appendData} from '../../Variables/ProcessVariable';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit">
        Shopztrend
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function ForgotPassword() {

      const [open, setOpen] = useState(false);
      const [message, setMessage] = useState("");
      const [status, setStatus] = useState();
      const [color, setColor] = useState();
      const [errorData , setErrorData]  = useState(false);
      const[password, setPassword]= useState('');
      const [confirmPassword, setConfirmPassword] = useState('');
      const [errorMsg, setErrorMsg] = useState('')
      const [error , setError] = useState(false);
      const[email, setEmail] = useState('');
      const [isValid, setIsValid] = useState(false);

      const navigate = useNavigate();

       // Validate Password
  const validateInput =()=> {
  if(password !=="" && confirmPassword !==""){
    if(password === confirmPassword){
      setError(false);
    }
    else{
      setErrorMsg("Passwords do not match")
      setError(true);
    }
  }
  else{
    setErrorMsg("")
    setError(false);
  }
}

//  Vaidate EMail
  const handleEmailChange = (event) => {
    const emailValue = event.target.value;
    const regex = /\S+@\S+\.\S+/;
    setIsValid(regex.test(emailValue));
    setEmail(emailValue);
  };


      const submit = () =>{
      if(password === confirmPassword && isValid){
        const obj = {
        newPassword: password,
        confirmNewPassword: confirmPassword,
        email: email
            }
      const sendData = appendData(obj);
        axios({
          method: 'POST',
          url: forgot_password,
          data: sendData,
      }).then(res => {
          console.log(res)
          if (res.data.error) {
              setMessage(res.data.message)
              setOpen(true)
              setStatus(false)
              setColor(false)
          } else {
              setMessage(res.data.message)
              setOpen(true)
              setStatus(true)
              setColor(true)
             navigate(-1)
          }
      }).catch(err => {
          alert('Oops something went wrong ' + err)
      });
      }
      else{
        setErrorMsg('Something Went Wrong')
      }
    }

  return (
    <div className="area" >
            <ul className="circles">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
 <Container component="main" maxWidth="xs">
               
          <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            color:'#ffff'
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box>
            <Stack textAlign='left' spacing={2}>
            <Box>
            <Typography>Your Email ID</Typography>
            <TextField
              margin="normal"
              required
              sx={{width: {xs:250, sm: 300, md: 500 , lg:500 }}}
              id="email"
              name="email"
              type='email'
              autoComplete="email"
              fullWidth
              onChange={handleEmailChange}
              error={error}
              size="small"
              defaultValue=''
            />
            </Box>
              <Box>
              <Typography>Enter New Password</Typography>
            <TextField
              margin="normal"
              required
              sx={{width: {xs:250, sm: 300, md: 500 , lg:500 }}}
                id="inputpassword"
                variant='outlined'
                name="password"
                type='password'
                autoComplete="off"
                onChange={(e)=>{setPassword(e.target.value);}}
                onBlur={validateInput}
            />
            </Box>
            <Box>
            <Typography>Confirm New Password</Typography>
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              type="password"
              id="password"
              autoComplete="off"
              helperText={errorMsg}
              onBlur={validateInput}
              onChange={(e)=>{setConfirmPassword(e.target.value);}}
              error={error}
            />
            </Box>
            </Stack>
            <Button
              type="submit"
              fullWidth
              variant="contained" 
              onClick={submit}
              sx={{ mt: 3, mb: 2, bgcolor:'#faa634', ':hover':{bgcolor:'#316C31'} }}
            >
              Create New Password
            </Button>       
          </Box>
        </Box>
        <Copyright sx={{ mt:2}} />
          </Container>
        </ul>
    </div >
  );
}
