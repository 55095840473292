import React, { useEffect, useState } from 'react'
import { Container, Grid, Button, Box, Typography, Card, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Autocomplete, } from '@mui/material';
import TextField from '@mui/material/TextField';
import axios, { all } from 'axios';
import { add_expense_category, create_shipment, get_expense_category, update_expense_category, update_order_status } from '../../Services/APIService';
import { toast } from 'react-toastify';

export default function UpdateOrderDialog({ 
    setMessage,
    setOpen,
    setStatus,
    setColor,
    openUpdateOrderDialog,
    setopenUpdateOrderDialog,
    OrderId,
    setOrderId,
    UserId,
    setUserId,
    CustomerName,
    setCustomerName,
    Payment,
    setPayment, 
    AllData ,
    FirstName ,
    setFirstName ,
    LastName , 
    setLastName
    }) {

    const [OrderStatus, setOrderStatus] = useState(null);

    const options = [
        {
            'label': 'Confirm Order',
            'value': 'Order Confirmed',
        },
        {
            'label': 'Cancel Order',
            'value': 'Order Cancelled',
        },
    ]

    const handleClose = () => {
        setopenUpdateOrderDialog(false)
        setPayment('')
        setCustomerName('')
        setOrderId('')
        setUserId('')
        setOrderStatus(null)
        setFirstName('')
        setLastName('');
    }

    const transformedProducts = {};

    AllData?.products?.forEach((product, index) => {
        // Convert weight from grams to pounds (1 gram = 0.00220462 pounds)
        const weightInLb = product.productWeight * 0.00220462;

        // Check if the weight is less than 1 lb and set it to 1 lb if so
        const adjustedWeightInLb = weightInLb < 1 ? "1" : weightInLb;

        transformedProducts[index] = {
            productWeight: adjustedWeightInLb, // Store weight in pounds, at least 1 lb
            productLength: product.productLength,
            productWidth: product.productWidth,
            productHeight: product.productHeight,
        };
    });

    const UpdateStatus = () => {
        if(OrderStatus !== null && OrderStatus !== ''){
        const sendData = new FormData()
        sendData.append('userId', UserId)
        sendData.append('orderId', OrderId)
        sendData.append('orderStatus', OrderStatus.value)
        axios({
            method: "POST",
            url: update_order_status,
            data: sendData
        }).then(res => {
            if (res.data.error) {
            errorMsg(res.data.message);
            } 
            else if (!res.data.error) {
            if (OrderStatus.value === 'Order Confirmed'){
            successMsg("Order Confirmed !")
            createShipment();
            }
            else{
            successMsg(res.data.message) 
            }
            }
            else
            {
            errorMsg('Something Went Wrong. Please Try Again !'); 
            }
        }).catch(err => {
            console.log(err)
        });
        }
    }



    const createShipment = () =>{
        console.log(AllData);
        const currentDate = new Date(); // Get the current date
        currentDate.setDate(currentDate.getDate() + 2); // Add two days to the current date

        // Format the date as needed (e.g., yyyy-mm-dd)
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Month is 0-based
        const day = String(currentDate.getDate()).padStart(2, '0');

        const formattedDate = `${year}-${month}-${day}`;
        const areacode = AllData.phoneNum.substring(0, 3);
        const phone = AllData.phoneNum.substring(3);
        const sendData = new FormData()
        sendData.append('orderId', OrderId)
        sendData.append('receiverName', CustomerName);
        sendData.append('receiverCompany', '')
        sendData.append('receiverDepartment', '')
        sendData.append('receiverStreetNumber', '')
        sendData.append('receiverStreetName', AllData.address)
        sendData.append('receiverStreetType', 'Street')
        sendData.append('receiverCity', AllData.city)
        sendData.append('receiverProvince', AllData.provinceCode)
        sendData.append('receiverCountry', AllData.country)
        sendData.append('receiverPostalCode', AllData.pincode)
        sendData.append('receiverCountryCode', AllData.countryCode)
        sendData.append('receiverAreaCode', areacode)
        sendData.append('receiverPhone', phone)
        sendData.append('shipmentDate', formattedDate);
        sendData.append('totalWeight', AllData.totalWeight)
        sendData.append('totalPieces', AllData.totalPieces)
        sendData.append('serviceID', AllData.shippingService)
        sendData.append('productData', JSON.stringify(transformedProducts))
        axios({
            method: "POST",
            url: create_shipment,
            data: sendData
        }).then(res => {
            if (res.data.error) {
                errorMsg(res.data.message);
            }
            else if (!res.data.error) {
                if (OrderStatus.value === 'Order Confirmed') {
                    successMsg("Shipment Created !")
                    handleClose();
                }
                else {
                    successMsg(res.data.message)
                    handleClose();
                }
            }
            else {
                errorMsg('Something Went Wrong. Please Try Again !');
            }
        }).catch(err => {
            console.log(err)
        }); 
    }



    const successMsg = (message) => {
        toast.success(message, {
            position: toast.POSITION.BOTTOM_LEFT,
            autoClose: 2000,
            closeOnClick: true,
        });
    };

    const errorMsg = (message) => {
        toast.error(message, {
            position: toast.POSITION.BOTTOM_LEFT,
            autoClose: 2000,
            closeOnClick: true,
        });
    };

    return (
        <Dialog open={openUpdateOrderDialog} fullWidth>
            <DialogTitle fontWeight={600}>
                Update Order Status
            </DialogTitle>
            <DialogContent>
                <Box p={2}>
                <Stack spacing={2}>
                    <Typography variant='body2' color='#616e80' fontWeight={600}>Order Id: {OrderId}</Typography>
                    <Typography variant='body2' color='#616e80' fontWeight={600}>Customer Id: {UserId}</Typography>
                    <Typography variant='body2' color='#616e80' fontWeight={600}>Customer Name: {CustomerName}</Typography>
                    <Typography variant='body2' color='#616e80' fontWeight={600}>Payment Method: {Payment}</Typography>
                </Stack>
                <Box mt={3}>
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        size='medium'
                        value={OrderStatus}
                        onChange={(index, value) => setOrderStatus(value)}
                        options={options}
                        renderInput={(params) => <TextField
                        {...params}
                        sx={{width: '25ch' }}
                        required
                        size='small'
                        label="Update Status" />}
                    />
                </Box>
                </Box>
            </DialogContent>
            <DialogActions sx={{p:2}}>
                    <Button onClick={handleClose} variant='outlined' color='error'>CLOSE</Button>
                    <Button onClick={UpdateStatus} variant='outlined' color='success'>UPDATE</Button>
            </DialogActions>
        </Dialog>
    )
}
