import { get_dashboard_data, get_order_vs_pending } from '../../Services/APIService';
import axios from 'axios';
import { Box, Typography, Card, Grid, Stack, TextField } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom';
import AccessAlarmsOutlinedIcon from '@mui/icons-material/AccessAlarmsOutlined';
import ScheduleSendOutlinedIcon from '@mui/icons-material/ScheduleSendOutlined';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';

export default function OrderVsPending() {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [status, setStatus] = useState();
    const [color, setColor] = useState();
    const [data , setData] = useState([]);
    const [fromDate , setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
  
    const navigate = useNavigate();

    const textFieldRef = useRef();

    const getFilteredMonthData = () =>{
      const sendData = new FormData()
      sendData.append('fromDate', fromDate);
      sendData.append('toDate', textFieldRef?.current?.value !== undefined ? textFieldRef?.current?.value : "");
       axios({
        method: 'POST',
         url: get_order_vs_pending,
         data: sendData,
    }).then(res => {
        if (res.data.error) {
            setMessage(res.data.message)
            setOpen(true)
            setStatus(false)
            setColor(false)
        } else {
            setOpen(true)
            setMessage(res.data.message)
            setStatus(true)
            setColor(true)
            setData(res.data.data)
        }
    }).catch(err => {
        console.log(err)
    });
    }

    useEffect(()=>{
        getFilteredMonthData();
    },[toDate]);
  

  

  return (
    <Box mb={3}>
      

    <Box textAlign='left' py={1} mb={1} display='flex' flexDirection='column' justifyContent='space-between' gap={3}>
    <Box sx={{cursor:'pointer'}}>
    <Typography variant='h6' sx={{color:'#faa634' , cursor:'pointer'}}>Order Vs Pending</Typography>
  </Box>
  <Box>

    <Box display='flex' flexDirection='row' gap={2} mb={1}>
    <TextField
    type="date"
    id="start" 
    size='small'
    name="trip-start"
    onChange={(e) => {
    setFromDate(e.target.value);
    }}
    label='From date'
    required
    InputLabelProps={{
      shrink: true,
    }}
      />

      <TextField
      type="date"
      id="end" 
      size='small'
      name="trip-end"
      inputRef={textFieldRef}
      onChange={(e) => {
      setToDate(e.target.value);
      }}
      label='To date'
      required
      InputLabelProps={{
        shrink: true,
      }}
        />
   </Box>

  </Box>
    </Box>  
    <Grid container spacing={{xs:1 , sm:2, md:3, lg:4}} justifyContent={{xs:'start' , sm:'center', md:'center', lg:'center'}} flexWrap='wrap'>

    <Grid item xs={12} sm={4} md={4} lg={4}>
       <Box component={Card} boxShadow={4} >
      <Box display='flex' flexDirection='column' justifyContent='center' alignContent='center' p={2}>
      <Box mt={1}>
      <AccessAlarmsOutlinedIcon fontSize='large' sx={{color:'#faa634'}} />
      </Box>
      <Box  mt={2}>
      <Typography variant='body2' color='#616e80'>Pending</Typography>
        </Box>
      <Box>
      <Typography variant='subtitle1' sx={{color:'#faa634'}} >{data?.totalPending}</Typography>
      </Box>
      </Box>
      </Box>
      </Grid>

      <Grid item xs={12} sm={4} md={4} lg={4}>
      <Box component={Card} boxShadow={4} onClick={()=>navigate('/dashbrdlayout/orders')}>
      <Box display='flex' flexDirection='column' justifyContent='center' alignContent='center' p={2}>
      <Box mt={1}>
      <ScheduleSendOutlinedIcon fontSize='large' sx={{color:'#faa634'}} />
      </Box>
      <Box  mt={2}>
      <Typography variant='body2' color='#616e80'>Shipped</Typography>
        </Box>
      <Box>
      <Typography variant='subtitle1' sx={{color:'#faa634'}} >{data?.totalShipped}</Typography>
      </Box>
      </Box>
      </Box>
      </Grid>

      <Grid item xs={12} sm={4} md={4} lg={4}>
      <Box component={Card} boxShadow={4} onClick={()=>navigate('/dashbrdlayout/allproductspage')}>
      <Box display='flex' flexDirection='column' justifyContent='center' alignContent='center' p={2}>
      <Box mt={1}>
      <TaskAltOutlinedIcon fontSize='large' sx={{color:'#faa634'}} />
      </Box>
      <Box  mt={2}>
      <Typography variant='body2' color='#616e80'>Delivered</Typography>
        </Box>
      <Box>
      <Typography variant='subtitle1' sx={{color:'#faa634'}} >{data?.totalDelivered}</Typography>
      </Box>
      </Box>
      </Box>
      </Grid>

      </Grid>
      </Box>
  )
}
