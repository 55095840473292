import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Container, Grid, Button, Box, Typography, Card, Dialog, DialogActions, DialogContent, DialogTitle, Stack, } from '@mui/material';
import Heading from '../../Components/Heading/Heading';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Divider from '@mui/material/Divider';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { add_purchase, get_all_product_name, get_color_by_product_id, get_product_and_cost, get_product_by_category, get_vendor, getallcategory } from '../../Services/APIService';
import axios from 'axios';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SnackBar from '../../Components/AdminDashBoardComps/Snackbar';

export default function CreateNewPurchase() {


    const [tableRows, setTableRows] = useState([{}]); // Initial row
    const [supplierName, setSupplierName] = useState(null);
    const [purchaseDate, setPurchaseDate] = useState('');
    const [totalWithoutVat, setTotalWithoutVat] = useState('');
    const [discount, setDiscount] = useState(0);
    const [discountType, setDiscountType] = useState(null);
    const [discountAmount, setDiscountAmount] = useState('');
    const [invoiceVat, setInvoiceVat] = useState(0);
    const [netTotal, setNetTotal] = useState('');
    const [paymentMethod, setPaymentMethod] = useState('');
    const [paidAmount, setPaidAmount] = useState('');
    const [dueAmount, setDueAmount] = useState('');
    const [productList, setProductList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [status, setStatus] = useState();
    const [color, setColor] = useState();
    const [SavedColorQty , setSavedColorQty] = useState([]);
    const [SavedColor, setSavedColor] = useState(null);
    const [SavedQty, setSavedQty] = useState('');
    const [currentRowIndex , setCurrentRowIndex]= useState('');
    const [overallSubtotal, setOverallSubtotal] = useState(0);
    const [VendorList, setVendorList] = useState([]);
    const [openDialogs, setOpenDialogs] = useState(tableRows.map(() => false));
    const [purchaseOrderDate, setPurchaseOrderDate] = useState('');
    const [category, setCategory] = useState('');
    const [customsDuty, setCustomsDuty] = useState('');
    const [importTax, setImportTax] = useState('');
    const [freightCharges, setFreightCharges] = useState('');
    const [customerBrokerage, setCustomerBrokerage] = useState('');
    const [paymentTerms, setPaymentTerms] = useState('');
    const [currencyType, setCurrencyType] = useState('');
    const [remainingQuantity ,setremainingQuantity] = useState('');

    const getallVendorAPI = () => {
        axios({
            method: "POST",
            url: get_vendor,
        }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(false)
                setColor(false)
            } else {
                setMessage(res.data.message)
                setVendorList(res.data.data);
                setStatus(true)
                setColor(true)
            }
        }).catch(err => {
            console.log(err)
        });
    }

    useEffect(() => {
        getallVendorAPI();
    }, [])


    const VendorOptions = VendorList.map((i)=>{
        const obj ={
            'label': i.vendorName, 
            'id':i.vendorId
        }
        return obj ;
    })

    const handleOpenDialog = (index , productId) => {
        if (productId !== '' &&  productId !== null && productId !== undefined) {
        setOpenDialogs((prevOpenDialogs) => {
            const updatedOpenDialogs = [...prevOpenDialogs];
            updatedOpenDialogs[index] = true;
            return updatedOpenDialogs;
        });
        setCurrentRowIndex(index);
    }
    };


    const handleCloseDialog = (index) => {
        setOpenDialogs((prevOpenDialogs) => {
            const updatedOpenDialogs = [...prevOpenDialogs];
            updatedOpenDialogs[index] = false;
            return updatedOpenDialogs;
        });
    };


    const handleAddRow = () => {
        const newRow = {
            productId: '',
            productName:'',
            productCost: '',
            overAllQty: '',
            savedColorQty: [] , 
            subtotal:'',
        };
        setTableRows((prevRows) => [...prevRows, newRow]);
    };

    const handleDeleteRow = (index) => {
        setTableRows((prevRows) => {
        const updatedRows = [...prevRows];
        updatedRows.splice(index, 1);
        return updatedRows;
        });
    };


    useEffect(() => {
        axios({
            method: "GET",
            url: getallcategory,
        }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(false)
                setColor(false)
            } else {
                setMessage(res.data.message)
                setCategoryList(res.data.data);
                setStatus(true)
                setColor(true)
            }
        }).catch(err => {
            console.log(err)
        });
    }, [])

    const CategoryOptions = categoryList.map((i) => {
        const obj = {
            'label': i.categoriesName,
            'id': i.categoryId,
        }
        return obj;
    });

    const handleCategoryChange = (index, value) => {
        setTableRows((prevRows) => {
            const updatedRows = [...prevRows];
            if (value) {
                updatedRows[index].categoryId = value.id;
                updatedRows[index].categoryName = value.label;
            } else {
                updatedRows[index].categoryId = null;
                updatedRows[index].categoryName = '';
            }
            return updatedRows;
        });

        if (value) {
            getProductList(value, index);
        }
    };


    const getProductList = (category, index) => {
        if (category.id !== '' || category.id !== null) {
            const sendData = new FormData();
            sendData.append('categoryId', category.id);
            axios({
                method: "POST",
                url: get_product_by_category,
                data: sendData
            }).then(res => {
                if (res.data.error) {
                    setMessage(res.data.message)
                    setOpen(true)
                    setStatus(false)
                    setColor(false)
                } else {
                    setMessage(res.data.message)
                    setProductList(res.data.data);
                    setStatus(true)
                    setColor(true)
                }
            }).catch(err => {
                console.log(err)
            });
        }
    }

    const Productoptions = productList.map((i) => {
        const obj = {
            'label': i.productName,
            'id': i.productId
        }
        return obj;
    });


    const getProductCost = (product , index) => {
        if(product.id !== '' || product.id !== null){
        const sendData = new FormData();
        sendData.append('productId' , product.id);
        axios({
            method: "POST",
            url: get_product_and_cost,
            data:sendData
        }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(false)
                setColor(false)
            } else {
                setMessage(res.data.message)
                setTableRows((prevRows) => {
                    const updatedRows = [...prevRows];
                    updatedRows[index].productCost = res.data.data.productPrice;
                    return updatedRows;
                });
                setStatus(true)
                setColor(true)
            }
        }).catch(err => {
            console.log(err)
        });
    }
    }

    const getProductColor = (product, index) => {
        if (product.id !== '' || product.id !== null) {
            const sendData = new FormData();
            sendData.append('productId', product.id);
            axios({
                method: "POST",
                url: get_color_by_product_id,
                data: sendData
            }).then(res => {
                if (res.data.error) {
                    setMessage(res.data.message)
                    setOpen(true)
                    setStatus(false)
                    setColor(false)
                } else {
                    setMessage(res.data.message)
                    setTableRows((prevRows) => {
                        const updatedRows = [...prevRows];
                        updatedRows[index].ColorQuantity = res.data.data;
                        return updatedRows;
                    });
                    setStatus(true)
                    setColor(true)
                }
            }).catch(err => {
                console.log(err)
            });
        }
    }


    const handleProductChange = (index, value) => {
        setTableRows((prevRows) => {
            const updatedRows = [...prevRows];
            if (value) {
                updatedRows[index].productId = value.id;
                updatedRows[index].productName = value.label;
            } else {
                updatedRows[index].productId = null;
                updatedRows[index].productName = '';
                updatedRows[index].productCost = '';
            }
            return updatedRows;
        });

        if (value) {
            getProductCost(value, index);
            getProductColor(value, index);
        }
    };

    const calculateSubtotal = (cost, quantity) => {
        return parseFloat(cost * quantity).toFixed(2);
    };

    const handleProductCostChange = (index, value) => {
        setTableRows((prevRows) => {
            const updatedRows = [...prevRows];
            updatedRows[index].productCost = value;
            updatedRows[index].subtotal = calculateSubtotal(value, updatedRows[index].overAllQty);
            return updatedRows;
        });
    };

    const handleOverAllQtyChange = (index, value) => {
        setremainingQuantity(parseInt(value));
        setTableRows((prevRows) => {
            const updatedRows = [...prevRows];
            updatedRows[index].overAllQty = value;
            updatedRows[index].subtotal = calculateSubtotal(updatedRows[index].productCost, value);
            return updatedRows;
        });
    };


    const handleSubtotalChange = (index, value) => {
        setTableRows((prevRows) => {
            const updatedRows = [...prevRows];
            updatedRows[index].subtotal = value;
            return updatedRows;
        });
    };


    // Save Color Qty
    const handleSaveColorQty = () => {
        if(SavedColor !== null & SavedQty !== ''){
        const newObject = { color: SavedColor.productColor, QTY: SavedQty };
        setTableRows((prevRows) => {
            const updatedRows = [...prevRows];
            updatedRows[currentRowIndex].savedColorQty = [
                ...(updatedRows[currentRowIndex].savedColorQty || []),
                newObject
            ];
            return updatedRows;
        });
        setremainingQuantity(remainingQuantity - SavedQty);
        setSavedColor(null);
        setSavedQty('');
    };
}



    const handleRemoveColorQty = (rowIndex, itemIndex , qty) => {
        setTableRows((prevRows) => {
            const updatedRows = [...prevRows];
            updatedRows[rowIndex].savedColorQty.splice(itemIndex, 1);
            return updatedRows;
        });
        setremainingQuantity(remainingQuantity + qty)
    };


    useEffect(() => {
        // Calculate the overallSubtotal by summing up subtotals of all rows
        const subtotal =  tableRows.reduce((total, row) => total + (parseFloat(row.subtotal) || 0), 0);
        const parsedSubtotal = parseFloat(subtotal);
        if (!isNaN(parsedSubtotal)) {
            setOverallSubtotal(parsedSubtotal.toFixed(2));
            setTotalWithoutVat(parsedSubtotal.toFixed(2));
            setNetTotal(parsedSubtotal.toFixed(2));
        }
    }, [tableRows]);

    console.log(tableRows);


    const handleDiscountTypeChange = (event, value) => {
        setDiscountType(value);
        if (value === 'Fixed') {
            setDiscountAmount(discount);
            const netTotalAmount = parseFloat(overallSubtotal - discount);
            setNetTotal(netTotalAmount.toFixed(2));
        } else if (value === 'Percentage') {
            const discountPercent = parseFloat(discount);
            const discountAmountValue = (discountPercent / 100) * overallSubtotal;
            const netTotalAmount = overallSubtotal - discountAmountValue;
            setDiscountAmount(discountAmountValue.toFixed(2));
            setNetTotal(netTotalAmount);
        }
        else{
            setNetTotal(overallSubtotal);
            setDiscountAmount('');
        }
    };


    const handlePaidAmount = (e) =>{
        setPaidAmount(e.target.value);
        if (e.target.value !== '' && e.target.value !== null){
        const duePayment = netTotal - e.target.value ;
        setDueAmount(duePayment.toFixed(2));
        }
        else
        {
            setDueAmount(netTotal);
        }
    }


    const product = {};
    const colorQty = {};

    tableRows && tableRows.forEach((item, index) => {
        const productItem = {
            productId: item.productId,
            categoryId:item.categoryId,
            productName: item.productName,
            productUnitCost: item.productCost,
            purchaseTotalQty: item.overAllQty,
            subTotal: item.subtotal,
        };
        product[index] = productItem;
        if (item.savedColorQty && item.savedColorQty.length > 0) {
            item.savedColorQty.forEach((colorQtyItem, colorQtyIndex) => {
                const colorQtyItemFormatted = {
                    productId: item.productId,
                    color: colorQtyItem.color,
                    qty: colorQtyItem.QTY,
                };
                colorQty[colorQtyIndex] = colorQtyItemFormatted;
            });
        }
    });

    const productArray = Object.values(product);
    const colorQtyArray = Object.values(colorQty);

    const onSubmit = () => {
        if (supplierName !== '' && purchaseDate !== '' && paidAmount !== '' && Object.keys(colorQty).length !== 0){
        const serverData = new FormData()       
        serverData.append('supplierName', supplierName?.label);
        serverData.append('vendorId', supplierName?.id);
        serverData.append('purchaseDate', purchaseDate)
        serverData.append('totalWithoutVat', totalWithoutVat);
        serverData.append('discount', discount);
        serverData.append('discountType', discountType);
        serverData.append('discountAmount', discountAmount);
        serverData.append('invoiceVat', invoiceVat);
        serverData.append('netTotal', netTotal);
        serverData.append('paymentMethod', paymentMethod);
        serverData.append('paidAmount', paidAmount);
        serverData.append('dueAmount', dueAmount);
        serverData.append('product', JSON.stringify(product));
        serverData.append('colorQty', JSON.stringify(colorQty));
        serverData.append('purchaseOrderDate', purchaseDate);
        serverData.append('category', category);
        serverData.append('importTax', importTax);
        serverData.append('customsDuty', customsDuty);
        serverData.append('freightCharges', freightCharges);
        serverData.append('customerBrokerage', customerBrokerage);
        serverData.append('paymentTerms', paymentTerms);
        serverData.append('currencyType', currencyType);
        axios({
            method: "POST",
            url: add_purchase,
            data: serverData,
        }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(false)
                setColor(false)
            } else {
                setOpen(true)
                setMessage(res.data.message)
                setStatus(true)
                setColor(true)
                setSupplierName('')
                setPurchaseDate('')
                setTotalWithoutVat('')
                setDiscount(0)
                setDiscountType(null)
                setDiscountAmount('')
                setInvoiceVat(0)
                setNetTotal('')
                setPaymentMethod('')
                setPaidAmount('')
                setDueAmount('')
                setProductList([])
                setSavedColorQty([])
                setSavedColor('')
                setSavedQty('')
                setCurrentRowIndex('')
                setOverallSubtotal(0)
                setTableRows([{}]);
                setPurchaseOrderDate('')
                setCategory('')
                setCustomsDuty('')
                setImportTax('')
                setFreightCharges('')
                setCustomerBrokerage('')
                setPaymentTerms('')
                setCurrencyType('')
            }
        }).catch(err => {
            console.log(err)
        });
    }
        else {
            setOpen(true)
            setMessage('Fill all the Fields')
            setStatus(false)
            setColor(false)
        }
    }




    return (
        <Box p={2}>
            <Box p={3}>
                <Heading title={'New Purchase'} />
            </Box>

            <SnackBar open={open} setOpen={setOpen} status={status} color={color} message={message} />

            <Box component={Card} sx={{ boxShadow: 2 }} pb={2}>
            <Container>
            <Box mt={2} py={2}  >
            <Grid container>
            <Grid item xs={12}>
            <Box display='flex' flexDirection='row' justifyContent='center' gap={5} py={2} px={4}>
            <Typography variant='subtitle2' sx={{py:1}}>Vendor Name : </Typography>
            <Autocomplete
            id="combo-box-demo"
            size="small"
            sx={{width:400}}
            value={supplierName}
            onChange={(event , value)=>setSupplierName(value)}
            options={VendorOptions}
            renderInput={(params) => <TextField {...params} label="Select Vendor" />}
            />
            </Box>
            </Grid>
            <TableContainer component={Card} boxShadow={0}>
            <Table bgcolor='#eff8f2'>
            <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: '#616e80' }}>
            <TableRow>
            <TableCell align="center" sx={{ borderRight: '1px solid silver' }} colSpan={4}>
             Category
            </TableCell>
             <TableCell align="center" sx={{ borderRight: '1px solid silver' }} colSpan={4}>
            Product
            </TableCell>
            <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>Unit Cost($)</TableCell>
            <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>Quantity</TableCell>
            <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>Color Qty</TableCell>
            <TableCell align="center" colSpan={4} >Sub Total($)</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {tableRows.map((row, index) => (
            <TableRow key={index}>

            <TableCell colSpan={4} align="center" sx={{ borderRight: '1px solid silver' }}>
            <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={CategoryOptions}
            value={row.categoryName || ''}
            size='small'
            sx={{ width: 180 }}
            onChange={(event, value) => handleCategoryChange(index, value)}
            renderInput={(params) => <TextField {...params} label="Category" />}
            />
            </TableCell>

            <TableCell colSpan={4} align="center" sx={{ borderRight: '1px solid silver' }}>
            <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={Productoptions}
            value={row.productName || ''}
            size='small'
            sx={{width:180}}
            onChange={(event, value) => handleProductChange(index, value)}
            renderInput={(params) => <TextField {...params} label="Select a Product" />}
            />
            </TableCell>

            <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>
            <TextField 
            id="outlined-basic" 
            onChange={(event) => handleProductCostChange(index, event.target.value)} 
            value={row.productCost || ''}  
            size='small' 
            sx={{width:100}}  
            variant="outlined" 
            />
            </TableCell>

            <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>
            <TextField 
            id="outlined-basic"
            type='number'
            value={row.overAllQty || ''}
            onChange={(event) => handleOverAllQtyChange(index, event.target.value)}
            size='small'
            sx={{ width: 100 }}
            variant="outlined" 
            />
            </TableCell>

            <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>
            <AddCircleIcon onClick={()=>handleOpenDialog(index ,row.productId )} color='primary' sx={{ verticalAlign: 'middle' }} fontSize='large' />
            <Stack py={1} spacing={1} width={120}>
           {row.savedColorQty && row.savedColorQty.length > 0 && (
            row.savedColorQty.map((i , itemIndex) => (
            <Box key={itemIndex}>
            <Typography variant='caption'>{i.color} - {i.QTY} <HighlightOffIcon onClick={() => handleRemoveColorQty(index, itemIndex , i.QTY)} fontSize='small' color='error' sx={{verticalAlign:'middle'}} /> </Typography>
            </Box>
            ))
            )}
            </Stack>
            
            <Dialog open={openDialogs[index]}>
            <DialogTitle>
             <Stack spacing={2}>
             <Typography>Color Qty</Typography>
             <Typography variant='body2'>** Should Not Exceed Overall Qty : {row.overAllQty} **</Typography>
             </Stack>
            </DialogTitle>
            <DialogContent>
            <Box display='flex' flexDirection='row' gap={2} p={3}>


            <Autocomplete
            id="combo-box-demo"
            size="small"
            sx={{width:400}}
            value={SavedColor}
            onChange={(event, value) => setSavedColor(value)}
            options={row?.ColorQuantity}
            getOptionLabel={(option) => option.productColor}
            renderInput={(params) => <TextField {...params} label="Select Color" />}
            />

            <TextField 
            id="outlined-basic" 
            size='small' 
            label='Qty' 
            type='number' 
            value={SavedQty} 
            onChange={(e) => {
            let newQty = parseInt(e.target.value, 10);
            if (newQty > remainingQuantity) {
              newQty = remainingQuantity; 
            } else if (newQty < 0) {
              newQty = 1; 
            }
            setSavedQty(newQty);
            }}  
            sx={{ width: 150 }} 
            variant="outlined" 
            />

            <AddCircleIcon onClick={handleSaveColorQty} sx={{verticalAlign:'middle'}} fontSize='large'/>
            </Box>

            <Stack p={1} spacing={2}>
            {row.savedColorQty && row.savedColorQty.length > 0 && (
                row.savedColorQty.map((i) => (
                    <Box key={i.color}>
                        <Typography variant='caption'>{i.color} - {i.QTY}</Typography>
                    </Box>
                ))
            )}
            </Stack>
            </DialogContent>
            <DialogActions>
            <Button onClick={()=>handleCloseDialog(index)} color='error'>Close</Button>
            </DialogActions>
            </Dialog>
            </TableCell>

            <TableCell align="center" colSpan={4} >
            <Box display='flex' gap={4} justifyContent='end'>

            <TextField
            value={row.subtotal || ''}
            onChange={(event) => handleSubtotalChange(index, event.target.value)}
            size='small'
            variant="outlined"
            />
            {
            index > 0 ? 
            <HighlightOffIcon onClick={()=>handleDeleteRow(index)} sx={{ verticalAlign: 'middle' }} color='error' fontSize='large' />
            :
            <AddBoxIcon onClick={handleAddRow} sx={{ verticalAlign: 'middle' }} color='primary' fontSize='large' />
            }  
            </Box>
            </TableCell>

            </TableRow>
            ))}

            <TableRow>
            <TableCell />
            <TableCell align="right" colSpan={10} sx={{ borderRight: '1px solid silver' }}>Purchase Date</TableCell>
            <TableCell align="center" > 
            <TextField 
            id="outlined-basic" 
            size='small' 
            variant="outlined" 
            fullWidth 
            type='date'
            onChange={(e)=>setPurchaseDate(e.target.value)}
            value={purchaseDate}
            />
            </TableCell>
            </TableRow>

            <TableRow>
            <TableCell />
                                            <TableCell align="right" colSpan={10} sx={{ borderRight: '1px solid silver' }}>Total<Typography component='span' variant='caption'>(Excluding Vat)</Typography></TableCell>
            <TableCell align="center"> 
            <TextField
             id="outlined-basic" 
             size='small' 
             variant="outlined" 
             fullWidth 
             disabled
            value={overallSubtotal}
             />
             </TableCell>
            </TableRow>

            <TableRow>
            <TableCell />
                                            <TableCell align="right" colSpan={10} sx={{ borderRight: '1px solid silver' }}>Discount</TableCell>
            <TableCell align="center"> 
            <TextField 
            id="outlined-basic"
             size='small' 
             variant="outlined" 
             fullWidth 
            onChange={(e)=>setDiscount(e.target.value)}
            value={discount}
             />
             </TableCell>
            </TableRow>

            <TableRow>
            <TableCell />
                                            <TableCell align="right" colSpan={10} sx={{ borderRight: '1px solid silver' }}>Discount Type</TableCell>
            <TableCell align="center">  
            <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={['Fixed', 'Percentage']}
            size='small'
            disabled={discount === 0 ? true : false}
            onChange={handleDiscountTypeChange}
            value={discountType}
             renderInput={(params) => <TextField {...params}  InputLabelProps={{ shrink: true }} />}
            /></TableCell>
            </TableRow>

            <TableRow>
            <TableCell />
                                            <TableCell align="right" colSpan={10} sx={{ borderRight: '1px solid silver' }}>Discount Amount</TableCell>
            <TableCell align="center"> 
            <TextField 
            id="outlined-basic"
            size='small' 
            variant="outlined" 
            fullWidth 
            disabled
            value={discountAmount}
             />
             </TableCell>
            </TableRow>

            <TableRow>
            <TableCell />
                                            <TableCell align="right" colSpan={10} sx={{ borderRight: '1px solid silver' }}>Net Total</TableCell>
            <TableCell align="center">
            <TextField 
            id="outlined-basic" 
            size='small' 
            variant="outlined"
            fullWidth
            disabled
            value={netTotal}
            />
            </TableCell>
            </TableRow>

                <TableRow>
                <TableCell />
                                            <TableCell align="right" colSpan={10} sx={{ borderRight: '1px solid silver' }}>Customs Duty</TableCell>
                <TableCell align="center">
                <TextField
                id="outlined-basic"
                size='small'
                variant="outlined"
                fullWidth
                onChange={(e)=>setCustomsDuty(e.target.value)}
                value={customsDuty}
                />
                </TableCell>
                </TableRow>

    <TableRow>
    <TableCell />
                                            <TableCell align="right" colSpan={10} sx={{ borderRight: '1px solid silver' }}>Import Tax</TableCell>
    <TableCell align="center">
    <TextField
    id="outlined-basic"
    size='small'
    variant="outlined"
    fullWidth
    onChange={(e) => setImportTax(e.target.value)}
    value={importTax}
    />
    </TableCell>
    </TableRow>



        <TableRow>
        <TableCell />
                                            <TableCell align="right" colSpan={10} sx={{ borderRight: '1px solid silver' }}>Freight Charges</TableCell>
        <TableCell align="center">
        <TextField
        id="outlined-basic"
        size='small'
        variant="outlined"
        fullWidth
        onChange={(e) => setFreightCharges(e.target.value)}
        value={freightCharges}
        />
        </TableCell>
        </TableRow>

        <TableRow>
        <TableCell />
                                            <TableCell align="right" colSpan={10} sx={{ borderRight: '1px solid silver' }}>Customer Brokerage</TableCell>
        <TableCell align="center">
        <TextField
        id="outlined-basic"
        size='small'
        variant="outlined"
        fullWidth
        onChange={(e) => setCustomerBrokerage(e.target.value)}
        value={customerBrokerage}
        />
        </TableCell>
        </TableRow>

        <TableRow>
        <TableCell />
                                            <TableCell align="right" colSpan={10} sx={{ borderRight: '1px solid silver' }}>Payment Terms</TableCell>
        <TableCell align="center">
        <TextField
        id="outlined-basic"
        size='small'
        variant="outlined"
        fullWidth
        onChange={(e) => setPaymentTerms(e.target.value)}
        value={paymentTerms}
        />
        </TableCell>
        </TableRow>

            <TableRow>
            <TableCell />
               <TableCell align="right" colSpan={10} sx={{ borderRight: '1px solid silver' }}>Currency Type</TableCell>
            <TableCell align="center">
            <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={['CAD', 'USD']}
            size='small'
            onChange={(event, value) => setCurrencyType(value)}
            value={currencyType}
            renderInput={(params) => <TextField {...params} InputLabelProps={{ shrink: true }} />}
            />
            </TableCell>
            </TableRow>

            <TableRow>
            <TableCell />
             <TableCell align="right" colSpan={10} sx={{ borderRight: '1px solid silver' }}>Payment Method</TableCell>
            <TableCell align="center">
            <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={['Cash', 'Card' , 'Cheque', 'Others']}
                size='small'
                onChange={(event, value) => setPaymentMethod(value)}
                value={paymentMethod}
                renderInput={(params) => <TextField {...params} InputLabelProps={{shrink:true}} />}
            />
            </TableCell>
            </TableRow>

            <TableRow>
            <TableCell />
            <TableCell align="right" colSpan={10} sx={{ borderRight: '1px solid silver' }}>Paid</TableCell>
            <TableCell align="center"> 
            <TextField 
            id="outlined-basic" 
            size='small' 
            variant="outlined" 
            fullWidth 
            onChange={handlePaidAmount}
            value={paidAmount}
            />
            </TableCell>
            </TableRow>

            <TableRow>
            <TableCell />
              <TableCell align="right" colSpan={10} sx={{ borderRight: '1px solid silver' }}>Due</TableCell>
            <TableCell align="center"> 
            <TextField 
            id="outlined-basic" 
            size='small' 
            variant="outlined" 
            fullWidth 
            value={dueAmount}
            />
            </TableCell>
            </TableRow>

            <TableRow>
            <TableCell />
              <TableCell align="right" colSpan={10}></TableCell>
            <TableCell align="center"> 
            <Button 
            color='warning' 
            variant='contained' 
            fullWidth
            onClick={onSubmit}
            >Submit
            </Button>
            </TableCell>
            </TableRow>

            </TableBody>
            </Table>
            </TableContainer>
            </Grid>
            </Box>
            </Container>
            </Box>
        </Box>
    );
}
