import { Box, Typography, Card, Grid, TextField, Button } from '@mui/material'
import React from 'react'
import Heading from '../../Components/Heading/Heading';
import { useForm } from "react-hook-form";
import { useState } from 'react';

export default function Notifications() {

  const [read, setRead] = useState(false);

  const handleClick = () =>{
   setRead(true);
  }
   
  return (
    <Box p={2}>
    <Box p={2}>

     <Box py={3}>
       <Heading  title={'Notifications'}/>
     </Box>

   {
     [...Array(5)].map((i)=>{
      return(
     <Box component={Card} mb={2}  bgcolor={read ? '#ffff' : '#8dd8e5'} onClick={handleClick}>
       <Grid container>
         <Grid item xs={12} sm={12} md={12} lg={12} >
          <Box display='flex' justifyContent='space-between'>
          <Box textAlign='left' p={1}>
         <Typography variant='subtitle2' fontWeight={500}>New Product added in Database Successfully</Typography>
         </Box>
          </Box>
         </Grid>
       </Grid>
     </Box>
  )})}


     </Box>
     </Box>
  )
}
