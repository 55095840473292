import { Box, Typography, Container, Grid, Card, CardMedia, CardContent, Button, Link } from '@mui/material'
import React ,{useState} from 'react'
import { Outlet } from 'react-router-dom';
import DashbrdHeader from '../../Components/AdminDashBoardComps/DashbrdHeader';
import SideBars from '../../Components/AdminDashBoardComps/SideBars';




export default function DashBoardLayout() {

  const [mobileOpen, setMobileOpen] = useState(false);

  return (
    <Box component='div'>
      <Box sx={{ display: 'flex', minHeight: '100%', overFlow: 'hidden' }}>
        <Box>
        <SideBars mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} />
        </Box>
        <Box sx={{ flexGrow: 1, minWidth: '' }}>
          <Box position='sticky' top={0} zIndex={10}>
          <DashbrdHeader mobileOpen={mobileOpen} setMobileOpen={setMobileOpen}  />
          </Box>
          <Box>
          <Outlet />
          </Box>
        </Box>
        </Box>
    </Box>
  )
}
