import React, { useEffect, useState } from "react";
import {
    Grid,
    TextField,
    IconButton,
    Box, Container, Stack, Typography, Button , Autocomplete
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { delete_home_page_image, get_home_image, update_home_page_image, add_home_page_images } from "../../../Services/APIService";
import axios from "axios";
import DeleteIcon from '@mui/icons-material/Delete';
import Heading from "../../../Components/Heading/Heading";
import SnackBar from "../../../Components/AdminDashBoardComps/Snackbar";
export default function TrendingLayout({section , Dimensions ,CategoryOptions , Layout, getAllData }) {

    const [editMode, setEditMode] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [imageId, setImageId] = useState('');
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [status, setStatus] = useState();
    const [color, setColor] = useState();
    const [editedData, setEditedData] = useState({
        title: "",
        content: "",
        category: null,
        section:section,
    });

    console.log(section);

    const [newData, setNewData] = useState({
        title: "",
        content: "",
        category: null,
        section:section,
      });
    
      const [newImage, setNewImage] = useState(null);


    const handleEditClick = (id) => {
        setImageId(id);
        setEditMode(true);
        getParticularData(id);
    };

    const handleImageChange = (event) => {
        const newImage = event.target.files[0];
        setSelectedImage(newImage);
    };

    const getParticularData = (id) => {
        if(id !== "" && id !== undefined){
            const sendData = new FormData()
            sendData.append('homePageImageId', id);
            axios({
              method: 'POST',
              url: get_home_image,
              data: sendData,
            }).then(res => {
                if (!res.data.error) {
                  setMessage(res.data.message)
                  setOpen(true)
                  setStatus(true)
                  setColor(true)
                    setEditedData({
                        title: res.data.data.title,
                        content: res.data.data.content,
                        category: res.data.data.category,
                        section:section,
                    })
                } else {
                  setOpen(true)
                  setStatus(false)
                  setColor(false)
                  setMessage(res.data.message)
                }
            }).catch(err => {
                alert('Oops something went wrong ' + err)
            });
        }
    }

    useEffect(() => {
        getParticularData();
    }, [imageId])



    const handleClick = () => {
        const serverData = new FormData()
        serverData.append("title", editedData.title)
        serverData.append("content", editedData.content)
        serverData.append("section", editedData.section)
        serverData.append("category", editedData.category)
        serverData.append("homePageImageId", imageId)
        serverData.append("images", selectedImage)
        axios({
            method: "POST",
            url: update_home_page_image,
            data: serverData,
        }).then(res => {
            if (!res.data.error) {
              setMessage(res.data.message)
              setOpen(true)
              setStatus(true)
              setColor(true)
                setSelectedImage(null);
                setEditMode(false);
                setImageId('');
                getAllData();
            } else {
              setOpen(true)
              setStatus(false)
              setColor(false)
              setMessage(res.data.message)
            }
        }).catch(err => {
            console.log(err)
        });
    }

    const Cancel = () => {
        setSelectedImage(null);
        setEditMode(false);
    }

    const DeleteData = (Imageid) => {
        if (Imageid !== "") {
            const sendData = new FormData()
            sendData.append('homePageImageId', Imageid);
            axios({
                method: 'POST',
                url: delete_home_page_image,
                data: sendData,
            }).then(res => {
                if (!res.data.error) {
                  setMessage(res.data.message)
                  setOpen(true)
                  setStatus(true)
                  setColor(true)
                    getAllData();
                } else {
                  setOpen(true)
                  setStatus(false)
                  setColor(false)
                  setMessage(res.data.message)
                }
            }).catch(err => {
                alert('Oops something went wrong ' + err)
            });
        }
    }


    const result = Array.isArray(Layout) ? Layout : [Layout];


    const AddNew = () =>{
        if(newData.title !== '' && newData.content !== '' && newData.category !==null && newImage !== null) {
        const serverData = new FormData()
        serverData.append("title", newData.title) 
        serverData.append("content", newData.content) 
        serverData.append("section", newData.section)
        serverData.append("category", newData.category) 
        serverData.append("isEnable", 1)
        serverData.append("images", newImage)
        axios({
          method: "POST",
          url: add_home_page_images,
          data: serverData,
        }).then(res => {
          if (!res.data.error) {
            setMessage(res.data.message)
            setOpen(true)
            setStatus(true)
            setColor(true)
            setNewImage(null);
            document.getElementById('newsliderImage').value ='';
            getAllData();
          } else {
            setOpen(true)
            setStatus(false)
            setColor(false)
            setMessage(res.data.message)
          }
        }).catch(err => {
          console.log(err)
        });
      }
        else{
          setOpen(true)
            setStatus(false)
            setColor(false)
          setMessage('Fill all the Fields');
        }
      }


    return (
        <Box>
        <SnackBar open={open} setOpen={setOpen} status={status} color={color} message={message} />
        <Box textAlign='left' mb={4}>
      <Typography variant='body1' color='#ff5d9e'>Required Dimension : {Dimensions}</Typography>
    </Box>
            <Container>
                <Grid container spacing={2}>
                    {result && result.map((image) => (
                      <Grid item xs={12} sm={6} md={6} lg={4} key={image.homePageImageId}>
                            {editMode && imageId === image.homePageImageId ? (
                                <Stack spacing={2}>
                                    <TextField
                                        label="Title"
                                        fullWidth
                                        variant="outlined"
                                        value={editedData.title}
                                        onChange={(e) =>
                                            setEditedData({ ...editedData, title: e.target.value })
                                        }
                                    />
                                    <TextField
                                        label="Content"
                                        fullWidth
                                        variant="outlined"
                                        value={editedData.content}
                                        onChange={(e) =>
                                            setEditedData({ ...editedData, content: e.target.value })
                                        }
                                    />
                                    <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={CategoryOptions}
                                    value={editedData.category}
                                    size='small'
                                    fullWidth
                                    onChange={(event, value) => setEditedData({ ...editedData, category:value.label  })}
                                    renderInput={(params) => <TextField {...params} label="Category" />}
                                    />
                                    <TextField
                                        type="file"
                                        onChange={handleImageChange}
                                        inputProps={{ accept: "image/*" }}
                                        sx={{ marginTop: 2 }}
                                    />
                                    <Box display='flex' flexDirection='row'>
                                        <Button onClick={handleClick}>Update</Button>
                                        <Button onClick={Cancel} color='error'>Cancel</Button>
                                    </Box>
                                </Stack>
                            ) : (
                                 <Stack>
                  <Box display='flex' flexDirection='row' alignContent='center'> 
                  <img
                    src={image.images}
                    style={{
                      width: 200,
                      height: 200,
                      objectFit: "contain",
                    }}
                    alt=""
                  />
                    <Box display='flex' flexDirection='column' p={1}>
                      <IconButton
                        onClick={() => handleEditClick(image.homePageImageId)}
                        color="primary"
                        sx={{ marginTop: 1 }}
                      >
                       <EditIcon sx={{ verticalAlign: 'middle' }} />
                     </IconButton>
                      
                        <IconButton
                          onClick={() => DeleteData(image.homePageImageId)}
                          color="error"
                          sx={{ marginTop: 1 }}
                        >
                            <DeleteIcon sx={{ verticalAlign: 'middle' }} />
                        </IconButton>
                  
                  </Box>
                  </Box>
                  <Box textAlign='left'>
                      <Typography variant='subtitle2'><strong>Title: </strong> {image.title}</Typography>
                      <Typography variant='subtitle2'><strong>Content: </strong>{image.content}</Typography>
                      <Typography variant='subtitle2'><strong>Category: </strong>{image.category}</Typography>
                  </Box>
                </Stack>
                            )}
                        </Grid>
                    ))}
                </Grid>
                { (section !== 'SalesPage_layout_1' && section !== 'SalesPage_layout_9') && (
                  <>
                <Box  mt={5} mb={2}>
                <Heading title={'Add New Card'} />
                </Box>
        
                <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6} lg={6} display='flex' justifyContent='start'>
                <Stack spacing={2}>
                <TextField
                  label="Title"
                  fullWidth
                  size="small"
                  variant="outlined"
                  value={newData.title}
                  onChange={(e) =>
                    setNewData({ ...newData, title: e.target.value })
                  }
                />
                <TextField
                  label="Content"
                  fullWidth
                  size="small"
                  variant="outlined"
                  value={newData.content}
                  onChange={(e) =>
                    setNewData({ ...newData, content: e.target.value })
                  }
                />
        
                <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={CategoryOptions}
                value={newData.category}
                size='small'
                fullWidth
                onChange={(event, value) => setNewData({...newData, category : value.label})}
                renderInput={(params) => <TextField {...params} label="Category" />}
                />
        
                <TextField
                  type="file"
                  size="small"
                  id='newsliderImage'
                  onChange={(e)=>setNewImage(e.target.files[0])}
                  inputProps={{ accept: 'image/*' }}
                  sx={{ marginTop: 2 }}
                />
                <Box display='flex' flexDirection='row'> 
                  <Button 
                  variant="contained"
                  color='warning' onClick={AddNew}>Generate</Button>
                </Box>
              </Stack>
                </Grid>
                </Grid>
                </>
                )}
            </Container>
        </Box>
    )
}
