import React, { useEffect, useState } from 'react'
import { Container, Grid, Button, Box, Typography, Card, Dialog, DialogActions, DialogContent, DialogTitle, Stack, } from '@mui/material';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import { add_expense_category, get_expense_category, update_expense_category } from '../../Services/APIService';

export default function ETPaymentDialog({errorMsg ,TransactionNumber , confirmOrder , ETNumber, setETNumber, EtransferDialog, setEtransferDialog }) {



    const handleClose = () => {
        setEtransferDialog(false);
        setETNumber('');
    }

    const ValidateNumber = () =>{
        if (ETNumber === TransactionNumber){
            confirmOrder();
            handleClose();
        }
        else {
            errorMsg('Entered Transaction Number is Invalid');
        }
    }


    
    return (
        <Dialog open={EtransferDialog}>
            <DialogTitle>
                Enter Transaction Number To Confirm Order
            </DialogTitle>
            <DialogContent>
                <Box display='flex' flexDirection='row' gap={2} p={3}>
                    <TextField
                        id="outlined-basic"
                        size='small'
                        label='Transaction Number'
                        value={ETNumber}
                        fullWidth
                        type='text'
                        autoComplete='off'
                        onChange={(e) => setETNumber(e.target.value)}
                        variant="outlined"
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant='outlined' color='error'>CLOSE</Button>
                <Button onClick={ValidateNumber} variant='outlined' color='success'>CONFIRM</Button>
            </DialogActions>
        </Dialog>
    )
}
