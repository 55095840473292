import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Heading from '../../Components/Heading/Heading';
import { FilterData } from '../../Components/FilterData/FilterData';
import Filter from '../../Components/FilterData/Filter';
import axios from 'axios';
import { Container, Grid, Button, Card, Typography } from '@mui/material';
import { getmainproducts, get_allproducts, get_all_deleted_products, BaseImageAPI, get_expense } from '../../Services/APIService';
import { DeletedproductsTable, ExpensesTable, PurchaseListTable, orderTable, productsTable } from '../../Variables/Variables';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PrintIcon from '@mui/icons-material/Print';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SnackBar from '../../Components/AdminDashBoardComps/Snackbar';
import ExpensesListAddDialog from './ExpensesListAddDialog';
import ExpensesListDeleteDialog from './ExpensesListDeleteDialog';

export default function ExpensesList() {

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [status, setStatus] = useState();
    const [color, setColor] = useState();
    const [ExpenseList, setExpenseList] = useState([]);
    const [search, setSearch] = useState('');
    const [openExpenseListDialog, setOpenExpenseListDialog] = useState('');
    const [openExpenseListDeleteDialog, setOpenExpenseListDeleteDialog] = useState('');
    const [ExpenseListId, setExpenseListId] = useState('');
    const [ExpenseListName, setExpenseListName] = useState('');
    const [expenseCategoryName, setexpenseCategoryName] = useState('');
    const [amount , setAmount] = useState('');
    const [totalExpenseAmount, setTotalExpenseAmount] = useState(0);

    const getAllExpense = () => {
        const sendData = new FormData();
        sendData.append('expenseId', '');
        axios({
            method: "POST",
            url: get_expense,
            data: sendData
        }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(false)
                setColor(false)
            } else {
                setOpen(true)
                setMessage(res.data.message)
                setStatus(true)
                setColor(true)
                setExpenseList(res.data.data);
            }
        }).catch(err => {
            console.log(err)
        });
    }

    useEffect(() => {
        getAllExpense();
    }, []);



    useEffect(() => {
        const calculateTotalExpenseAmount = () => {
            const totalAmount = ExpenseList.reduce((acc, item) => acc + parseFloat(item.expenseAmount), 0);
            setTotalExpenseAmount(parseFloat(totalAmount).toFixed(2));
        };

        calculateTotalExpenseAmount();
    }, [ExpenseList]);


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleOpenDialog = () => {
        setOpenExpenseListDialog(true);
    }

    const EditExpense = (id) => {
        setOpenExpenseListDialog(true);
        setExpenseListId(id);
    }


    const DeleteExpense = (id, name, category, bill) => {
        setOpenExpenseListDeleteDialog(true);
        setExpenseListId(id);
        setExpenseListName(name);
        setexpenseCategoryName(category);
        setAmount(bill)
    }


  return (
      <Box p={2}>
          <Box p={3}>
              <Heading title={'Expense List'} />
          </Box>

          <SnackBar open={open} setOpen={setOpen} status={status} color={color} message={message} />

          <ExpensesListAddDialog setExpenseListId={setExpenseListId} getAllExpense={getAllExpense} ExpenseListId={ExpenseListId} setMessage={setMessage} setOpen={setOpen} setStatus={setStatus} setColor={setColor} openExpenseListDialog={openExpenseListDialog} setOpenExpenseListDialog={setOpenExpenseListDialog} />
          <ExpensesListDeleteDialog amount={amount} setAmount={setAmount} expenseCategoryName={expenseCategoryName} setexpenseCategoryName={setexpenseCategoryName} setExpenseListId={setExpenseListId} setExpenseListName={setExpenseListName} ExpenseListName={ExpenseListName} getAllExpense={getAllExpense} ExpenseListId={ExpenseListId} setMessage={setMessage} setOpen={setOpen} setStatus={setStatus} setColor={setColor} openExpenseListDeleteDialog={openExpenseListDeleteDialog} setOpenExpenseListDeleteDialog={setOpenExpenseListDeleteDialog} />


          <Box component={Card} sx={{ boxShadow: 2 }} pb={2}>
              <Container>
                  <Box mt={2} py={2}  >
                      <Grid container>
                          <Grid item xs={12}>
                              <Box display='flex' justifyContent='space-between' py={2} border='1px 1px 0px 1px solid silver'>
                                  <Box display='flex' flexDirection='row' gap={2}>
                                      <Button variant='contained' color='success' onClick={handleOpenDialog}
                                          sx={{ borderRadius: 0, boxShadow: 0, fontWeight: 600 }} ><AddIcon sx={{ verticalAlign: 'middle', fontWeight: 600 }} /> CREATE NEW EXPENSE</Button>
                                    
                                </Box>
                                <Box px={2}>
                                      <Typography fontWeight={600}>Total : ${totalExpenseAmount}</Typography>
                                </Box>
                              </Box>
                          </Grid>
                          <TableContainer sx={{ border: '1px solid silver' }} >

                              <Table  bgcolor='#eff8f2'>
                                  <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: '#616e80' }}>
                                      <TableRow sx={{ borderBottom: '1px solid silver' }}>
                                          {
                                              ExpensesTable.map((i, index) => {
                                                  return (
                                                      <TableCell sx={{
                                                          textAlign: 'center', color: 'white', fontWeight: 600, minHeight: '20px', borderRight: '1px solid silver',
                                                      }}>
                                                          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                              <Box sx={{ my: 'auto' }}>
                                                                  {i.title}
                                                              </Box>
                                                              <Box>{
                                                                  i.filter &&
                                                                  <Filter search={search} setSearch={setSearch} setPage={setPage} />
                                                              }
                                                              </Box>
                                                          </Box>
                                                      </TableCell>
                                                  )
                                              })
                                          }
                                      </TableRow>
                                  </TableHead>


                                  <TableBody>

                                      {
                                          ExpenseList.filter((data) => FilterData(data, search, {
                                              searchFeildOne: data.productId,
                                              searchFeildTwo: data.productName,
                                          })).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((i, index) => {
                                              return (
                                                  <TableRow key={index} sx={{
                                                      borderBottom: '1px solid silver',
                                                      '& .MuiTableCell-root': {
                                                          height: '20px',
                                                      }
                                                  }}>
                                                      <TableCell sx={{
                                                          textAlign: 'center', borderBottom: '1px solid silver', borderLeft: '1px solid silver',
                                                          borderRight: '1px solid silver',
                                                      }}>{index + 1 }</TableCell>
                                                      <TableCell sx={{
                                                          textAlign: 'center', borderBottom: '1px solid silver', borderLeft: '1px solid silver',
                                                          borderRight: '1px solid silver',
                                                      }}><Box>
                                                              <Typography variant='subtitle2'>{i.expenseDetails}</Typography>
                                                              <Typography variant='caption' fontWeight={600}>{i.expenseCategory}</Typography>
                                                        </Box>
                                                        </TableCell>
                                                      <TableCell sx={{
                                                          textAlign: 'center', borderBottom: '1px solid silver', borderLeft: '1px solid silver',
                                                          borderRight: '1px solid silver',
                                                      }}>{i.expenseAmount}</TableCell>
                                                      <TableCell sx={{
                                                          textAlign: 'center', borderBottom: '1px solid silver', borderLeft: '1px solid silver',
                                                          borderRight: '1px solid silver',
                                                      }}>{i.expenseDate}</TableCell>
                                                      <TableCell sx={{
                                                          textAlign: 'center', borderBottom: '1px solid silver', borderLeft: '1px solid silver',
                                                          borderRight: '1px solid silver',
                                                      }}>
                                                          <Box display='flex' flexDirection='row' justifyContent='center' gap={2}>
                                                              <Button onClick={() => EditExpense(i.expenseId)} variant="contained" sx={{ height: 25, width: 80, fontWeight: 600, opacity: 0.9, fontSize: 12 }} ><DriveFileRenameOutlineIcon sx={{ verticalAlign: 'middle', mr: 1 }} /> EDIT</Button>
                                                              <Button onClick={() => DeleteExpense(i.expenseId, i.expenseDetails, i.expenseCategory , i.expenseAmount )} variant="contained" sx={{ height: 25, width: 90, fontWeight: 600, opacity: 0.9, fontSize: 12 }} color='error'><DeleteForeverIcon sx={{ verticalAlign: 'middle', mr: 1 }} /> DELETE</Button>
                                                          </Box>
                                                    </TableCell>
                                                  </TableRow>
                                              )
                                          })
                                      }

                                  </TableBody>
                              </Table>
                          </TableContainer>
                          <TablePagination
                              rowsPerPageOptions={[5, 10, 25]}
                              component="div"
                              count={ExpenseList.length}
                              page={page}
                              rowsPerPage={rowsPerPage}
                              onPageChange={handleChangePage}
                              onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                      </Grid>
                  </Box>
              </Container>
          </Box>
      </Box>
  )
}
