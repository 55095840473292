import React, { useEffect, useState } from 'react'
import { Container, Grid, Button, Box, Typography, Card, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Autocomplete, } from '@mui/material';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import { add_expense, get_expense, get_expense_category, update_expense } from '../../Services/APIService';


export default function ExpensesListAddDialog({ setExpenseListId, ExpenseListId, getAllExpense, setMessage, setOpen, setStatus, setColor, openExpenseListDialog, setOpenExpenseListDialog }) {


    const [expenseCategoryId, setexpenseCategoryId] = useState('');
    const [expenseCategoryName, setexpenseCategoryName] = useState('');
    const [expenseAmount, setexpenseAmount] = useState('');
    const [expenseDetails, setexpenseDetails] = useState('');
    const [expenseDate, setexpenseDate] = useState('');
    const [ExpenseCategory, setExpenseCategory] = useState([]);
    const AdminId = localStorage.getItem('AdminUserId'); 

    const handleClose = () => {
        setOpenExpenseListDialog(false);
        setExpenseListId('');
        setexpenseCategoryId('');
        setexpenseCategoryName('');
        setexpenseAmount('');
        setexpenseDetails('');
        setexpenseDate('');
    }

    const getAllExpenseCategory = () => {
        const sendData = new FormData();
        sendData.append('expenseCategoryId', '');
        axios({
            method: "POST",
            url: get_expense_category,
            data: sendData
        }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(false)
                setColor(false)
            } else {
                setOpen(true)
                setMessage(res.data.message)
                setStatus(true)
                setColor(true)
                setExpenseCategory(res.data.data);
            }
        }).catch(err => {
            console.log(err)
        });
    }

    useEffect(() => {
        getAllExpenseCategory();
    }, []);


    const Categoryoptions = ExpenseCategory.map((i) => {
        const obj = {
            'label': i.expenseCategory,
            'id': i.expenseCategoryId
        }
        return obj;
    });

    const getExpenseById = () => {
        if (ExpenseListId !== '' && ExpenseListId !== null) {
            const sendData = new FormData();
            sendData.append('expenseId', ExpenseListId);
            axios({
                method: "POST",
                url: get_expense,
                data: sendData
            }).then(res => {
                if (res.data.error) {
                    setMessage(res.data.message)
                    setOpen(true)
                    setStatus(false)
                    setColor(false)
                } else {
                    setOpen(true)
                    setMessage(res.data.message)
                    setStatus(true)
                    setColor(true)
                    setexpenseCategoryId(res.data.data.expenseCategoryId);
                    setexpenseAmount(res.data.data.expenseAmount);
                    setexpenseDetails(res.data.data.expenseDetails);
                    setexpenseDate(res.data.data.expenseDate);
                    setexpenseCategoryName(res.data.data.expenseCategory);
                }
            }).catch(err => {
                console.log(err)
            });
        }
    }

    useEffect(() => {
        getExpenseById();
    }, [ExpenseListId]);


    const handleAddExpense = () => {
        if (ExpenseListId !== '' && ExpenseListId !== null) {
            const sendData = new FormData();
            sendData.append('expenseCategoryId', expenseCategoryId);
            sendData.append('expenseAmount', expenseAmount);
            sendData.append('expenseDetails', expenseDetails);
            sendData.append('expenseId', ExpenseListId);
            sendData.append('expenseDate', expenseDate);
            axios({
                method: "POST",
                url: update_expense,
                data: sendData
            }).then(res => {
                if (res.data.error) {
                    setMessage(res.data.message)
                    setOpen(true)
                    setStatus(false)
                    setColor(false)
                } else {
                    setOpen(true)
                    setMessage(res.data.message)
                    setStatus(true)
                    setColor(true)
                    handleClose();
                    getAllExpense();
                    setExpenseListId('');
                }
            }).catch(err => {
                console.log(err)
            });
        }
        else {
            const sendData = new FormData();
            sendData.append('expenseCategoryId', expenseCategoryId);
            sendData.append('expenseAmount', expenseAmount);
            sendData.append('expenseDetails', expenseDetails);
            sendData.append('expenseDate', expenseDate);
            sendData.append('adminId', AdminId);
            axios({
                method: "POST",
                url: add_expense,
                data: sendData
            }).then(res => {
                if (res.data.error) {
                    setMessage(res.data.message)
                    setOpen(true)
                    setStatus(false)
                    setColor(false)
                } else {
                    setOpen(true)
                    setMessage(res.data.message)
                    setStatus(true)
                    setColor(true)
                    handleClose();
                    getAllExpense();
                }
            }).catch(err => {
                console.log(err)
            });
        }
    }


    return (
        <Dialog onClose={handleClose} open={openExpenseListDialog} fullWidth>
            <DialogTitle sx={{borderBottom:'1px solid silver'}}>
                CREATE NEW EXPENSE
            </DialogTitle>
            <DialogContent>
                <Stack spacing={2} py={1}>
                    <Box display='flex' flexDirection='column' gap={1}>
                        <Typography fontWeight={600} variant='body2' color='#616e80'>Amount</Typography>
                        <TextField
                            id="outlined-basic"
                            size='small'
                            fullWidth
                            value={expenseAmount}
                            onChange={(e) => setexpenseAmount(e.target.value)}
                            variant="outlined"
                        />
                    </Box>

                    <Box display='flex' flexDirection='column' gap={2}>
                        <Typography fontWeight={600} variant='body2' color='#616e80'>Expense Category</Typography>
                        <Autocomplete
                            id="combo-box-demo"
                            size="small"
                            fullWidth
                            value={Categoryoptions.find((option) => option.id === expenseCategoryId) || ''}
                            onChange={(event, value) => {
                                if (value) {
                                    setexpenseCategoryId(value.id);
                                } else {
                                    setexpenseCategoryId(''); // Set to an appropriate default value when nothing is selected
                                }
                            }}
                            options={Categoryoptions}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </Box>


                    <Box display='flex' flexDirection='column' gap={2}>
                        <Typography fontWeight={600} variant='body2' color='#616e80'>Details</Typography>
                        <TextField
                            id="outlined-basic"
                            size='small'
                            multiline
                            rows={4}
                            fullWidth
                            value={expenseDetails}
                            onChange={(e) => setexpenseDetails(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                            variant="outlined"
                        />
                    </Box>


                    <Box display='flex' flexDirection='column' gap={2}>
                        <Typography fontWeight={600} variant='body2' color='#616e80'>Date</Typography>
                        <TextField
                            id="outlined-basic"
                            size='small'
                            type='date'
                            InputLabelProps={{
                                shrink:true
                            }}
                            fullWidth
                            value={expenseDate}
                            onChange={(e) => setexpenseDate(e.target.value)}
                            variant="outlined"
                        />
                    </Box>

                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant='outlined' color='error'>CLOSE</Button>
                <Button onClick={handleAddExpense} variant='outlined' color='success'>{ExpenseListId !== '' && ExpenseListId !== null ? 'UPDATE' : 'SAVE'}</Button>
            </DialogActions>
        </Dialog>
    )
}
