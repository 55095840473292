import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Container, Grid, Button, Box, Typography, Card, Dialog, DialogActions, DialogContent, DialogTitle, Stack, } from '@mui/material';
import Heading from '../../Components/Heading/Heading';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Divider from '@mui/material/Divider';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { add_channel_sale_order, add_purchase, get_all_product_name, get_color_by_product_id, get_product_and_cost, get_provinces, get_vendor, gettax } from '../../Services/APIService';
import axios from 'axios';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SnackBar from '../../Components/AdminDashBoardComps/Snackbar';

export default function CreateNewSale() {

    const [tableRows, setTableRows] = useState([{}]); // Initial row
    const [supplierName, setSupplierName] = useState(null);
    const [purchaseDate, setPurchaseDate] = useState('');
    const [totalWithoutVat, setTotalWithoutVat] = useState('');
    const [discount, setDiscount] = useState(0);
    const [discountType, setDiscountType] = useState(null);
    const [discountAmount, setDiscountAmount] = useState('');
    const [invoiceVat, setInvoiceVat] = useState(0);
    const [netTotal, setNetTotal] = useState('');
    const [paymentMethod, setPaymentMethod] = useState('');
    const [paidAmount, setPaidAmount] = useState('');
    const [paymentTerms, setPaymentTerms] = useState('');
    const [dueAmount, setDueAmount] = useState('');
    const [productList, setProductList] = useState([]);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [status, setStatus] = useState();
    const [color, setColor] = useState();
    const [SavedColorQty, setSavedColorQty] = useState([]);
    const [SavedColor, setSavedColor] = useState('');
    const [orderedColor, setOrderedColor] = useState('');
    const [SavedQty, setSavedQty] = useState('');
    const [currentRowIndex, setCurrentRowIndex] = useState('');
    const [overallSubtotal, setOverallSubtotal] = useState(0);
    const [VendorList, setVendorList] = useState([]);
    const [openDialogs, setOpenDialogs] = useState(tableRows.map(() => false));
    const [customerName, setCustomerName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [customerProvince, setcustomerProvince] = useState(null);
    const [taxCost, setTaxcost] = useState('');
    const [tax, setTax] = useState('');
    const [orderValue, setOrderValue] = useState('')
    const [ Taxinfo ,   setTaxinfo] = useState('');
    const[provinceList , setProvinceList] = useState([]);
    const [DueFor, setDueFor] = useState(null);


    const [errorMsg1, setErrorMsg1] = useState('');
    const [Error1 , setError1] = useState(false);
  
    const [errorMsg2, setErrorMsg2] = useState('');
    const [Error2 , setError2] = useState(false);
  
    const [errorMsg3, setErrorMsg3] = useState('');
    const [Error3 , setError3] = useState(false);

    // Define onChange handlers to update state variables
    const handleCustomerNameChange = (e) => {
        const newValue = e.target.value;
        var regName = /^[A-Za-z\s]+$/; // Allow spaces with \s
        if (newValue.match(regName) || newValue === "") {
          setErrorMsg1("");
          setError1(false);
          setCustomerName(newValue); // only set when successful
        } else {
          setError1(true);
          setErrorMsg1("Numeric and Special characters are not allowed");
        }
    };

    const handlePhoneNumberChange = (e) => {
        const newValue = e.target.value;
        if (newValue.match(/^[0-9]+$/)){
            setPhoneNumber(parseInt(newValue));
        }
        else{
            setPhoneNumber('');
        } 
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const getallVendorAPI = () => {
        axios({
            method: "POST",
            url: get_vendor,
        }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(false)
                setColor(false)
            } else {
                setOpen(true)
                setMessage(res.data.message)
                setVendorList(res.data.data);
                setStatus(true)
                setColor(true)
            }
        }).catch(err => {
            console.log(err)
        });
    }

    useEffect(() => {
        getallVendorAPI();
    }, [])

    const VendorOptions = VendorList.map((i) => {
        const obj = {
            'label': i.vendorName,
            'id': i.vendorId
        }
        return obj;
    })

    const handleOpenDialog = (index) => {
        setOpenDialogs((prevOpenDialogs) => {
            const updatedOpenDialogs = [...prevOpenDialogs];
            updatedOpenDialogs[index] = true;
            return updatedOpenDialogs;
        });
        setCurrentRowIndex(index);
    };


    const handleCloseDialog = (index) => {
        setOpenDialogs((prevOpenDialogs) => {
            const updatedOpenDialogs = [...prevOpenDialogs];
            updatedOpenDialogs[index] = false;
            return updatedOpenDialogs;
        });
    };


    const handleAddRow = () => {
        const newRow = {
            productId: '',
            productName: '',
            productCost: '',
            overAllQty: '',
            savedColorQty: [],
            subtotal: '',
        };
        setTableRows((prevRows) => [...prevRows, newRow]);
    };

    const handleDeleteRow = (index) => {
        setTableRows((prevRows) => {
            const updatedRows = [...prevRows];
            updatedRows.splice(index, 1);
            return updatedRows;
        });
    };


    useEffect(() => {
        axios({
            method: "GET",
            url: get_all_product_name,
        }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(false)
                setColor(false)
            } else {
                setOpen(true)
                setMessage(res.data.message)
                setProductList(res.data.data);
                setStatus(true)
                setColor(true)
            }
        }).catch(err => {
            console.log(err)
        });
    }, [])

    const Productoptions = productList.map((i) => {
        const obj = {
            'label': i.productName,
            'id': i.productId
        }
        return obj;
    });


    const getProductCost = (product, index) => {
        if (product.id !== '' || product.id !== null) {
            const sendData = new FormData();
            sendData.append('productId', product.id);
            axios({
                method: "POST",
                url: get_product_and_cost,
                data: sendData
            }).then(res => {
                if (res.data.error) {
                    setMessage(res.data.message)
                    setOpen(true)
                    setStatus(false)
                    setColor(false)
                } else {
                    setOpen(true)
                    setMessage(res.data.message)
                    setTableRows((prevRows) => {
                        const updatedRows = [...prevRows];
                        updatedRows[index].productCost = res.data.data.productPrice;
                        return updatedRows;
                    });
                    setStatus(true)
                    setColor(true)
                }
            }).catch(err => {
                console.log(err)
            });
        }
    }



    const getProductColor = (product, index) => {
        if (product.id !== '' || product.id !== null) {
            const sendData = new FormData();
            sendData.append('productId', product.id);
            axios({
                method: "POST",
                url: get_color_by_product_id,
                data: sendData
            }).then(res => {
                if (res.data.error) {
                    setMessage(res.data.message)
                    setOpen(true)
                    setStatus(false)
                    setColor(false)
                } else {
                    setMessage(res.data.message)
                    setTableRows((prevRows) => {
                        const updatedRows = [...prevRows];
                        updatedRows[index].ColorQuantity = res.data.data;
                        return updatedRows;
                    });
                    setStatus(true)
                    setColor(true)
                }
            }).catch(err => {
                console.log(err)
            });
        }
    }

    const handleProductChange = (index, value) => {
        setTableRows((prevRows) => {
            const updatedRows = [...prevRows];
            if (value) {
                updatedRows[index].productId = value.id;
                updatedRows[index].productName = value.label;
                updatedRows[index].productColor = null;
            } else {
                updatedRows[index].productId = null;
                updatedRows[index].productName = '';
                updatedRows[index].productCost = '';
            }
            return updatedRows;
        });

        if (value) {
            getProductCost(value, index);
            getProductColor(value, index);
        }
    };

    const calculateSubtotal = (cost, quantity) => {
        return parseFloat(cost * quantity).toFixed(2);
    };

    const handleProductCostChange = (index, value) => {
        setTableRows((prevRows) => {
            const updatedRows = [...prevRows];
            updatedRows[index].productCost = value;
            updatedRows[index].subtotal = calculateSubtotal(value, updatedRows[index].overAllQty);
            return updatedRows;
        });
    };

    const handleOverAllQtyChange = (index, value) => {
        setTableRows((prevRows) => {
            const updatedRows = [...prevRows];
            updatedRows[index].overAllQty = value;
            updatedRows[index].subtotal = calculateSubtotal(updatedRows[index].productCost, value);
            return updatedRows;
        });
    };


    const handleOverAllColorChange = (index, value) => {
        setTableRows((prevRows) => {
            const updatedRows = [...prevRows];
            updatedRows[index].productColor = value;
            return updatedRows;
        });
    };

    const handleSubtotalChange = (index, value) => {
        setTableRows((prevRows) => {
            const updatedRows = [...prevRows];
            updatedRows[index].subtotal = value;
            return updatedRows;
        });
    };



    useEffect(() => {
        const subtotal = tableRows.reduce((total, row) => total + (row.subtotal || 0), 0); // Handle undefined values
        const parsedSubtotal = parseFloat(subtotal);
        if (!isNaN(parsedSubtotal)) { // Check for valid numbers
            setOverallSubtotal(parsedSubtotal.toFixed(2));
            setTotalWithoutVat(parsedSubtotal.toFixed(2));
            setNetTotal(parsedSubtotal.toFixed(2));
        }
    }, [tableRows]);


    const handleDiscountTypeChange = (event, value) => {
        setDiscountType(value);
        if (value === 'Fixed') {
            setDiscountAmount(discount);
            const netTotalAmount = parseFloat(overallSubtotal - discount);
            setNetTotal(netTotalAmount.toFixed(2));
        } else if (value === 'Percentage') {
            const discountPercent = parseFloat(discount);
            const discountAmountValue = (discountPercent / 100) * overallSubtotal;
            const netTotalAmount = overallSubtotal - discountAmountValue;
            setDiscountAmount(discountAmountValue.toFixed(2));
            setNetTotal(netTotalAmount);
        }
        else {
            setNetTotal(overallSubtotal);
            setDiscountAmount('');
        }
    };


    const handlePaidAmount = (e) => {
        setPaidAmount(e.target.value);
        if (e.target.value !== '' && e.target.value !== null) {
            const duePayment = netTotal - e.target.value;
            setDueAmount(duePayment.toFixed(2));
        }
        else {
            setDueAmount(netTotal);
        }
    }


    const product = {};
    const colorQty = {};

    tableRows && tableRows.forEach((item, index) => {
        const productItem = {
            productId: item.productId,
            productName: item.productName,
            productPrice: item.productCost,
            productQty: item.overAllQty,
            productColor:item.productColor,
        };
        product[index] = productItem;
    });

    const productArray = Object.values(product);
    const colorQtyArray = Object.values(colorQty);

    const onSubmit = () => {
        if (supplierName !== '' && purchaseDate !== '' && paidAmount !== '') {
            const serverData = new FormData()
            serverData.append('customerName', customerName);
            serverData.append('customerMobile', phoneNumber);
            serverData.append('customerMail', email);
            serverData.append('saleDate', purchaseDate)
            serverData.append('totalWithoutVat', totalWithoutVat);
            serverData.append('discount', discount);
            serverData.append('discountType', discountType);
            serverData.append('discountAmount', discountAmount);
            serverData.append('invoiceVat', invoiceVat);
            serverData.append('netTotal', netTotal);
            serverData.append('paymentMethod', paymentMethod);
            serverData.append('paidAmount', paidAmount);
            serverData.append('paymentTerms', paymentTerms);
            serverData.append('dueAmount', dueAmount);
            serverData.append('items', JSON.stringify(product));
            serverData.append('customerProvince', customerProvince?.label);
            serverData.append('taxCost', taxCost);
            serverData.append('dueFor', DueFor);
            axios({
                method: "POST",
                url: add_channel_sale_order,
                data: serverData,
            }).then(res => {
                if (res.data.error) {
                    setMessage(res.data.message)
                    setOpen(true)
                    setStatus(false)
                    setColor(false)
                } else {
                    setOpen(true)
                    setMessage(res.data.message)
                    setStatus(true)
                    setColor(true)
                    setCustomerName('')
                    setPhoneNumber('')
                    setEmail('')
                    setPurchaseDate('')
                    setTotalWithoutVat('')
                    setDiscount(0)
                    setDiscountType(null)
                    setDiscountAmount('')
                    setInvoiceVat(0)
                    setNetTotal('')
                    setPaymentMethod('')
                    setPaidAmount('')
                    setDueAmount('')
                    setProductList([])
                    setSavedColorQty([])
                    setSavedColor('')
                    setSavedQty('')
                    setCurrentRowIndex('')
                    setOverallSubtotal(0)
                    setTableRows([{}]);
                    setcustomerProvince(null)
                    setTaxinfo('');
                    setPaymentTerms('');
                    setTax('')
                    setOrderValue('')
                    setTaxcost('')
                    setDueFor(null)
                }
            }).catch(err => {
                console.log(err)
            });
        }
        else {
            setOpen(true)
            setMessage('Fill all the Fields')
            setStatus(false)
            setColor(false)
        }
    }


    useEffect(() =>{
        axios({
              method: "GET",
              url: get_provinces,
          }).then(res => {
                if (res.data.error) {
                    setMessage(res.data.message)
                    setStatus(false)
                    setColor(false)
                    setOpen(true);
                } else {
                    setProvinceList(res.data.data)
                }
            }).catch(err => {
                console.log(err)
            });
      },[])
    
      const options = provinceList.map((i)=>{
        const obj = {
          'label':i.ProvinceName,
          'code': i.provinceCode,
          'id':i.provinceId
        }
        return obj ;
       });


       useEffect(()=>{
        if(customerProvince !== null && customerProvince !== '' && netTotal !== null && netTotal !==''){
        const serverData = new FormData()
        serverData.append('province', customerProvince?.label);
        serverData.append('total', netTotal);
        axios({
            method: "POST",
            url: gettax,
            data: serverData,
        }).then(res => {
            if (!res.data.error) {
              setTaxcost(res.data.tax_details.total_tax_amount)
              setTax(res.data.tax_details.total_tax)
              setOrderValue(res.data.tax_details.total_amount)
            } else {
                setOpen(true);
                setMessage(res.data.message)
                setStatus(false)
                setColor(false)
            }
        }).catch(err => {
            console.log(err)
        });
       }
       },[customerProvince , netTotal])


       const validateInput = () =>{
        if(email !== ''){
        if (/\S+@\S+\.\S+/.test(email)) {
          setErrorMsg3("");
          setError3(false);
          } else {
          setError3(true);
          setErrorMsg3("Invalid Email Address");
          }
        }
        else{
          setError3(true);
          setErrorMsg3("Email Address Cannot Be Empty");
        }
      }

    return (
        <Box p={2}>
            <Box p={3}>
                <Heading title={'Create New Sale'} />
            </Box>
            <Box component={Card} sx={{ boxShadow: 2 }} pb={2}>
            <Container>
                <Box mt={1} py={2}  >
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={12} py={2}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                        <TextField
                                            id="customerName"
                                            label="Customer Name"
                                            size="small"
                                            variant="outlined"
                                            fullWidth
                                            helperText={errorMsg1}
                                            error={Error1}
                                            value={customerName} // Bind value to state variable
                                            onChange={handleCustomerNameChange} // Bind onChange event handler
                                        />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                        <TextField
                                            id="phoneNumber"
                                            label="Phone Number"
                                            size="small"
                                            variant="outlined"
                                            fullWidth
                                            inputProps={{
                                                maxLength: 13,
                                              }}
                                            value={phoneNumber} // Bind value to state variable
                                            onChange={handlePhoneNumberChange} // Bind onChange event handler
                                        />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                        <TextField
                                            id="email"
                                            label="Email"
                                            size="small"
                                            variant="outlined"
                                            fullWidth
                                            helperText={errorMsg3}
                                            error={Error3}
                                            onBlur={validateInput}
                                            value={email} // Bind value to state variable
                                            onChange={handleEmailChange} // Bind onChange event handler
                                        />
                                </Grid>
                                </Grid>
                        </Grid>
                       

                        
                            <TableContainer component={Card} boxShadow={0}>
                                <Table bgcolor='#eff8f2'>
                                    <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: '#616e80' }}>
                                        <TableRow>
                                            <TableCell align="center" sx={{ borderRight: '1px solid silver' }} colSpan={4}>
                                                Product
                                            </TableCell>
                                            <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>Unit Cost($)</TableCell>
                                            <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>Ordered Qty</TableCell>
                                            <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>Color</TableCell>
                                            <TableCell align="center" colSpan={4} >Sub Total($)</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {tableRows.map((row, index) => (
                                            <TableRow key={index}>

                                                <TableCell colSpan={4} align="center" sx={{ borderRight: '1px solid silver' }}>
                                                    <Autocomplete
                                                        disablePortal
                                                        id="combo-box-demo"
                                                        options={Productoptions}
                                                        value={row.productName || ''}
                                                        size='small'
                                                        sx={{ width: 180 }}
                                                        onChange={(event, value) => handleProductChange(index, value)}
                                                        renderInput={(params) => <TextField {...params} label="Select a Product" />}
                                                    />
                                                </TableCell>

                                                <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>
                                                    <TextField
                                                        id="outlined-basic"
                                                        onChange={(event) => handleProductCostChange(index, event.target.value)}
                                                        value={row.productCost || ''}
                                                        size='small'
                                                        sx={{ width: 100 }}
                                                        variant="outlined"
                                                    />
                                                </TableCell>

                                                <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>
                                                    <TextField
                                                        id="outlined-basic"
                                                        type='number'
                                                        value={row.overAllQty || ''}
                                                        onChange={(event) => handleOverAllQtyChange(index, event.target.value)}
                                                        size='small'
                                                        sx={{ width: 100 }}
                                                        variant="outlined"
                                                    />
                                                </TableCell>

                                                <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>
                                                    <Autocomplete
                                                    id="combo-box-demo"
                                                    size="small"
                                                    sx={{width:400}}
                                                    value={row?.productColor || null}
                                                    onChange={(event , value) => handleOverAllColorChange(index, value)}
                                                    options={row?.ColorQuantity}
                                                    getOptionLabel={(option) => option?.productColor}
                                                    renderInput={(params) => <TextField {...params} />}
                                                    />
                                                </TableCell>

                                                <TableCell align="center" colSpan={4} >
                                                    <Box display='flex' gap={4} justifyContent='end'>

                                                        <TextField
                                                            value={row.subtotal || ''}
                                                            onChange={(event) => handleSubtotalChange(index, event.target.value)}
                                                            size='small'
                                                            variant="outlined"
                                                        />
                                                        {
                                                            index > 0 ?
                                                                <HighlightOffIcon onClick={() => handleDeleteRow(index)} sx={{ verticalAlign: 'middle' }} color='error' fontSize='large' />
                                                                :
                                                                <AddBoxIcon onClick={handleAddRow} sx={{ verticalAlign: 'middle' }} color='primary' fontSize='large' />
                                                        }
                                                    </Box>
                                                </TableCell>

                                            </TableRow>
                                        ))}

                                        <TableRow>
                                            <TableCell />
                                            <TableCell align="right" colSpan={6} sx={{ borderRight: '1px solid silver' }}>Date</TableCell>
                                            <TableCell align="center" >
                                                <TextField
                                                    id="outlined-basic"
                                                    size='small'
                                                    variant="outlined"
                                                    fullWidth
                                                    type='date'
                                                    onChange={(e) => setPurchaseDate(e.target.value)}
                                                    value={purchaseDate}
                                                />
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell />
                                            <TableCell align="right" colSpan={6} sx={{ borderRight: '1px solid silver' }}>Total<Typography component='span' variant='caption'>(Excluding Vat)</Typography></TableCell>
                                            <TableCell align="center">
                                                <TextField
                                                    id="outlined-basic"
                                                    size='small'
                                                    variant="outlined"
                                                    fullWidth
                                                    disabled
                                                    value={overallSubtotal}
                                                />
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell />
                                            <TableCell align="right" colSpan={6} sx={{ borderRight: '1px solid silver' }}>Discount</TableCell>
                                            <TableCell align="center">
                                                <TextField
                                                    id="outlined-basic"
                                                    size='small'
                                                    variant="outlined"
                                                    fullWidth
                                                    onChange={(e) => setDiscount(e.target.value)}
                                                    value={discount}
                                                />
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell />
                                            <TableCell align="right" colSpan={6} sx={{ borderRight: '1px solid silver' }}>Discount Type</TableCell>
                                            <TableCell align="center">
                                                <Autocomplete
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={['Fixed', 'Percentage']}
                                                    size='small'
                                                    disabled={discount === 0 ? true : false}
                                                    onChange={handleDiscountTypeChange}
                                                    value={discountType}
                                                    renderInput={(params) => <TextField {...params} InputLabelProps={{ shrink: true }} />}
                                                /></TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell />
                                            <TableCell align="right" colSpan={6} sx={{ borderRight: '1px solid silver' }}>Discount Amount</TableCell>
                                            <TableCell align="center">
                                                <TextField
                                                    id="outlined-basic"
                                                    size='small'
                                                    variant="outlined"
                                                    fullWidth
                                                    disabled
                                                    value={discountAmount}
                                                />
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell />
                                            <TableCell align="right" colSpan={6} sx={{ borderRight: '1px solid silver' }}>Net Total</TableCell>
                                            <TableCell align="center">
                                                <TextField
                                                    id="outlined-basic"
                                                    size='small'
                                                    variant="outlined"
                                                    fullWidth
                                                    disabled
                                                    value={netTotal}
                                                />
                                            </TableCell>
                                        </TableRow>


                                        <TableRow>
                                            <TableCell />
                                            <TableCell align="right" colSpan={6} sx={{ borderRight: '1px solid silver' }}>Province</TableCell>
                                            <TableCell align="center">
                                                <Autocomplete
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={options}
                                                    size='small'
                                                    onChange={(event, value) => setcustomerProvince(value)}
                                                    value={customerProvince}
                                                    renderInput={(params) => <TextField {...params} InputLabelProps={{ shrink: true }} />}
                                                />
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell />
                                            <TableCell align="right" colSpan={6} sx={{ borderRight: '1px solid silver' }}>Tax (%)</TableCell>
                                            <TableCell align="center">
                                                <TextField
                                                    id="outlined-basic"
                                                    size='small'
                                                    variant="outlined"
                                                    fullWidth
                                                    disabled
                                                    value={tax}
                                                />
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell />
                                            <TableCell align="right" colSpan={6} sx={{ borderRight: '1px solid silver' }}>Total Tax ($)</TableCell>
                                            <TableCell align="center">
                                                <TextField
                                                    id="outlined-basic"
                                                    size='small'
                                                    variant="outlined"
                                                    fullWidth
                                                    disabled
                                                    value={taxCost}
                                                />
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell />
                                            <TableCell align="right" colSpan={6} sx={{ borderRight: '1px solid silver' }}>Total Order Amount ($)</TableCell>
                                            <TableCell align="center">
                                                <TextField
                                                    id="outlined-basic"
                                                    size='small'
                                                    variant="outlined"
                                                    fullWidth
                                                    disabled
                                                    value={orderValue}
                                                />
                                            </TableCell>
                                        </TableRow>


                                        <TableRow>
                                            <TableCell />
                                            <TableCell align="right" colSpan={6} sx={{ borderRight: '1px solid silver' }}>Payment Method</TableCell>
                                            <TableCell align="center">
                                                <Autocomplete
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={['Cash', 'Card', 'Cheque', 'Others']}
                                                    size='small'
                                                    onChange={(event, value) => setPaymentMethod(value)}
                                                    value={paymentMethod}
                                                    renderInput={(params) => <TextField {...params} InputLabelProps={{ shrink: true }} />}
                                                />
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell />
                                            <TableCell align="right" colSpan={6} sx={{ borderRight: '1px solid silver' }}>Payment Terms</TableCell>
                                            <TableCell align="center">
                                                <TextField
                                                    id="outlined-basic"
                                                    size='small'
                                                    variant="outlined"
                                                    fullWidth
                                                    onChange={(e)=>setPaymentTerms(e.target.value)}
                                                    value={paymentTerms}
                                                />
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell />
                                            <TableCell align="right" colSpan={6} sx={{ borderRight: '1px solid silver' }}>Paid ($)</TableCell>
                                            <TableCell align="center">
                                                <TextField
                                                    id="outlined-basic"
                                                    size='small'
                                                    variant="outlined"
                                                    fullWidth
                                                    onChange={handlePaidAmount}
                                                    value={paidAmount}
                                                />
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell />
                                            <TableCell align="right" colSpan={6} sx={{ borderRight: '1px solid silver' }}>Due ($)</TableCell>
                                            <TableCell align="center">
                                                <TextField
                                                    id="outlined-basic"
                                                    size='small'
                                                    variant="outlined"
                                                    fullWidth
                                                    value={dueAmount}
                                                />
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell />
                                            <TableCell align="right" colSpan={6} sx={{ borderRight: '1px solid silver' }}>Due For</TableCell>
                                            <TableCell align="center">
                                            <Autocomplete
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={['30 Days', '45 Days', '60 Days', '90 Days']}
                                                    size='small'
                                                    onChange={(event, value) => setDueFor(value)}
                                                    value={DueFor}
                                                    renderInput={(params) => <TextField {...params} InputLabelProps={{ shrink: true }} />}
                                                />
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell />
                                            <TableCell align="right" colSpan={6}></TableCell>
                                            <TableCell align="center">
                                                <Button
                                                    color='warning'
                                                    variant='contained'
                                                    fullWidth
                                                    onClick={onSubmit}
                                                >Submit
                                                </Button>
                                            </TableCell>
                                        </TableRow>

                                    </TableBody>
                                </Table>
                            </TableContainer>
                    </Grid>
                </Box>
            </Container>
            </Box>
        </Box>
    );
}
