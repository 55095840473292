import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Box, Typography, Card, Grid, Stack, TextField } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import Stats from './Stats';
import OrderVsPending from './OrderVsPending';
import Revenue from './Revenue';

export default function DashBoard() {



  return (
    <Box>
      <Box p={3}>

      <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
      <Stats />
      </Grid>


      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
      <OrderVsPending />
      </Grid>


         <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
         <Box sx={{cursor:'pointer', textAlign:'left'}}>
         <Typography variant='h6' sx={{color:'#faa634' , cursor:'pointer'}}>REVENUE</Typography>
         </Box>
          <Box component={Card} sx={{
                  p: 2.5, borderRadius: 5, marginTop:2.5, '&:hover': {
                    boxShadow: 5
                  }
                }}>
              <Revenue />
        </Box>
        </Grid>
        </Grid>






      </Box>
    </Box>
  )
}
