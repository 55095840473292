import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Heading from '../../Components/Heading/Heading';
import { FilterData } from '../../Components/FilterData/FilterData';
import Filter from '../../Components/FilterData/Filter';
import axios from 'axios';
import { Container, Grid } from '@mui/material';
import { getmainproducts, get_allproducts, get_all_deleted_products, BaseImageAPI } from '../../Services/APIService';
import { DeletedproductsTable, orderTable, productsTable } from '../../Variables/Variables';

export default function ArchiveProducts() {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [status, setStatus] = useState();
    const [color, setColor] = useState();
    const [products, setProducts] = useState([]);
    const [search, setSearch] = useState('');



    useEffect(() => {
        axios({
            method: "GET",
            url: get_all_deleted_products,
        }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(false)
                setColor(false)
                setProducts([]);
            } else {
                setOpen(true)
                setMessage(res.data.message)
                setProducts(res.data.data);
                setStatus(true)
                setColor(true)
            }
        }).catch(err => {
            console.log(err)
        });
    }, [])


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };



    return (
        <Box p={2}>
            <Box p={3}>
                <Heading title={'Archived Products'} />
            </Box>
            <Container>
                <Box mt={2} py={4}  >
                    <Grid container>
                        <TableContainer sx={{ border: '1px solid silver' }} >

                            <Table>
                                <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: '#616e80' }}>
                                    <TableRow sx={{ borderBottom: '1px solid silver' }}>
                                        {
                                            DeletedproductsTable.map((i, index) => {
                                                return (
                                                    <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                            <Box sx={{ my: 'auto' }}>
                                                                {i.title}
                                                            </Box>
                                                            <Box>{
                                                                i.filter &&
                                                                <Filter search={search} setSearch={setSearch} setPage={setPage} />
                                                            }
                                                            </Box>
                                                        </Box>
                                                    </TableCell>
                                                )
                                            })
                                        }
                                    </TableRow>
                                </TableHead>


                                <TableBody>

                                    {
                                        products && products.filter((data) => FilterData(data, search, {
                                            searchFeildOne: data.productId,
                                            searchFeildTwo: data.productName,
                                        })).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((i, index) => {
                                            return (
                                                <TableRow key={index} sx={{ borderBottom: '1px solid silver' }}>
                                                    <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}> <img style={{ width: 100, height: 100 }} src={`${BaseImageAPI}${i.mainImage}`} alt='Image' /> </TableCell>
                                                    <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}>{i.productId}</TableCell>                                                 
                                                    <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}>{i.productName}</TableCell>
                                                    <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}>{i.productPrice}</TableCell>
                                                    <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}>{i.productQty}</TableCell>
                                                    <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}>{i.productDiscountPercent}</TableCell>
                                                    <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid silver' }}>{i.productLeadTime}</TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }

                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={products ? products.length : 0}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Grid>
                </Box>
            </Container>
        </Box>
    );
}