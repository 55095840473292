import { Box, Typography, Card, Grid, TextField, Button, Autocomplete, Stack, IconButton, Paper, InputAdornment} from '@mui/material'
import React, { useEffect } from 'react'
import Heading from '../../Components/Heading/Heading';
import { useForm } from "react-hook-form";
import { useState } from 'react';
import { BaseImageAPI, add_main_category, add_product_image, add_products, delete_product_image, get_all_product_name, get_products_in_admin, getallcategory, update_product } from '../../Services/APIService';
import axios from 'axios';
import { Category, SocialDistanceOutlined } from '@mui/icons-material';
import RemoveDialogBox from './RemoveDialogBox';
import EditIcon from '@mui/icons-material/Edit';
import SnackBar from '../../Components/AdminDashBoardComps/Snackbar';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation } from 'react-router-dom';
import FastRewindOutlinedIcon from '@mui/icons-material/FastRewindOutlined';
import { useNavigate } from 'react-router-dom';

export default function UpdateProducts() {


    const { register, formState: { errors }, handleSubmit, reset } = useForm();
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [status, setStatus] = useState();
    const [color, setColor] = useState();
    const [BackDropText, setBackDropText] = useState('');
    const location = useLocation();
    const {productId} = location.state;
    const [openBackdrop, setOpenBackdrop] = useState(false);
    // PRODUCT DATA
    const [productName , setProductName] = useState('');
    const [productDescription, setProductDescription] = useState('');
    const [productPrice, setProductPrice] = useState('');
    const [MRPproductPrice, setMRPProductPrice] = useState('');
    const [productQty, setProductQty] = useState('');
    const [productWeight, setProductWeight] = useState('');
    const [productLength, setProductLength] = useState('');
    const [productWidth, setProductWidth] = useState('');
    const [productHeight, setProductHeight] = useState('');
    const [productDiscountPercent, setProductDiscountPercent] = useState('');
    const [productLeadTime, setProductLeadTime] = useState('');
    const [productColor, setProductColor] = useState('');
    const [mainImage, setmainImage] = useState('');
    const [productImage, setproductImage] = useState('');
    const [CreateNewproductImage, setCreateNewproductImage] = useState('');
    const [productVideo, setproductVideo] = useState('');
    const [input, setInput] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [showEdit , setShowEdit] = useState(false);

    const [LaunchingSoon, setLaunchingSoon] = useState("");
    const [ComboColor, setComboColor] = useState("");
    const [ColorCode, setColorCode] = useState("");
    const [SoldOut, setSoldOut] = useState("");
    const [Publish, setPublish] = useState("");
    // Displaying Data 
    const [showProduct, setShowProduct] = useState(false);
    const [newImageURLs, setNewImageURLs] = useState([]);
    const [newVideoURLs, setNewVideoURLs] = useState('');
    const [newMainImageURLs, setNewMainImageURLs] = useState('');
    const [newImageIndex, setNewImageIndex] = useState(-1);
    const [productImageId, setProductImageId] = useState([]);
    const [newImages , setNewImages] = useState([]);
    const [KeepMainImage, setKeepMainImage] = useState('')
    const [KeepproductVideo, setKeepproductVideo] = useState('')
    const [showNewlyAddedImage , setShowNewlyAddedImage] = useState(false);
    const [showCreateNewPreviewImages, setshowCreateNewPreviewImages] = useState(false);
    const [newlyAddedImageURLs, setNewlyAddedImageURLs] = useState([]);
    const [error, setError] = React.useState('');
    const [newColor, setNewColor] = useState("");
    const [newColorImages, setNewColorImages] = useState([]);
    const [newColorImagesArray, setNewColorImagesArray] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState("");
    const [productList, setProductList] = useState([]);
    const numberRegex = /^[0-9]+(\.[0-9]+)?$/;
    const [PreviewImagesByColor, setPreviewImagesByColor] = useState([]);
    const navigate = useNavigate();
    const handleInputPriceChange = (event) => {
        const value = event.target.value;
        setProductPrice(value);

        if (!numberRegex.test(value)) {
            setError('Product Price must be a valid number');
        } else {
            setError('');
            
        }
    };

    const handleInputMRPPriceChange = (event) => {
        const value = event.target.value;
        setMRPProductPrice(value);

        if (!numberRegex.test(value)) {
            setError('MRP Price must be a valid number');
        } else {
            setError('');

        }
    };

    useEffect(() => {
        axios({
            method: "GET",
            url: get_all_product_name,
        }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(false)
                setColor(false)
            } else {
                setOpen(true)
                setMessage(res.data.message)
                setProductList(res.data.data);
                setStatus(true)
                setColor(true)
            }
        }).catch(err => {
            console.log(err)
        });
    }, [])


    const handleClick = () => {
        if (productId !== '' && productId !== null){
        const serverData = new FormData()
        serverData.append('productId', productId)
        axios({
            method: "POST",
            url: get_products_in_admin,
            data: serverData,
        }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(false)
                setColor(false)
                setShowEdit(false);
            } else {
                setOpen(true)
                setMessage(res.data.message)
                setStatus(true)
                setColor(true);
                setShowEdit(true);
                setProductName(res.data.products.productName);
                setProductDescription(res.data.products.productDescription);
                setProductPrice(res.data.products.productPrice.replace(/\$/g, ''));
                setProductQty(res.data.products.productQty);
                setProductDiscountPercent(res.data.products.productDiscountPercent);
                setProductLeadTime(res.data.products.productLeadTime);
                setProductColor(res.data.products.productColor);
                setmainImage(res.data.products.mainImage);
                setKeepMainImage(res.data.products.mainImage);
                setproductImage(res.data.products.productImage);
                setproductVideo(res.data.products.productVideo);
                setKeepproductVideo(res.data.products.productVideo);
                setProductWeight(res.data.products.productWeight);
                setProductHeight(res.data.products.productHeight);
                setProductLength(res.data.products.productLength);
                setProductWidth(res.data.products.productWidth);
                setMRPProductPrice(res.data.products.productMRP.replace(/\$/g, ''))
                setLaunchingSoon(res.data.products.launchingSoon)
                setComboColor(res.data.products.comboColor)
                setColorCode(res.data.products.colorCode)
                setSoldOut(res.data.products.soldOut)
                setPublish(res.data.products.publish)
            }
        }).catch(err => {
            console.log(err)
        });
    }
    }

    useEffect(()=>{
        handleClick();
    },[productId])


    const handleOpenProductData = () =>{
        setShowProduct(!showProduct);
        setmainImage(KeepMainImage);
        setproductVideo(KeepproductVideo);
        setNewImageIndex();
        setNewImageURLs([]);
        setNewImages([]);
        setProductImageId([]);
        setNewMainImageURLs('');
        setNewVideoURLs('');
        setNewlyAddedImageURLs([]);
        setShowNewlyAddedImage(false);
        setNewColorImagesArray([]);
        setPreviewImagesByColor([]);
        handleClick();
        setshowCreateNewPreviewImages(false);
    }

    const handleDelete = () =>{
      setOpenDialog(true);
    }


    const handleMainImage = (e) => {
        if (!e.target.files[0]) {
            // User cancelled file selection, revert to the old main image
            return;
        }
        setmainImage(e.target.files[0]);
        const temporaryURL = URL.createObjectURL(e.target.files[0]);
        setNewMainImageURLs(temporaryURL);
    };

    const handleVideo = (e) => {
        if (!e.target.files[0]) {
            // User cancelled file selection, revert to the old main image
            return;
        }
        const file = e.target.files[0];
        console.log(file);
        const temporaryURL = URL.createObjectURL(file);
        if (file.type.startsWith("video/")) {
            setproductVideo(file);
            setNewVideoURLs(temporaryURL);
        }
    };


    const handleColorImageChange = (color, newImage) => {
        if (!newImage) {
            // User cancelled file selection, do not proceed
            return;
        }
        const newColorImageToAPI = {color: color, images: newImage};
        setNewColorImagesArray([...newColorImagesArray, newColorImageToAPI]);
        document.getElementById(`colorTextField-${color}`).value = '';
    };



    const deleteSelectedPreviewImage = (imageId) => {
        setproductImage((prevImages) => {
            const updatedProductImage = {};
            for (const [color, images] of Object.entries(prevImages)) {
                updatedProductImage[color] = images.filter((image) => image.productImageId !== imageId);
            }
            return updatedProductImage;
        });
        setProductImageId([...productImageId, { imageId: imageId }]);
    };

    const deletePreviewImage = (id) =>{
        const serverData = new FormData()
        serverData.append('productImageId', id)
        axios({
            method: "POST",
            url: delete_product_image,
            data: serverData,
        }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(false)
                setColor(false)
            } else {
                setOpen(true)
                setMessage(res.data.message)
                setStatus(true)
                setColor(true);
                deleteSelectedPreviewImage(id);
            }
        }).catch(err => {
            console.log(err)
        });
    }


    const DeleteNewlyAddedImage = (color, imageIndex) => {
        setNewColorImagesArray((prevColorImagesArray) => {
            const updatedImagesArray = prevColorImagesArray.map((colorObject) => {
                if (colorObject.color === color) {
                    const updatedImages = colorObject.images.filter(
                        (image, index) => index !== imageIndex
                    );
                    return { ...colorObject, images: updatedImages };
                }
                return colorObject;
            });
            return updatedImagesArray;
        });
    };



    const handleInsertImages = () => {
        newColorImagesArray.forEach(data => {
            const { color, images } = data;

            // Create FormData and append data
            const serverData = new FormData();
            serverData.append('productId', productId.id);
            serverData.append('productColor', color);
            images.forEach((image, index) => {
                serverData.append(`productImage[${index}]`, image);
            });

            // Make the API call for each element
            axios
                .post(add_product_image, serverData)
                .then(res => {
                    if (res.data.error) {
                        setMessage(res.data.message);
                        setOpen(true);
                        setStatus(false);
                        setColor(false);
                    } else {
                        setOpen(true);
                        setMessage(res.data.message);
                        setStatus(true);
                        setColor(true);
                        setNewColorImagesArray([]);
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        });
    };

    const CreateNewPreviewImages = () =>{
        setshowCreateNewPreviewImages(!showCreateNewPreviewImages);
    }

    const LocalStoredImage = () => {
        if (productColor !== '' && CreateNewproductImage.length !== 0) {
            const newObject = { color: productColor, Images: CreateNewproductImage };
            setPreviewImagesByColor((prevImages) => [...prevImages, newObject]);
            setProductColor("");
            document.getElementById('previewImage').value = '';
        }
    };

    const renderImages = () => {
        return PreviewImagesByColor.map((item, index) => (
            <Grid item xs={12} sm={12} md={12} lg={12} textAlign="center" display="flex" justifyContent="center" flexDirection="column" key={index}>
                <Box textAlign="left" px={2}>
                    <Typography sx={{ marginBottom: 1, textDecoration: 'underline' }} fontWeight={500} variant="subtitle1" py={2}>
                        {item.color} :
                    </Typography>
                    <Box display="flex" flexDirection="row" justifyContent="start" gap={2}>
                        {Array.from(item.Images).map((image, imageIndex) => {
                            // Check if createObjectURL method is supported
                            const imageUrl = typeof URL !== 'undefined' && typeof URL.createObjectURL === 'function' ? URL.createObjectURL(image) : '';
                            return (
                                <img
                                    key={imageIndex}
                                    src={imageUrl}
                                    alt={`Product Image ${imageIndex + 1}`}
                                    style={{ width: '80px', height: '80px', verticalAlign: 'middle' }}
                                />
                            );
                        })}
                        <Box>
                            <CloseIcon sx={{ verticalAlign: 'middle', my: 3 }} color="primary" fontSize="large" onClick={() => removeImageByColor(index)} />
                        </Box>
                    </Box>
                </Box>
            </Grid>
        ));
    };

    const removeImageByColor = (colorIndex) => {
        const updatedPreviewImages = [...PreviewImagesByColor];
        updatedPreviewImages.splice(colorIndex, 1);
        setPreviewImagesByColor(updatedPreviewImages);
    };


    const handleInsertNewPreviewImages = () => {
        PreviewImagesByColor.forEach(data => {
            const { color, Images } = data;

            // Create FormData and append data
            const serverData = new FormData();
            serverData.append('productId', productId.id);
            serverData.append('productColor', color);
            Images.forEach((image, index) => {
                serverData.append(`productImage[${index}]`, image);
            });

            // Make the API call for each element
            axios
                .post(add_product_image, serverData)
                .then(res => {
                    if (res.data.error) {
                        setMessage(res.data.message);
                        setOpen(true);
                        setStatus(false);
                        setColor(false);
                    } else {
                        setOpen(true);
                        setMessage(res.data.message);
                        setStatus(true);
                        setColor(true);
                        setPreviewImagesByColor([]);
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        });
    };

    const onSubmit = (data) => {
        setOpenBackdrop(true);
        setBackDropText('Updating Product Data....')
        handleInsertImages();
        const serverData = new FormData()
        for (var key in data) {
            serverData.append(key, data[key]);
        }
        for (let i = 0; i < newImages.length; i++) {
            serverData.append('productImage[]', newImages[i]);
        }
        serverData.append('categoryId', '');
        serverData.append('productId', productId)
        serverData.append('mainImage', mainImage);
        serverData.append('productVideo', productVideo);
        serverData.append('productPrice', productPrice);
        serverData.append('productMRP', MRPproductPrice);
        serverData.append('launchingSoon', LaunchingSoon);
        serverData.append('comboColor', ComboColor);
        serverData.append('colorCode', ColorCode);
        serverData.append('soldOut', SoldOut);
        serverData.append('publish', Publish);
        serverData.append('productImageId', JSON.stringify(productImageId));
        axios({
            method: "POST",
            url: update_product,
            data: serverData,
        }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(false)
                setColor(false)
                setOpenBackdrop(false);
            } else {
                setOpen(true)
                setMessage(res.data.message)
                setStatus(true)
                setColor(true)
                setShowProduct(false);
                setNewImageIndex()
                setNewImageURLs([])
                setNewImages([])
                setNewMainImageURLs('')
                setNewVideoURLs('');
                setNewlyAddedImageURLs([])
                setShowNewlyAddedImage(false);
                setOpenBackdrop(false);
                setshowCreateNewPreviewImages(false);
                setShowProduct(false);
                handleClick();
            }
        }).catch(err => {
            console.log(err)
        });
    }

    return (
        <Box p={2}>
            <Box p={2}>

                <SnackBar open={open} setOpen={setOpen} status={status} color={color} message={message} />

                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={openBackdrop}
                >
                    <Box display='flex' flexDirection='row' gap={2}>
                        <Box py={1}>
                            <Typography>{BackDropText}</Typography>
                        </Box>
                        <CircularProgress color="secondary" />
                    </Box>
                </Backdrop>

                <RemoveDialogBox 
                handleClick={handleClick}
                 setOpen={setOpen} 
                 setStatus={setStatus} 
                 setColor={setColor} 
                 setMessage={setMessage}
                  openDialog={openDialog} 
                  setOpenDialog={setOpenDialog} 
                  id={productId} 
                  name={productName} 
                  mainImage={mainImage} />


                <Box py={3} mt={1} display='flex' justifyContent='space-between' flexDirection={{lg:'row', md:'row', sm:'row', xs:'column'}}  gap={{xs:1, sm:0, md:0, lg:0}}>
                    <Heading title={'Edit & Remove Product'} />
                    <Box display='flex' justifyContent='end'>
                    <IconButton onClick={() => navigate(-1)} sx={{ width: 100, height: 40, border: '3px solid black', borderRadius: 1}}>
                    <Button sx={{ color: '#000000', fontWeight: 600, textTransform: 'none' }}><FastRewindOutlinedIcon sx={{ verticalAlign: 'middle' }} /> Back</Button>
                   </IconButton>
                    </Box>
            </Box>


         <form onSubmit={handleSubmit(onSubmit)} id='form1'>
        <Box component={Card} py={3} mt={1} boxShadow={5}>
         <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} >
        <Grid container display='flex' flexDirection={{ xs: 'column', sm: 'cloumn', md: 'row', lg: 'row' }} textAlign='left'>
        
        
        <Grid item xs={12} sm={12} md={12} lg={12}>
        <Box display='flex' justfiyContent='start' flexDirection='row' gap={3} p={2}>
            {
                !showProduct ? <>
                    <Button variant="contained" size='small' onClick={handleOpenProductData} fullWidth>EDIT</Button>
                    <Button variant="contained" size='small' onClick={handleDelete} fullWidth color='error'>DELETE</Button>
                </>
                    : null}
        </Box>
        </Grid>


            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box p={2}>
                    {
                        !showProduct ?
                            <Box display='flex' flexDirection={{ xs: 'column', sm: 'cloumn', md: 'row', lg: 'row' }} justifyContent='start' gap={3}>
                            <Typography variant='body1' fontWeight={600}>Product Name : </Typography>
                            <Typography variant='body1' sx={{ marginBottom: 2 }} color='#616e80'>{productName}</Typography>
                            </Box>
                            :
                            <Box display='flex' flexDirection='column' justifyContent='start' gap={3}>
                               <Typography variant='body1' fontWeight={600}>Product Name : </Typography>
                               <TextField
                                id="outlined-start-adornment"
                                size="small"
                                error={errors.productName ? true : false}
                                helperText={errors.productName ? "Product Name is required" : ""}
                                {...register("productName")} 
                                type='text'                               
                                defaultValue={productName}
                                sx={{width:'20ch'}}
                            />
                        </Box>
                    }
                </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box p={2}>
                    {
                        !showProduct ?
                            <Box display='flex' flexDirection='column' justifyContent='start' gap={3}>
                                <Typography variant='body1' fontWeight={600}>Product Description : </Typography>
                                <Typography variant='body1' sx={{ marginBottom: 2 }} color='#616e80'>{productDescription}</Typography>
                            </Box>
                            :
                               <Box display='flex' flexDirection='column' justifyContent='start' gap={3}>
                                <Typography variant='body1' fontWeight={600}>Product Description : </Typography>
                                <TextField
                                    id="outlined-start-adornment"
                                    size="small"
                                    type='text'
                                    error={errors.productDescription ? true : false}
                                    helperText={errors.productDescription ? "Product Description is required" : ""}
                                    {...register("productDescription")} 
                                    rows={5}
                                    multiline
                                    defaultValue={productDescription}
                                />
                            </Box>
                    }
                </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box p={2}>
                    {
                        !showProduct ?
                                <Box display='flex' flexDirection={{ xs: 'column', sm: 'cloumn', md: 'row', lg: 'row' }} justifyContent='start' gap={3}>
                                    <Typography variant='body1' fontWeight={600}>Product Qty : </Typography>
                                <Typography variant='body1' sx={{ marginBottom: 2 }} color='#616e80'>{productQty}</Typography>
                            </Box>
                            :
                            <Box display='flex' flexDirection='column' justifyContent='start' gap={3}>
                                <Typography variant='body1' fontWeight={600}>Product Qty : </Typography>
                                <TextField
                                    id="outlined-start-adornment"
                                    size="small"
                                    type='tel'
                                    defaultValue={productQty}
                                    error={errors.productQty ? true : false}
                                    helperText={errors.productQty ? "Product Qty is required" : ""}
                                    {...register("productQty")} 
                                    sx={{ width: '20ch' }}
                                />
                            </Box>
                    }
                </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box p={2}>
            {
            !showProduct ?
            <Box display='flex' flexDirection={{ xs: 'column', sm: 'cloumn', md: 'row', lg: 'row' }} justifyContent='start' gap={3}>
            <Typography variant='body1' fontWeight={600}>Product Weight : </Typography>
            <Typography variant='body1' sx={{ marginBottom: 2 }} color='#616e80'>{productWeight}</Typography>
            </Box>
            :
            <Box display='flex' flexDirection='column' justifyContent='start' gap={3}>
            <Typography variant='body1' fontWeight={600}>Product Weight : </Typography>
            <TextField
            defaultValue={productWeight}
            error={errors.productWeight ? true : false}
            helperText={errors.productWeight ? "Product Weight is required" : ""}
            {...register("productWeight")}
            size='small'
            type='text'
            sx={{ width: '20ch' }}
            InputProps={{
                endAdornment: <InputAdornment position="end">Gram</InputAdornment>,
            }}
            variant="outlined" />
            </Box>
            }
            </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box p={2}>
            {
            !showProduct ?
            <Box display='flex' flexDirection={{ xs: 'column', sm: 'cloumn', md: 'row', lg: 'row' }} justifyContent='start' gap={3}>
            <Typography variant='body1' fontWeight={600}>Product Length : </Typography>
            <Typography variant='body1' sx={{ marginBottom: 2 }} color='#616e80'>{productLength}</Typography>
            </Box>
            :
            <Box display='flex' flexDirection='column' justifyContent='start' gap={3}>
            <Typography variant='body1' fontWeight={600}>Product Length : </Typography>
            <TextField
            defaultValue={productLength}
            error={errors.productLength ? true : false}
            helperText={errors.productLength ? "Product Weight is required" : ""}
            {...register("productLength")}
            size='small'
            type='text'
            sx={{ width: '20ch' }}
            InputProps={{
                endAdornment: <InputAdornment position="end">Inch</InputAdornment>,
            }}
            variant="outlined" />
            </Box>
            }
            </Box>
            </Grid>


                <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box p={2}>
                {
                !showProduct ?
                <Box display='flex' flexDirection={{ xs: 'column', sm: 'cloumn', md: 'row', lg: 'row' }} justifyContent='start' gap={3}>
                <Typography variant='body1' fontWeight={600}>Product Width : </Typography>
                <Typography variant='body1' sx={{ marginBottom: 2 }} color='#616e80'>{productWidth}</Typography>
                </Box>
                :
                <Box display='flex' flexDirection='column' justifyContent='start' gap={3}>
                <Typography variant='body1' fontWeight={600}>Product Width : </Typography>
                <TextField
                defaultValue={productWidth}
                error={errors.productWidth ? true : false}
                helperText={errors.productWidth ? "Product Width is required" : ""}
                {...register("productWidth")}
                size='small'
                type='text'
                sx={{ width: '20ch' }}
                InputProps={{
                    endAdornment: <InputAdornment position="end">Inch</InputAdornment>,
                }}
                variant="outlined" />
                </Box>
                }
                </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box p={2}>
                {
                !showProduct ?
                <Box display='flex' flexDirection={{ xs: 'column', sm: 'cloumn', md: 'row', lg: 'row' }} justifyContent='start' gap={3}>
                <Typography variant='body1' fontWeight={600}>Product Height : </Typography>
                <Typography variant='body1' sx={{ marginBottom: 2 }} color='#616e80'>{productHeight}</Typography>
                </Box>
                :
                <Box display='flex' flexDirection='column' justifyContent='start' gap={3}>
                <Typography variant='body1' fontWeight={600}>Product Height : </Typography>
                <TextField
                defaultValue={productHeight}
                error={errors.productHeight ? true : false}
                helperText={errors.productHeight ? "Product Height is required" : ""}
                {...register("productHeight")}
                size='small'
                type='text'
                sx={{ width: '20ch' }}
                InputProps={{
                    endAdornment: <InputAdornment position="end">Inch</InputAdornment>,
                }}
                variant="outlined" />
                </Box>
                }
                </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box p={2}>
                {
                !showProduct ?
                <Box display='flex' flexDirection={{ xs: 'column', sm: 'cloumn', md: 'row', lg: 'row' }} justifyContent='start' gap={3}>
                <Typography variant='body1' fontWeight={600}>MRP Price : </Typography>
                 <Typography variant='body1' sx={{ marginBottom: 2 }} color='#616e80'>{MRPproductPrice}</Typography>
                </Box>
                :
                <Box display='flex' flexDirection='column' justifyContent='start' gap={3}>
                <Typography variant='body1' fontWeight={600}>MRP Price : </Typography>
                <TextField
                id="outlined-start-adornment"
                size="small"
                 value={MRPproductPrice}
                type="text"
                error={Boolean(error)}
                helperText={error}
                onChange={handleInputMRPPriceChange}
                sx={{ width: '20ch' }}
                />
                </Box>
                }
                </Box>
                </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box p={2}>
                    {
                        !showProduct ?
                                <Box display='flex' flexDirection={{ xs: 'column', sm: 'cloumn', md: 'row', lg: 'row' }} justifyContent='start' gap={3}>
                                    <Typography variant='body1' fontWeight={600}>Product Price : </Typography>
                                <Typography variant='body1'  sx={{ marginBottom: 2 }} color='#616e80'>{productPrice}</Typography>
                            </Box>
                            :
                            <Box display='flex' flexDirection='column' justifyContent='start' gap={3}>
                            <Typography variant='body1' fontWeight={600}>Product Price : </Typography>
                            <TextField
                            id="outlined-start-adornment"
                            size="small"
                            value={productPrice}
                            type="text"
                            error={Boolean(error)}
                            helperText={error}
                            onChange={handleInputPriceChange}
                            sx={{ width: '20ch' }}
                            />
                            </Box>
                    }
                </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box p={2}>
                    {
                        !showProduct ?
                                <Box display='flex' flexDirection={{ xs: 'column', sm: 'cloumn', md: 'row', lg: 'row' }} justifyContent='start' gap={3}>
                                    <Typography variant='body1' fontWeight={600}>Product Discount Percent : </Typography>
                                <Typography variant='body1' sx={{ marginBottom: 2 }} color='#616e80'>{productDiscountPercent}</Typography>
                            </Box>
                            :
                            <Box display='flex' flexDirection='column' justifyContent='start' gap={3}>
                            <Typography variant='body1' fontWeight={600}>Product Discount Percent : </Typography>
                                <TextField
                                    id="outlined-start-adornment"
                                    size="small"
                                    defaultValue={productDiscountPercent}
                                    type='text'
                                    error={errors.productDiscountPercent ? true : false}
                                    helperText={errors.productDiscountPercent ? "Discount is required" : ""}
                                    {...register("productDiscountPercent")} 
                                sx={{ width: '20ch' }}
                                />
                            </Box>
                    }
                </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box p={2}>
                    {
                        !showProduct ?
                            <Box display='flex' flexDirection={{ xs: 'column', sm: 'cloumn', md: 'row', lg: 'row' }} justifyContent='start' gap={3}>
                                <Typography variant='body1' fontWeight={600}>Product LeadTime : </Typography>
                                <Typography variant='body1' sx={{ marginBottom: 2 }} color='#616e80'>{productLeadTime}</Typography>
                            </Box>
                            :
                            <Box display='flex' flexDirection='column' justifyContent='start' gap={3}>
                            <Typography variant='body1' fontWeight={600}>Product LeadTime : </Typography>
                                <TextField
                                    id="outlined-start-adornment"
                                    size="small"
                                    defaultValue={productLeadTime}
                                    type='text'
                                    error={errors.productLeadTime ? true : false}
                                    helperText={errors.productLeadTime ? "Product Lead Time is required" : ""}
                                    {...register("productLeadTime")} 
                                sx={{ width: '20ch' }}
                                />
                            </Box>
                    }
                </Box>
            </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Box p={2}>
                                            {
                                                !showProduct ?
                                                    <Box display='flex' flexDirection={{ xs: 'column', sm: 'cloumn', md: 'row', lg: 'row' }} justifyContent='start' gap={3}>
                                                        <Typography variant='body1' fontWeight={600}>Launching Soon : </Typography>
                                                        <Typography variant='body1' sx={{ marginBottom: 2 }} color='#616e80'>{LaunchingSoon}</Typography>
                                                    </Box>
                                                    :
                                                    <Box display='flex' flexDirection='column' justifyContent='start' gap={3}>
                                                        <Typography variant='body1' fontWeight={600}>Launching Soon : </Typography>
                                                        <Autocomplete
                                                            disablePortal
                                                            id="combo-box-demo"
                                                            size='medium'
                                                            value={LaunchingSoon}
                                                            key={input}
                                                            onChange={(index, value) => setLaunchingSoon(value)}
                                                            options={['yes', 'no']}
                                                            renderInput={(params) => <TextField {...params} sx={{width: '20ch' }} required size='small' label="Launching Soon" />}
                                                        />
                                                    </Box>
                                            }
                                        </Box>
                                    </Grid>


                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Box p={2}>
                                            {
                                                !showProduct ?
                                                    <Box display='flex' flexDirection={{ xs: 'column', sm: 'cloumn', md: 'row', lg: 'row' }} justifyContent='start' gap={3}>
                                                        <Typography variant='body1' fontWeight={600}>Sold Out : </Typography>
                                                        <Typography variant='body1' sx={{ marginBottom: 2 }} color='#616e80'>{SoldOut}</Typography>
                                                    </Box>
                                                    :
                                                    <Box display='flex' flexDirection='column' justifyContent='start' gap={3}>
                                                        <Typography variant='body1' fontWeight={600}>Sold Out : </Typography>
                                                        <Autocomplete
                                                            disablePortal
                                                            id="combo-box-demo"
                                                            size='medium'
                                                            value={SoldOut}
                                                            key={input}
                                                            onChange={(index, value) => setSoldOut(value)}
                                                            options={['yes', 'no']}
                                                            renderInput={(params) => <TextField {...params} sx={{width: '20ch' }} required size='small' label="Sold Out" />}
                                                        />
                                                    </Box>
                                            }
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Box p={2}>
                                            {
                                                !showProduct ?
                                                    <Box display='flex' flexDirection={{ xs: 'column', sm: 'cloumn', md: 'row', lg: 'row' }} justifyContent='start' gap={3}>
                                                        <Typography variant='body1' fontWeight={600}>Combo Color : </Typography>
                                                        <Typography variant='body1' sx={{ marginBottom: 2 }} color='#616e80'>{ComboColor}</Typography>
                                                    </Box>
                                                    :
                                                    <Box display='flex' flexDirection='column' justifyContent='start' gap={3}>
                                                        <Typography variant='body1' fontWeight={600}>Combo Color : </Typography>
                                                        <Autocomplete
                                                            disablePortal
                                                            id="combo-box-demo"
                                                            size='medium'
                                                            value={ComboColor}
                                                            key={input}
                                                            onChange={(index, value) => setComboColor(value)}
                                                            options={['yes', 'no']}
                                                            renderInput={(params) => <TextField {...params} sx={{width: '20ch' }} required size='small' label="Combo Color" />}
                                                        />
                                                    </Box>
                                            }
                                        </Box>
                                    </Grid>


                                    {
                                        ComboColor === 'yes' && 
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Box p={2}>
                                            {
                                                !showProduct ?
                                                    <Box display='flex' flexDirection={{ xs: 'column', sm: 'cloumn', md: 'row', lg: 'row' }} justifyContent='start' gap={3}>
                                                        <Typography variant='body1' fontWeight={600}>Color Code : </Typography>
                                                        <Typography variant='body1' sx={{ marginBottom: 2 }} color='#616e80'>{ColorCode}</Typography>
                                                    </Box>
                                                    :
                                                    <Box display='flex' flexDirection='column' justifyContent='start' gap={3}>
                                                        <Typography variant='body1' fontWeight={600}>Color Code : </Typography>
                                                        <TextField
                                                            helperText={"Eg: 'Assorted' , 'Set Of 3'"}
                                                            onChange={(e)=>setColorCode(e.target.value)}
                                                            size='small'
                                                            type='text'
                                                            required
                                                            label="Color Code"
                                                            sx={{ width: '20ch' }}
                                                            variant="outlined" />
                                                    </Box>
                                            }
                                        </Box>
                                    </Grid>
                                        }


                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Box p={2}>
                                            {
                                                !showProduct ?
                                                    <Box display='flex' flexDirection={{ xs: 'column', sm: 'cloumn', md: 'row', lg: 'row' }} justifyContent='start' gap={3}>
                                                        <Typography variant='body1' fontWeight={600}>Publish : </Typography>
                                                        <Typography variant='body1' sx={{ marginBottom: 2 }} color='#616e80'>{Publish}</Typography>
                                                    </Box>
                                                    :
                                                    <Box display='flex' flexDirection='column' justifyContent='start' gap={3}>
                                                        <Typography variant='body1' fontWeight={600}>Publish : </Typography>
                                                        <Autocomplete
                                                            disablePortal
                                                            id="combo-box-demo"
                                                            size='medium'
                                                            value={Publish}
                                                            key={input}
                                                            onChange={(index, value) => setPublish(value)}
                                                            options={['yes', 'no']}
                                                            renderInput={(params) => <TextField {...params} sx={{width: '20ch' }} required size='small' label="Publish" />}
                                                        />
                                                    </Box>
                                            }
                                        </Box>
                                    </Grid>






        <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box p={2}>
                {
                    !showProduct ?
                        <Box display='flex' flexDirection='column' justifyContent='start' gap={3}>
                        <Typography variant='body1' fontWeight={600}>Product Main Image: </Typography>
                        {mainImage && <img style={{ width: 100, height: 100 }} alt="mainImage" src={`${BaseImageAPI}${mainImage}`} />}
                        </Box>
                        :
                    <Box display='flex' flexDirection='column' justifyContent='start' gap={2}>
                     <Typography variant='body1' fontWeight={600}>Product Main Image: </Typography>  
                    <Box display='flex' flexDirection='row' gap={2}>   
                    {newMainImageURLs ? (
                        <img style={{ width: 100, height: 100 }} alt="mainImage" src={newMainImageURLs} />
                    ) : (
                        mainImage && (
                            <img style={{ width: 100, height: 100 }} alt="mainImage" src={`${BaseImageAPI}${mainImage}`} />
                        )
                    )}
                    <IconButton
                      aria-label="upload"
                     component="label"
                     sx={{ ':hover': { backgroundColor: 'transparent' } }}
                     >
                    <EditIcon color="primary" sx={{verticalAlign:'middle'}} />
                    <TextField
                     hidden
                     id="profilePicUpload"
                    type="file"
                     onChange={handleMainImage}
                    />
                 </IconButton>
                    </Box>
                    </Box>
                }
            </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
        <Box p={2}>
        {
        !showProduct ?
        <Box display='flex' flexDirection='column' justifyContent='start' gap={3}>
        <Typography variant='body1' fontWeight={600}>Product Video: </Typography>
        {productVideo && <video controls style={{ height: 100, width: 200 }}>
        <source src={`${BaseImageAPI}${productVideo}`} type="video/mp4" />
        Your browser does not support the video tag.
        </video>
        }
        </Box>
        :
        <Box display='flex' flexDirection='column' justifyContent='start' gap={2}>
        <Typography variant='body1' fontWeight={600}>Product Video: </Typography>
        <Box display='flex' flexDirection='row' gap={2}>
        {
        newVideoURLs ? (
        <video controls style={{ height: 100, width: 200 }}>
        <source src={newVideoURLs} type="video/mp4" />
        Your browser does not support the video tag.
        </video>
        ) :
        productVideo &&
        (
        <video controls style={{ height: 100, width: 200 }}>
        <source src={`${BaseImageAPI}${productVideo}`} type="video/mp4" />
        Your browser does not support the video tag.
        </video>
        )
        }
        <IconButton
        aria-label="upload"
        component="label"
        sx={{ ':hover': { backgroundColor: 'transparent' } }}
        >
        <EditIcon color="primary" sx={{ verticalAlign: 'middle' }} />
        <TextField
        hidden
        id="videoUpload"
        type="file"
        onChange={handleVideo}
        />
        </IconButton>
        </Box>
        </Box>
        }
        </Box>
        </Grid>



         <Grid item xs={12} sm={12} md={12} lg={12}>
        <Box p={2} display='flex' justifyContent='space-between'>
        <Typography variant={showProduct ? 'h6': 'body1'} fontWeight={600} py={2}>Product Preview Image</Typography>
        {
        showProduct && <Button onClick={CreateNewPreviewImages}>Create New Preview Images</Button>
        }
    
        </Box>
         
         {showProduct && showCreateNewPreviewImages &&
        <Grid item xs={12} sm={12} md={12} lg={12}>
        <Box p={2}>
        <Typography fontWeight={600} textAlign='left' variant='subtitle1' color='#616e80' sx={{ textDecoration: 'underline' }} >Create New Preview Images</Typography>
        </Box>
        </Grid>
         }


     {showCreateNewPreviewImages &&
       <Box component={Paper} mx={3} my={1} boxShadow={2}>
        <Grid container display='flex' flexWrap='wrap' flexDirection={{ xs: 'column', sm: 'row', md: 'row', lg: 'row' }}>

        <Grid item xs={12} sm={4} md={4} lg={4}>
        <TextField
        onChange={(e) => setProductColor(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
        size='small'
        type='text'
        label="Product Color"
        value={productColor}
        sx={{ m: 2 }}
        variant="outlined" />
        </Grid>


        <Grid item xs={12} sm={4} md={4} lg={4}>
        <TextField
        size="small"
        type="file"
        id="previewImage"
        multiple
        onChange={(e) => {
        const files = Array.from(e.target.files);
        setCreateNewproductImage(files);
        }}
        label="Product Preview Images"
        sx={{ m: 2 }}
        helperText="(Upload at least 3 Images)"
        variant="outlined"
        InputLabelProps={{
        shrink: true,
        }}
        inputProps={{
        multiple: true,
        accept: 'image/*', // Specify the file types to accept
        }}
        />
        </Grid>

        <Grid item xs={12} sm={4} md={4} lg={4}>
        <Button variant='contained' color='primary' sx={{ m: 2, width: '15ch' }} onClick={LocalStoredImage}>Save</Button>
        </Grid>

        <Grid container>
        <Box py={2}>
        {renderImages()}
        </Box>
        </Grid>
    
        <Grid item xs={12} sm={12} md={12} lg={12} p={2}>
        <Button variant='contained' fullWidth color='warning' onClick={handleInsertNewPreviewImages}>Create New Images</Button>
        </Grid>
        </Grid>
        </Box>
    }

    {
    showProduct &&
    <Grid item xs={12} sm={12} md={12} lg={12}>
    <Box p={2}>
    <Typography fontWeight={600} textAlign='left' variant='subtitle1' sx={{ textDecoration: 'underline' }} color='#616e80'>Edit Existing Images</Typography>
    </Box>
    </Grid>
    }

        <Grid>
        <Box component={Paper} mx={3} my={1} boxShadow={2}>
            {Object.entries(productImage).map(([color, images] , Colorindex) => (
                <>
            <Grid item xs={12} sm={12} md={12} lg={12} key={color} py={2} borderBottom={Colorindex !== Object.entries(productImage).length - 1 ? '1px solid silver' : ''}>
            <Typography variant='subtitle1' px={2} fontWeight={600} color='#616e80'>{color}</Typography>
             <Box display="flex" flexDirection="row" gap={1} flexGrow={1} flexWrap="wrap">
            {images.map((image, index) => (
            <Box p={2} mx={1} key={index}>
            {!showProduct ? (
            <Box>
            <img style={{ width: 100, height: 100 }} alt="mainImage" src={`${BaseImageAPI}${image.productImage}`} />
            </Box>
            ) : (
            <Box display="flex" flexDirection="row" gap={1} flexGrow={1} flexWrap="wrap">
            <Box display="flex" flexDirection="column" gap={1}>
            <img
            style={{ width: 100, height: 100 }}
            alt="mainImage"
            src={`${BaseImageAPI}${image.productImage}`}
            />
            <Box>
            <IconButton>
            <DeleteForeverIcon color="error" sx={{ verticalAlign: 'middle' }} onClick={() => deletePreviewImage(image.productImageId)} />
            </IconButton>
            </Box>
            </Box>
            </Box>
            )}
            </Box>
            ))}
            </Box>

            <Box display="flex" flexDirection="row" gap={1}>
            {newColorImagesArray.map((colorObject, index) => {
            if (colorObject.color === color) {
            return (
            <Box display="flex" flexDirection="row" gap={1}>
            {colorObject.images.map((newImage, newImageIndex) => {
            const imageUrl = typeof URL !== 'undefined' && typeof URL.createObjectURL === 'function' ? URL.createObjectURL(newImage) : '';
            return (
            <Box p={2} key={newImageIndex}>
            {/* Use object URL for new image */}
            <img style={{ width: 100, height: 100 }} alt="mainImage" src={imageUrl} />
            <Box>
            <IconButton>
            <DeleteForeverIcon onClick={() =>
                DeleteNewlyAddedImage(color, newImageIndex)
            } color="error" sx={{ verticalAlign: 'middle' }} />
            </IconButton>
            </Box>
            </Box>
            )})}
            </Box>
            );
            }
            return null;
            })}
            </Box>

            {showProduct && (
            <Box mt={2} p={2} display="flex" alignItems="center">
            <TextField
            size="small"
            id={`colorTextField-${color}`}
            label={`Image for ${color}`}
            type="file"
            multiple
            onChange={(e) => handleColorImageChange(color, Array.from(e.target.files))}
            InputLabelProps={{
                shrink: true,
            }}
            inputProps={{
                multiple: true,
            }}
            />
            </Box>
            )}
            </Grid>
            </>
            ))}
            </Box>
         </Grid>
      
    </Grid>


            {/* Buttons */}
              
              {
                showProduct && 
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box p={2} display='flex' flexDirection='row' gap={2}>
                            <Button variant='contained' fullWidth onClick={handleOpenProductData}
                                sx={{ m: 2, bgcolor: 'red' }}>
                                CANCEL</Button>

                                <Button variant='contained' fullWidth form='form1' type='submit'
                                sx={{ m: 2, bgcolor: '#faa634', ':hover': { bgcolor: '#316C31' } }}>
                                UPDATE</Button>
                                
                        </Box>
                    </Grid>
              }

             </Grid>
             </Grid>
             </Grid>
        </Box>
        </form>


{/* End Box */}
        </Box>
        </Box>
    )
}
