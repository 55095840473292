import React, { useEffect, useState } from 'react'
import { Container, Grid, Button, Box, Typography, Card, Dialog, DialogActions, DialogContent, DialogTitle, Stack, } from '@mui/material';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import { add_expense_category, get_expense_category, update_expense_category } from '../../Services/APIService';

export default function ExpenseCategroyDialog({ setExpenseCategoryId, ExpenseCategoryId ,  getAllExpense, setMessage, setOpen, setStatus, setColor, openExpenseDialog, setOpenExpenseDialog }) {

    const [expenseCategory, setexpenseCategory] = useState('');

    const handleClose = () =>{
        setOpenExpenseDialog(false);
        setExpenseCategoryId('');
        setexpenseCategory('');
        setExpenseCategoryId('');
    }

        const getExpenseById = () => {
            if (ExpenseCategoryId !== '' && ExpenseCategoryId !== null){
        const sendData = new FormData();
                sendData.append('expenseCategoryId', ExpenseCategoryId);
        axios({
        method: "POST",
        url: get_expense_category,
        data: sendData
        }).then(res => {
        if (res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(false)
        setColor(false)
        } else {
        setOpen(true)
        setMessage(res.data.message)
        setStatus(true)
        setColor(true)
        setexpenseCategory(res.data.data.expenseCategory);
        }
        }).catch(err => {
        console.log(err)
        });
        }
        else{
        setexpenseCategory('')
        }
        }

        useEffect(() => {
        getExpenseById();
        }, [ExpenseCategoryId]);


        const handleAddExpense = () => {
        if (ExpenseCategoryId !== '' && ExpenseCategoryId !== null) {
        const sendData = new FormData();
        sendData.append('expenseCategory', expenseCategory);
        sendData.append('expenseCategoryId', ExpenseCategoryId);
        axios({
        method: "POST",
        url: update_expense_category,
        data: sendData
        }).then(res => {
        if (res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(false)
        setColor(false)
        } else {
        setOpen(true)
        setMessage(res.data.message)
        setStatus(true)
        setColor(true)
        handleClose();
        getAllExpense();
        setexpenseCategory('');
        setExpenseCategoryId('');
        }
        }).catch(err => {
        console.log(err)
        });
        }
        else {
        const sendData = new FormData();
        sendData.append('expenseCategory', expenseCategory);
        axios({
        method: "POST",
        url: add_expense_category,
        data: sendData
        }).then(res => {
        if (res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(false)
        setColor(false)
        } else {
        setOpen(true)
        setMessage(res.data.message)
        setStatus(true)
        setColor(true)
        handleClose();
        getAllExpense();
        }
        }).catch(err => {
        console.log(err)
        });
        }}

  return (
      <Dialog onClose={handleClose} open={openExpenseDialog}>
          <DialogTitle>
            CREATE NEW EXPENSE CATEGORY
          </DialogTitle>
          <DialogContent>
              <Box display='flex' flexDirection='row' gap={2} p={3}>
                  <TextField
                      id="outlined-basic"
                      size='small'
                      label='Expense Category'
                      value={expenseCategory}
                      fullWidth
                      onChange={(e) => setexpenseCategory(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                      variant="outlined"
                  />
              </Box>
          </DialogContent>
          <DialogActions>
              <Button onClick={handleClose} variant='outlined' color='error'>CLOSE</Button>
              <Button onClick={handleAddExpense} variant='outlined' color='success'>{ExpenseCategoryId !== '' && ExpenseCategoryId !== null ? 'UPDATE' : 'SAVE'}</Button>
          </DialogActions>
      </Dialog>
  )
}
