import React, { useEffect, useState } from "react";
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import axios from "axios";
import { get_all_contractor, get_all_supplier } from "../../Services/APIService";


export default function VendorInquiry() {

    const [data , setData] = useState([]);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [status, setStatus] = useState();
    const [color, setColor] = useState();

    useEffect(() => {
        axios({
            method: 'GET',
            url: get_all_supplier,
        }).then(res => {
            if (!res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(false)
                setColor(false)
                setData(res.data.data)
            } else {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(true)
                setColor(true)
            }
        }).catch(err => {
            alert('Oops something went wrong ' + err)
        });
    }, [])

  return (
    <Box>
          <TableContainer component={Paper}>
              <Table>
                  <TableHead>
                      <TableRow>
                          <TableCell>S.No</TableCell>
                          <TableCell>Contact Person</TableCell>
                          <TableCell>Address</TableCell>
                          <TableCell>Email</TableCell>
                          <TableCell>Mobile</TableCell>
                          <TableCell>Website URL</TableCell>
                          <TableCell>Product Nature</TableCell>
                          <TableCell>Factory Location</TableCell>
                          <TableCell>Reference</TableCell>
                          <TableCell>Project Type</TableCell>
                          <TableCell>Product Details</TableCell>
                      </TableRow>
                  </TableHead>
                  <TableBody>
                      {data.map((item , index) => (
                          <TableRow key={index}>
                              <TableCell>{index + 1}</TableCell>
                              <TableCell>{item.supplierContactPerson}</TableCell>
                              <TableCell>{item.supplierAddress}</TableCell>
                              <TableCell>{item.supplierMail}</TableCell>
                              <TableCell>{item.supplierMobile}</TableCell>
                              <TableCell>{item.websiteURL}</TableCell>
                              <TableCell>{item.productNature}</TableCell>
                              <TableCell>{item.factoryLocation}</TableCell>
                              <TableCell>{item.reference}</TableCell>
                              <TableCell>{item.projectType}</TableCell>
                              <TableCell>{item.productDetails}</TableCell>
                          </TableRow>
                      ))}
                  </TableBody>
              </Table>
          </TableContainer>
    </Box>
  )
}
