import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Stack, Typography } from '@mui/material';
import { Grid } from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

export default function ReportDialog({setOpenDialog,getAllExpense, getAllWebOrders , fromDate , toDate , setFromDate , setToDate , setMessage, setOpen, setStatus, setColor,openDialog , selectedReport}) {

    const handleClose = () => {
      setOpenDialog(false);
    };

    const GenerateReport = async () => {
      if (fromDate !== '' && toDate !== '') {
        if (selectedReport === 'Expense') {
           getAllExpense();
          }
          if(selectedReport === 'Web Orders'){
            getAllWebOrders()
          }
      }
    };
  


  return (
    <Dialog open={openDialog} onClose={handleClose} fullWidth maxWidth='sm'>
    <DialogTitle>
    <Box p={1} borderBottom='1px solid rgba(0,0,0,.1)'>
    {selectedReport} Report
    </Box>
   </DialogTitle>
    <DialogContent>
    <Stack textAlign='left'>
    <Grid container justifyContent='center'>
     <Grid item xs={12} lg={2} justifyContent='start' display='flex'>
     <Stack spacing={4} textAlign='left'>
     <Typography py={1}>From</Typography>
     <Typography py={1}>To</Typography>
     </Stack>
     </Grid>
     <Grid item xs={12} lg={8}  justifyContent='start'>
     <Stack spacing={4} textAlign='left'>
     <TextField
     type="date"
     id="start" 
     size='small'
     name="trip-start"
    fullWidth
     onChange={(e) => {
     setFromDate(e.target.value);
     }}
     required
     InputLabelProps={{
       shrink: true,
     }}
       />

       <TextField
       type="date"
       id="end" 
       size='small'
       name="trip-end"
       fullWidth
       onChange={(e) => {
       setToDate(e.target.value);
       }}
       required
       InputLabelProps={{
         shrink: true,
       }}
         />
     </Stack>
     </Grid>
    </Grid>
   </Stack>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color='error' variant='contained'>Cancel</Button>
      <Button
      variant="contained"
      color='warning'
      onClick={GenerateReport}
      endIcon={<ExitToAppIcon />}
    >
    Generate Report
    </Button>
    </DialogActions>
  </Dialog>
  )
}
