import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Typography } from '@mui/material'
import { CategoryImageAPI, delete_main_category} from '../../Services/APIService';
import axios from 'axios';
import { useState } from 'react';

export default function DeleteDialog({setOpenDialog,  openDialog , i , getallcategoryAPI}) {

    
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [status, setStatus] = useState();
    const [color, setColor] = useState();

   const handleClose = () => {
    setOpenDialog(false);
    };

  const deleteCategory = (categoryId) => {
         const serverData = new FormData()
        serverData.append('mainCategoryId', categoryId)
        axios({
            method: "POST",
            url: delete_main_category,
            data: serverData,
        }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(false)
                setColor(false)
            } else {
                setOpen(true)
                setMessage(res.data.message)
                setStatus(true)
                setColor(true);
                getallcategoryAPI();
                handleClose();
            }
        }).catch(err => {
            console.log(err)
        });
        };

        
  return (
    <div>
        <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        >
        <DialogTitle id="alert-dialog-title" sx={{fontSize:17}}>
        {"ARE YOU SURE THAT YOU WANT TO DELETE THE CATEGORY PERMANENTLY ?"}
        </DialogTitle>
        <DialogContent>
        <DialogContentText id="alert-dialog-description">
        <Box display='flex' flexDirection='column' gap={2}> 
        <Typography component='span'>Category</Typography> <img style={{ width: 100, height: 100 }}src={`${CategoryImageAPI}${i.categoryImage}`} alt='Image' />
        <Typography>Selected CategoryId : <Typography component='span' color='#333' fontWeight={600}> {i.categoryId}</Typography>  </Typography>
        <Typography> Selected CategoryName : <Typography component='span' color='#333' fontWeight={600}> {i.categoriesName}</Typography>  </Typography>
        </Box>                                             
        </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button onClick={()=>deleteCategory(i.categoryId)}>YES</Button>
        <Button onClick={handleClose} autoFocus>
        NO
        </Button>
        </DialogActions>
        </Dialog>
    </div>
  )
}
