import { get_dashboard_data } from '../../Services/APIService';
import axios from 'axios';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import LocalGroceryStoreOutlinedIcon from '@mui/icons-material/LocalGroceryStoreOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import NextPlanOutlinedIcon from '@mui/icons-material/NextPlanOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Box, Typography, Card, Grid, Stack, TextField } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom';

export default function Stats() {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [status, setStatus] = useState();
    const [color, setColor] = useState();
    const [data , setData] = useState([]);
    const[currentMode , setCurentMode] = useState('Today');
    const [weekNumber , setWeekNumber] = useState('');
    const [fromDate , setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const[yearnumber , setYearNumber] = useState('');
    const [showWeek , setShowWeek ] = useState(false);
    const [showYear , setShowYear ] = useState(false);
    const [showMonth , setShowMonth ] = useState(false);
  
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);
    const textFieldRef = useRef();
    const textFieldYearRef = useRef();
    const textFieldWeekRef = useRef();
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    useEffect(() => {
      const sendData = new FormData()
      sendData.append('checkBox', currentMode);
  
       axios({
        method: 'POST',
         url: get_dashboard_data,
         data: sendData,
    }).then(res => {
        if (res.data.error) {
            setMessage(res.data.message)
            setOpen(true)
            setStatus(false)
            setColor(false)
        } else {
            setOpen(true)
            setMessage(res.data.message)
            setStatus(true)
            setColor(true)
            setData(res.data.data)
        }
    }).catch(err => {
        console.log(err)
    });
    }, [])
  
  
    const getFilteredYearData = (year) =>{
      const sendData = new FormData()
      sendData.append('checkBox', 'Yearly');
      sendData.append('years', year !== undefined ? year : '');
       axios({
        method: 'POST',
         url: get_dashboard_data,
         data: sendData,
    }).then(res => {
        if (res.data.error) {
            setMessage(res.data.message)
            setOpen(true)
            setStatus(false)
            setColor(false)
        } else {
            setOpen(true)
            setMessage(res.data.message)
            setStatus(true)
            setColor(true)
            setData(res.data.data)
        }
    }).catch(err => {
        console.log(err)
    });
    }
  
  
    const getFilteredWeekData = (week) =>{
      const sendData = new FormData()
      sendData.append('checkBox', 'Weekly');
      sendData.append('weekNumber', week !== undefined ? week : '');
       axios({
        method: 'POST',
         url: get_dashboard_data,
         data: sendData,
    }).then(res => {
        if (res.data.error) {
            setMessage(res.data.message)
            setOpen(true)
            setStatus(false)
            setColor(false)
        } else {
            setOpen(true)
            setMessage(res.data.message)
            setStatus(true)
            setColor(true)
            setData(res.data.data)
        }
    }).catch(err => {
        console.log(err)
    });
    }
  

  
    const getFilteredMonthData = () =>{
      const sendData = new FormData()
      sendData.append('checkBox', 'Monthly');
      sendData.append('fromDate', fromDate);
      sendData.append('toDate', textFieldRef?.current?.value !== undefined ? textFieldRef?.current?.value : '');
       axios({
        method: 'POST',
         url: get_dashboard_data,
         data: sendData,
    }).then(res => {
        if (res.data.error) {
            setMessage(res.data.message)
            setOpen(true)
            setStatus(false)
            setColor(false)
        } else {
            setOpen(true)
            setMessage(res.data.message)
            setStatus(true)
            setColor(true)
            setData(res.data.data)
        }
    }).catch(err => {
        console.log(err)
    });
    }
  
    const ChangeMode = (mode) =>{
      setCurentMode(mode);
      if (mode === 'Yearly'){
        setShowYear(true);
        setShowWeek(false);
        setShowMonth(false);
        getFilteredYearData('');
        handleClose();
      }
      else if (mode === 'Weekly'){
       setShowWeek(true);
      setShowMonth(false);
      setShowYear(false);
      getFilteredWeekData('');
      handleClose();
      }
      else if (mode === 'Monthly'){
        setShowMonth(true);
        setShowWeek(false);
        setShowYear(false);
        getFilteredMonthData();
        handleClose();
      }
    }

  

  return (
    <Box mb={3}>
      

    <Box textAlign='left' py={1} mb={1} display='flex' flexDirection={{xs:'column' , sm:'row', md:'row', lg:'row'}} justifyContent='space-between' gap={{lg:0, md:0 ,sm:0 , xs:1}}>
    <Box sx={{cursor:'pointer'}}>
    <Typography variant='h6' sx={{color:'#faa634' , cursor:'pointer'}} onClick={handleClick}>{currentMode === 'Today' ? "Today's" : currentMode} <ExpandMoreIcon sx={{verticalAlign:'middle', mr:0.2, cursor:'pointer'}} />Stats</Typography>
    <Menu
    id="basic-menu"
    anchorEl={anchorEl}
    open={openMenu}
    onClose={handleClose}
    MenuListProps={{
      'aria-labelledby': 'basic-button',
    }}
  >
    <MenuItem onClick={()=>ChangeMode('Weekly')}>Weekly</MenuItem>
    <MenuItem onClick={()=>ChangeMode('Monthly')}>Monthly</MenuItem>
    <MenuItem onClick={()=>ChangeMode('Yearly')}>Yearly</MenuItem>
  </Menu>
  </Box>
  <Box>
  {
  showWeek &&
  <input
  type="week"
  inputRef={textFieldWeekRef}
  name="week" 
  onChange={(e) => {
    const fullWeekValue = e.target.value; // e.g., "2018-W24"
    const weekNumber = fullWeekValue.replace('-W', ''); // Remove "-W"
    setWeekNumber(weekNumber);
    getFilteredWeekData(weekNumber);
  }}  
  id="camp-week"
  min="2018-W18"
  required
  className="MuiInputBase-input MuiInput-input"
  />
  }
  {
    showMonth &&
    <Box display='flex' flexDirection='row' gap={2}>
    <TextField
    type="date"
    id="start" 
    size='small'
    name="trip-start"
    onChange={(e) => {
    setFromDate(e.target.value);
    }}
    label='From date'
    required
    InputLabelProps={{
      shrink: true,
    }}
      />

      <TextField
      type="date"
      id="end" 
      size='small'
      name="trip-end"
      inputRef={textFieldRef}
      onChange={(e) => {
      setToDate(e.target.value);
        getFilteredMonthData();
      }}
      label='To date'
      required
      InputLabelProps={{
        shrink: true,
      }}
        />
   </Box>
  }

  {
    showYear && 
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <DatePicker
      views={['year']}
      label="Year"
      openTo='year'
      slotProps={{ textField: { size: 'small' } }}
      inputRef={textFieldYearRef}
      dateFormat="yyyy"
      onChange={(newValue) => {
        const date = new Date(newValue);
        const YearDate = date.getFullYear();
        setYearNumber(YearDate);
        getFilteredYearData(YearDate);
      }}
      renderInput={(params) => <TextField 
        {...params} 
        helperText={null}
         inputProps={{
          ...params.inputProps,
         readOnly: true
        }} size='small' />}
    />
    </LocalizationProvider>
  }
  </Box>
    </Box>  
    <Grid container spacing={{xs:1 , sm:2, md:3, lg:4}} justifyContent={{xs:'start' , sm:'center', md:'center', lg:'center'}} flexWrap='wrap'>

    <Grid item xs={6} sm={2} md={2} lg={2}>
       <Box component={Card} boxShadow={4} >
      <Box display='flex' flexDirection='column' justifyContent='center' alignContent='center' p={2}>
      <Box mt={1}>
      <MonetizationOnOutlinedIcon fontSize='large' sx={{color:'#faa634'}} />
      </Box>
      <Box  mt={2}>
      <Typography variant='body2' color='#616e80'>EARNINGS</Typography>
        </Box>
      <Box>
      <Typography variant='subtitle1' sx={{color:'#faa634'}} >{data?.totalEarnings}</Typography>
      </Box>
      </Box>
      </Box>
      </Grid>

      <Grid item xs={6} sm={2} md={2} lg={2}>
      <Box component={Card} boxShadow={4} onClick={()=>navigate('/dashbrdlayout/orders')}>
      <Box display='flex' flexDirection='column' justifyContent='center' alignContent='center' p={2}>
      <Box mt={1}>
      <LocalGroceryStoreOutlinedIcon fontSize='large' sx={{color:'#faa634'}} />
      </Box>
      <Box  mt={2}>
      <Typography variant='body2' color='#616e80'>ORDERS</Typography>
        </Box>
      <Box>
      <Typography variant='subtitle1' sx={{color:'#faa634'}} >{data?.totalOrders}</Typography>
      </Box>
      </Box>
      </Box>
      </Grid>

      <Grid item xs={6} sm={2} md={2} lg={2}>
      <Box component={Card} boxShadow={4} onClick={()=>navigate('/dashbrdlayout/allproductspage')}>
      <Box display='flex' flexDirection='column' justifyContent='center' alignContent='center' p={2}>
      <Box mt={1}>
      <Inventory2OutlinedIcon fontSize='large' sx={{color:'#faa634'}} />
      </Box>
      <Box  mt={2}>
      <Typography variant='body2' color='#616e80'>PRODUCTS</Typography>
        </Box>
      <Box>
      <Typography variant='subtitle1' sx={{color:'#faa634'}} >{data?.totalProducts}</Typography>
      </Box>
      </Box>
      </Box>
      </Grid>


      <Grid item xs={6} sm={2} md={2} lg={2}>
 <Box component={Card} boxShadow={4} onClick={()=>navigate('/dashbrdlayout/users')}>
      <Box display='flex' flexDirection='column' justifyContent='center' alignContent='center' p={2}>
      <Box mt={1}>
      <AccountCircleOutlinedIcon fontSize='large' sx={{color:'#faa634'}} />
      </Box>
      <Box  mt={2}>
      <Typography variant='body2' color='#616e80'>USERS</Typography>
        </Box>
      <Box>
      <Typography variant='subtitle1' sx={{color:'#faa634'}} >{data?.totalUsers}</Typography>
      </Box>
      </Box>
      </Box>
      </Grid>


      <Grid item xs={6} sm={2} md={2} lg={2}>
      <Box component={Card} boxShadow={4} onClick={()=>navigate('/dashbrdlayout/returnsale')}>
      <Box display='flex' flexDirection='column' justifyContent='center' alignContent='center' p={2}>
      <Box mt={1}>
      <NextPlanOutlinedIcon fontSize='large' sx={{color:'#faa634'}} />
      </Box>
      <Box  mt={2}>
      <Typography variant='body2' color='#616e80'>RETURNS</Typography>
        </Box>
      <Box>
      <Typography variant='subtitle1' sx={{color:'#faa634'}} >{data?.totalreturns}</Typography>
      </Box>
      </Box>
      </Box>
      </Grid>




      </Grid>
      </Box>
  )
}
