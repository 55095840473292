import { Container , Box,  Grid, IconButton, TextField, Button, Typography } from '@mui/material'
import React, { useState } from 'react'
import EditIcon from '@mui/icons-material/Edit';
import { update_home_page_image, delete_home_page_image, add_home_page_images } from '../../../Services/APIService';
import axios from 'axios';
import DeleteIcon from '@mui/icons-material/Delete';
import Heading from '../../../Components/Heading/Heading';
import SnackBar from "../../../Components/AdminDashBoardComps/Snackbar";
export default function BigSlider({ HomeSilder, getAllData , setHomeSlider }) {
 
  const [editMode, setEditMode] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [newImage, setNewImage] = useState(null);

  const [title , setTitle] = useState('');
  const [section, setSection] = useState('HomePageSlider');
  const [content, setContent] = useState('');
  const [imageId , setImageId] = useState('');
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [color, setColor] = useState();

  const handleEditClick = (id) => {
    setImageId(id);
    setEditMode(true);
  };


  const handleImageChange = (event) => {
    const newImage = event.target.files[0];
    setSelectedImage(newImage);
  };


  const handleClick = () => {
    const serverData = new FormData()
    serverData.append("title", title)
    serverData.append("content", content)
    serverData.append("section", section)
    serverData.append("homePageImageId", imageId)
    serverData.append("images", selectedImage)
    axios({
      method: "POST",
      url: update_home_page_image,
      data: serverData,
    }).then(res => {
      if (!res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        setTitle('');
        setSection('');
        setContent('');
        setSelectedImage('');
        setImageId('');
        getAllData();
      } else {
        setOpen(true)
        setStatus(false)
        setColor(false)
        setMessage(res.data.message)
      }
    }).catch(err => {
      console.log(err)
    });
  }

  const Cancel = () =>{
    setSelectedImage(null);
    setEditMode(false);
  }

  const DeleteData = (Imageid) => {
    if (Imageid !== "") {
      const sendData = new FormData()
      sendData.append('homePageImageId', Imageid);
      axios({
        method: 'POST',
        url: delete_home_page_image,
        data: sendData,
      }).then(res => {
        if (!res.data.error) {
          setMessage(res.data.message)
          setOpen(true)
          setStatus(true)
          setColor(true)
          getAllData();
        } else {
          setOpen(true)
          setStatus(false)
          setColor(false)
          setMessage(res.data.message)
        }
      }).catch(err => {
        alert('Oops something went wrong ' + err)
      });
    }
  }

  const AddNew = () =>{
    const serverData = new FormData()
    serverData.append("title", title)
    serverData.append("content", content)
    serverData.append("section", section)
    serverData.append("category", '')
    serverData.append("isEnable", 1)
    serverData.append("images", newImage)
    axios({
      method: "POST",
      url: add_home_page_images,
      data: serverData,
    }).then(res => {
      if (!res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        setNewImage(null);
        document.getElementById('newsliderImage').value ='';
        getAllData();
      } else {
        setOpen(true)
        setStatus(false)
        setColor(false)
        setMessage(res.data.message)
      }
    }).catch(err => {
      console.log(err)
    });
  }

  return (
    <Box>
    <SnackBar open={open} setOpen={setOpen} status={status} color={color} message={message} />
    <Box textAlign='left' mb={4}>
      <Typography variant='body1' color='#ff5d9e'>Required Dimension : 3840 * 1500 </Typography>
    </Box>
      <Container>

        <Grid container spacing={2}>
          {HomeSilder && HomeSilder.map((image) => (
            <Grid item xs={12} sm={6} md={6} lg={4} key={image.homePageImageId}>
              {editMode && imageId === image.homePageImageId ? (
                <>
                <TextField
                  type="file"
                  onChange={handleImageChange}
                  inputProps={{ accept: 'image/*' }}
                  sx={{marginTop:10}}
                />
                  <Button onClick={handleClick}>Update</Button>
              <Button onClick={Cancel} color='error'>Cancel</Button>
                </>
              ) : (
                  <Box display='flex' flexDirection='row' alignContent='center'>
                  <img
                    src={image.images}
                    style={{ width: 200, height: 200, objectFit: 'contain' }}
                    alt=''
                  />
                    <Box display='flex' flexDirection='column' mt={5}>
                  <IconButton
                      onClick={() => handleEditClick(image.homePageImageId)}
                    color='primary'
                  >
                    <EditIcon />
                  </IconButton>
                    <IconButton
                      onClick={() => DeleteData(image.homePageImageId)}
                      color="error"
                      sx={{ marginTop: 1 }}
                    >
                      <DeleteIcon sx={{ verticalAlign: 'middle' }} />
                    </IconButton>
                    </Box>
                </Box>
              )}
            </Grid>
          ))}
        </Grid>


        <Box  mt={2}>
        <Heading title={'Add New Slider'} />
      </Box>

        <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6} lg={6} display='flex' justifyContent='start'>
        <TextField
        type="file"
        size='medium'
        id='newsliderImage'
        onChange={(e)=>setNewImage(e.target.files[0])}
        inputProps={{ accept: 'image/*' }}
        sx={{marginTop:2}}
      />
      <Grid item xs={12} sm={6} md={6} lg={6} >
      <Button
      variant="contained"
      color='warning'
      sx={{marginTop:4}}
      onClick={AddNew}
    >
    Generate
    </Button>
      </Grid>

        </Grid>
        </Grid>
      </Container>
    </Box>
  )
}
