import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Heading from '../../Components/Heading/Heading';
import { FilterData } from '../../Components/FilterData/FilterData';
import Filter from '../../Components/FilterData/Filter';
import axios from 'axios';
import { Container, Grid, Button, Card } from '@mui/material';
import {  get_allproducts, get_expense_category,} from '../../Services/APIService';
import { ExpensesCategoryTable, } from '../../Variables/Variables';
import AddIcon from '@mui/icons-material/Add';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SnackBar from '../../Components/AdminDashBoardComps/Snackbar';
import ExpenseCategroyDialog from './ExpenseCategroyDialog';
import ExpenseCategoryDelete from './ExpenseCategoryDelete';

export default function ExpenseCategory() {

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [color, setColor] = useState();
  const [ExpenseCategory, setExpenseCategory] = useState([]);
  const [search, setSearch] = useState('');
  const [openExpenseDialog , setOpenExpenseDialog] = useState('');
  const [openExpenseDeleteDialog, setOpenExpenseDeleteDialog] = useState('');
  const [ExpenseCategoryId, setExpenseCategoryId] = useState('');
  const [ExpenseCategoryName, setExpenseCategoryName] = useState('');

 const getAllExpense  = () =>{
   const sendData = new FormData();
   sendData.append('expenseCategoryId', '');
   axios({
     method: "POST",
     url: get_expense_category,
     data: sendData
   }).then(res => {
     if (res.data.error) {
       setMessage(res.data.message)
       setOpen(true)
       setStatus(false)
       setColor(false)
     } else {
       setOpen(true)
       setMessage(res.data.message)
       setStatus(true)
       setColor(true)
       setExpenseCategory(res.data.data);
     }
   }).catch(err => {
     console.log(err)
   });
 }

  useEffect(() => {
    getAllExpense();
  }, []);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenDialog = () => {
 setOpenExpenseDialog(true);
  }

  const EditExpense = (id) =>{
    setOpenExpenseDialog(true);
    setExpenseCategoryId(id);
  }

  
  const DeleteExpense = (id , name) => {
    setOpenExpenseDeleteDialog(true);
    setExpenseCategoryId(id);
    setExpenseCategoryName(name);
  }

  return (
    <Box p={2}>
      <Box p={3}>
        <Heading title={'Expense List'} />
      </Box>
      <SnackBar open={open} setOpen={setOpen} status={status} color={color} message={message} />

      <ExpenseCategroyDialog setExpenseCategoryId={setExpenseCategoryId} getAllExpense={getAllExpense} ExpenseCategoryId={ExpenseCategoryId} setMessage={setMessage} setOpen={setOpen} setStatus={setStatus} setColor={setColor} openExpenseDialog={openExpenseDialog} setOpenExpenseDialog={setOpenExpenseDialog} />
      <ExpenseCategoryDelete setExpenseCategoryId={setExpenseCategoryId} setExpenseCategoryName={setExpenseCategoryName} ExpenseCategoryName={ExpenseCategoryName} getAllExpense={getAllExpense} ExpenseCategoryId={ExpenseCategoryId} setMessage={setMessage} setOpen={setOpen} setStatus={setStatus} setColor={setColor} openExpenseDeleteDialog={openExpenseDeleteDialog} setOpenExpenseDeleteDialog={setOpenExpenseDeleteDialog} />
      <Box component={Card} sx={{ boxShadow: 2 }} pb={2}>
        <Container>
          <Box mt={2} py={2}  >
            <Grid container>
              <Grid item xs={12}>
                <Box display='flex' justifyContent='space-between' py={2} border='1px 1px 0px 1px solid silver'>
                  <Button variant='contained' color='success' onClick={handleOpenDialog}
                    sx={{ borderRadius: 0, boxShadow: 0, fontWeight: 600 }} ><AddIcon sx={{ verticalAlign: 'middle', fontWeight: 600 }} /> CREATE NEW EXPENSE CATEGORY</Button>
                 
                </Box>
              </Grid>
              <TableContainer sx={{ border: '1px solid silver' }} >

                <Table bgcolor='#eff8f2' size='small'>
                  <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: '#616e80' }}>
                    <TableRow sx={{ borderBottom: '1px solid silver' }}>
                      {
                        ExpensesCategoryTable.map((i, index) => {
                          return (
                            <TableCell sx={{
                              textAlign: 'center', color: 'white', fontWeight: 600, minHeight: '20px', borderRight: '1px solid silver',
                            }}>
                              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Box sx={{ my: 'auto' }}>
                                  {i.title}
                                </Box>
                                <Box>{
                                  i.filter &&
                                  <Filter search={search} setSearch={setSearch} setPage={setPage} />
                                }
                                </Box>
                              </Box>
                            </TableCell>
                          )
                        })
                      }
                    </TableRow>
                  </TableHead>

                  <TableBody>

                    {
                      ExpenseCategory.filter((data) => FilterData(data, search, {
                        searchFeildOne: data.productId,
                        searchFeildTwo: data.productName,
                      })).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((i , index) => {
                        return (
                          <TableRow key={i.expenseCategoryId} sx={{
                            borderBottom: '1px solid silver',
                            '& .MuiTableCell-root': {
                              height: '20px',
                            }
                          }}>
                            <TableCell sx={{
                              textAlign: 'center', borderBottom: '1px solid silver', borderLeft: '1px solid silver',
                              borderRight: '1px solid silver',
                            }}>{index + 1}</TableCell>
                            <TableCell sx={{
                              textAlign: 'center', borderBottom: '1px solid silver', borderLeft: '1px solid silver',
                              borderRight: '1px solid silver',
                            }}>{i.expenseCategory}</TableCell>
                            <TableCell sx={{
                              textAlign: 'center', borderBottom: '1px solid silver', borderLeft: '1px solid silver',
                              borderRight: '1px solid silver',
                            }}>0</TableCell>
                            <TableCell sx={{
                              textAlign: 'center', borderBottom: '1px solid silver', borderLeft: '1px solid silver',
                              borderRight: '1px solid silver',
                            }}>
                              <Box display='flex' flexDirection='row' justifyContent='center' gap={2}>
                                <Button onClick={() => EditExpense(i.expenseCategoryId)} variant="contained" sx={{ height: 25, width: 80, fontWeight: 600, opacity: 0.9 , fontSize:12}} ><DriveFileRenameOutlineIcon sx={{verticalAlign:'middle', mr:1}} /> EDIT</Button>
                                <Button onClick={() => DeleteExpense(i.expenseCategoryId , i.expenseCategory)} variant="contained" sx={{ height: 25, width: 90, fontWeight: 600, opacity: 0.9, fontSize: 12 }} color='error'><DeleteForeverIcon sx={{ verticalAlign: 'middle', mr: 1 }} /> DELETE</Button>
                              </Box>
                            </TableCell>
                          </TableRow>
                        )
                      })
                    }

                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={ExpenseCategory.length}
                page={page}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Grid>
          </Box>
        </Container>
      </Box>
    </Box>
  )
}
