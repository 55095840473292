import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Heading from '../../Components/Heading/Heading';
import axios from 'axios';
import { Container, Grid, Card, Typography, Button, IconButton } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';
import PaidIcon from '@mui/icons-material/Paid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FastRewindOutlinedIcon from '@mui/icons-material/FastRewindOutlined';
import SnackBar from '../../Components/AdminDashBoardComps/Snackbar';
import PrintIcon from '@mui/icons-material/Print';
import { channel_invoice, get_channel_sale_order } from '../../Services/APIService';

export default function SaleDetails() {


    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [status, setStatus] = useState();
    const [color, setColor] = useState();
    const [SaleList, setSaleList] = useState([]);
    const navigate = useNavigate();
    const [value, setValue] = React.useState(0);
    const location = useLocation();
    const { id } = location.state;

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`full-width-tabpanel-${index}`}
                aria-labelledby={`full-width-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 2 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `full-width-tab-${index}`,
            'aria-controls': `full-width-tabpanel-${index}`,
        };
    }

    useEffect(() => {
        const sendData = new FormData();
        sendData.append('orderId', id);
        axios({
            method: "POST",
            url: get_channel_sale_order,
            data: sendData
        }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(false)
                setColor(false)
            } else {
                setOpen(true)
                setMessage(res.data.message)
                setStatus(true)
                setColor(true)
                setSaleList(res.data.data[0]);
            }
        }).catch(err => {
            console.log(err)
        });
    }, [id]);



    const totalQuantity = SaleList?.saleProduct?.reduce((total, product) => {
        return total + product.saleQty;
    }, 0);


    const handlegenerateInvoice = () => {
        const sendData = new FormData();
        sendData.append('orderId', SaleList.orderId);

        axios({
            method: 'POST',
            url: channel_invoice,
            data: sendData
        })
            .then((res) => {
                if (res.data.error) {
                    setMessage(res.data.message);
                    setOpen(true);
                    setStatus(false);
                    setColor(false);
                } else {
                    setMessage(res.data.message);
                    setOpen(true);
                    setStatus(true);
                    setColor(true);

                    // Open the URL in a new tab
                    const newTab = window.open(res.data.data, '_blank');

                    // Check if the new tab has been successfully opened
                    if (newTab !== null) {
                        // Add a timeout to ensure the URL has loaded before printing
                        setTimeout(() => {
                            newTab.print(); // Print the contents of the new tab
                        }, 1000); // Adjust the timeout delay as needed
                    } else {
                        alert('Popup blocker prevented opening the new tab. Please allow popups.');
                    }
                }
            })
            .catch((err) => {
                alert("Oops something went wrong " + err);
            });
    };


    return (
        <Box p={2}>

            <Box p={3}>
                <Heading title={'sale Details'} />
            </Box>

            <SnackBar open={open} setOpen={setOpen} status={status} color={color} message={message} />
            <Box component={Card} sx={{ boxShadow: 2 }}>

                <Grid container p={2} spacing={2}>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Box component={Card} pb={2} border='1px solid silver'>
                            <Box bgcolor='#00bca4' py={4}>
                                <ContactEmergencyIcon sx={{ color: '#FFFFFF' }} />
                                <Typography fontWeight={600} color='#FFFFFF' variant='h6'>Supplier Name</Typography>
                            </Box>
                            <Box p={2}>
                                <Box p={2} border='1px solid silver' display='flex' flexWrap='wrap' flexDirection='row' gap={1}>
                                    <Typography color='#616e80' fontWeight={600} variant='subtitle2'>Name:</Typography>
                                    <Typography fontWeight={600} variant='subtitle2'>{SaleList.customerName}</Typography>
                                </Box>

                                <Box p={2} border='1px solid silver' display='flex' flexWrap='wrap' flexDirection='row' gap={1}>
                                    <Typography color='#616e80' fontWeight={600} variant='subtitle2'>Email:</Typography>
                                    <Typography fontWeight={600} variant='subtitle2'>{SaleList.customerMobile}</Typography>
                                </Box>
                                <Box p={2} border='1px solid silver' display='flex' flexWrap='wrap' flexDirection='row' gap={1}>
                                    <Typography color='#616e80' fontWeight={600} variant='subtitle2'>Phone:</Typography>
                                    <Typography fontWeight={600} variant='subtitle2'>{SaleList.customerMail}</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Box component={Card} pb={2} border='1px solid silver'>
                            <Box bgcolor='#65a6ff' sx={{ color: '#FFFFFF' }} py={4}>
                                <ReceiptIcon />
                                <Typography color='#FFFFFF' fontWeight={600} variant='h6'>Bill Info</Typography>
                            </Box>
                            <Box p={2} >
                                <Box p={2} border='1px solid silver' display='flex' flexWrap='wrap' flexDirection='row' gap={1}>
                                    <Typography color='#616e80' fontWeight={600} variant='subtitle2'>Invoice Num:</Typography>
                                    <Typography fontWeight={600} variant='subtitle2'>{SaleList.customerName}</Typography>
                                </Box>
                                <Box p={2} border='1px solid silver' display='flex' flexWrap='wrap' flexDirection='row' gap={1}>
                                    <Typography color='#616e80' fontWeight={600} variant='subtitle2'>Date:</Typography>
                                    <Typography fontWeight={600} variant='subtitle2'>{SaleList.saleDate}</Typography>
                                </Box>
                                <Box p={2} border='1px solid silver' display='flex' flexWrap='wrap' flexDirection='row' gap={1}>
                                    <Typography color='#616e80' fontWeight={600} variant='subtitle2'>Order Cost:</Typography>
                                    <Typography fontWeight={600} variant='subtitle2'>{SaleList.orderCost}</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Box component={Card} pb={2} border='1px solid silver'>
                            <Box bgcolor='#984dff' py={4}>
                                <PaidIcon sx={{ color: '#FFFFFF' }} />
                                <Typography color='#FFFFFF' fontWeight={600} variant='h6'>Payment Info</Typography>
                            </Box>
                            <Box p={2}>
                                <Box p={2} border='1px solid silver' display='flex' flexWrap='wrap' flexDirection='row' gap={1}>
                                    <Typography color='#616e80' fontWeight={600} variant='subtitle2'>Mode:</Typography>
                                    <Typography fontWeight={600} variant='subtitle2'>{SaleList.paymentMethod}</Typography>
                                </Box>
                                <Box p={2} border='1px solid silver' display='flex' flexWrap='wrap' flexDirection='row' gap={1}>
                                    <Typography color='#616e80' fontWeight={600} variant='subtitle2'>Paid:</Typography>
                                    <Typography fontWeight={600} variant='subtitle2'>${SaleList.paidAmount}</Typography>
                                </Box>
                                <Box p={2} border='1px solid silver' display='flex' flexWrap='wrap' flexDirection='row' gap={1}>
                                    <Typography color='#616e80' fontWeight={600} variant='subtitle2'>Due:</Typography>
                                    <Typography fontWeight={600} variant='subtitle2'>${SaleList.dueAmount}</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>






                <Container>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mt={4} mb={1}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="success"
                            textColor="inherit"
                            aria-label="full width tabs example"
                            variant="scrollable"
                            scrollButtons
                            allowScrollButtonsMobile
                        >
                            <Tab
                                label="sale Items"
                                sx={{
                                    bgcolor: value === 0 && '#00bca4',
                                    border: value === 0 && '1px solid silver',
                                    borderRadius: value === 0 && '4px',
                                    textTransform: 'none'
                                }}
                                {...a11yProps(0)} />
                            <Tab
                                label="Payment History"
                                sx={{
                                    bgcolor: value === 1 && '#00bca4',
                                    border: value === 1 && '1px solid silver',
                                    borderRadius: value === 1 && 1,
                                    textTransform: 'none'
                                }}
                                {...a11yProps(1)}
                            />
                            <Tab
                                label="Payment Due"
                                sx={{
                                    bgcolor: value === 2 && '#00bca4',
                                    border: value === 2 && '1px solid silver',
                                    borderRadius: value === 2 && 2,
                                    textTransform: 'none'
                                }}
                                {...a11yProps(2)}
                            />
                        </Tabs>
                    </Box>

                    {/* sale items */}

                    <TabPanel value={value} index={0} >
                        <Box mb={2}>
                            <TableContainer >
                                <Table bgcolor='#F9F9F8' sx={{ borderLeft: '1px solid silver' }}>
                                    <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: '#616e80' }}>
                                        <TableRow>
                                            <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>#</TableCell>
                                            <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>
                                                Product
                                            </TableCell>
                                            <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>Quantity</TableCell>
                                            <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>Unit Price</TableCell>
                                            <TableCell align="center">Sub Total($)</TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {
                                            SaleList?.products?.map((i, index) => {
                                                return (
                                                    <TableRow>
                                                        <TableCell align="center" sx={{ borderRight: '1px solid silver', borderLeft: '1px solid silver' }}>
                                                            {index + 1}
                                                        </TableCell>

                                                        <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>
                                                            {i.productName}
                                                        </TableCell>

                                                        <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>
                                                            {i.saleQty}
                                                        </TableCell>

                                                        <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>
                                                            {i.productUnitCost}
                                                        </TableCell>

                                                        <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>
                                                            {parseFloat(i.subTotal).toFixed(2)}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                        <TableRow>
                                            <TableCell sx={{ borderLeft: '1px solid silver' }} />
                                            <TableCell align="right" colSpan={3} sx={{ borderRight: '1px solid silver', fontWeight: 600 }}>Total Quantity :</TableCell>
                                            <TableCell align="center" sx={{ borderRight: '1px solid silver' }} >
                                                {
                                                    totalQuantity
                                                }
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell sx={{ borderLeft: '1px solid silver' }} />
                                            <TableCell align="right" colSpan={3} sx={{ borderRight: '1px solid silver', fontWeight: 600 }}>Discount($) :</TableCell>
                                            <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>
                                                {SaleList.discountAmount}
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell sx={{ borderLeft: '1px solid silver', bgcolor: '#F8FCD4' }} />
                                            <TableCell align="right" colSpan={3} sx={{ borderRight: '1px solid silver', bgcolor: '#F8FCD4', fontWeight: 600 }}>Net Total($) :</TableCell>
                                            <TableCell align="center" sx={{ borderRight: '1px solid silver', bgcolor: '#F8FCD4' }}>
                                                {
                                                    SaleList.netTotal
                                                }
                                            </TableCell>
                                        </TableRow>

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </TabPanel>

                    {/* Payment  */}
                    <TabPanel value={value} index={1}>
                        <Box mb={2}>
                            <TableContainer >
                                <Table bgcolor='#F9F9F8' sx={{ borderLeft: '1px solid silver' }}>
                                    <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: '#616e80' }}>
                                        <TableRow>
                                            <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>Date</TableCell>
                                            <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>Method</TableCell>
                                            <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>Amount Paid($)</TableCell>
                                            <TableCell align="center">PRINT</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>

                                            <TableCell align="center" sx={{ borderRight: '1px solid silver', borderLeft: '1px solid silver' }}>
                                                {SaleList.saleDate}
                                            </TableCell>

                                            <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>
                                                {SaleList.paymentMethod}
                                            </TableCell>

                                            <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>
                                                {SaleList.paidAmount}
                                            </TableCell>

                                            <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>
                                                <Button onClick={handlegenerateInvoice} variant='outlined' color='warning' sx={{ textTransform: 'none', height: 25 }}>
                                                    <PrintIcon sx={{ verticalAlign: 'middle', mr: 1 }} fontSize='small' /> Print</Button>
                                            </TableCell>

                                        </TableRow>

                                        <TableRow>
                                            <TableCell sx={{ borderLeft: '1px solid silver', bgcolor: '#F8FCD4' }} />
                                            <TableCell align="right" colSpan={2} sx={{ borderRight: '1px solid silver', bgcolor: '#F8FCD4', fontWeight: 600 }}>Total :</TableCell>
                                            <TableCell align="center" sx={{ borderRight: '1px solid silver', bgcolor: '#F8FCD4' }}>
                                                {SaleList.paidAmount}
                                            </TableCell>
                                        </TableRow>

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </TabPanel>

                    {/* Due */}
                    <TabPanel value={value} index={2}>
                        <Box mb={2}>
                            <TableContainer >
                                <Table bgcolor='#F9F9F8' sx={{ borderLeft: '1px solid silver' }}>
                                    <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: '#616e80' }}>
                                        <TableRow>
                                            <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>Date</TableCell>
                                            <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>Method</TableCell>
                                            <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>Amount Paid ($)</TableCell>
                                            <TableCell align="center">DUE ($)</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>

                                            <TableCell align="center" sx={{ borderRight: '1px solid silver', borderLeft: '1px solid silver' }}>
                                                {SaleList.saleDate}
                                            </TableCell>

                                            <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>
                                                {SaleList.paymentMethod}
                                            </TableCell>

                                            <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>
                                                {SaleList.paidAmount}
                                            </TableCell>

                                            <TableCell align="center" sx={{ borderRight: '1px solid silver' }}>
                                                {SaleList.dueAmount}
                                            </TableCell>

                                        </TableRow>

                                        <TableRow>
                                            <TableCell sx={{ borderLeft: '1px solid silver', bgcolor: '#F8FCD4' }} />
                                            <TableCell align="right" colSpan={2} sx={{ borderRight: '1px solid silver', bgcolor: '#F8FCD4', fontWeight: 600 }}>Total Due:</TableCell>
                                            <TableCell align="center" sx={{ borderRight: '1px solid silver', bgcolor: '#F8FCD4' }}>
                                                {SaleList.dueAmount}
                                            </TableCell>
                                        </TableRow>

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </TabPanel>
                </Container>

                <Box p={1} display='flex' justifyContent='end'>
                    <IconButton onClick={() => navigate(-1)} sx={{ width: 100, height: 40, border: '3px solid black', borderRadius: 1 }}>
                        <Button sx={{ color: '#000000', fontWeight: 600, textTransform: 'none' }}><FastRewindOutlinedIcon sx={{ verticalAlign: 'middle' }} /> Back</Button>
                    </IconButton>
                </Box>


            </Box>



        </Box>
    )
}
